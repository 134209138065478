import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty, sortBy } from 'lodash';

import Component from 'components/Component';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';
import Skeleton from 'components/common/Skeleton';

import { getDefaultStageSelectorData } from 'components/pages/analyze/AttribuitonTable/logic/StageSelector';
import { skeletonSmallNumberSizes } from 'components/common/enums';

import style from 'styles/analyze/stage-selector.css';

export const stageType = PropTypes.shape({
  key: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  prevNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stageGrowth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export default class StageSelector extends Component {
  style = style;

  static propTypes = {
    stages: PropTypes.arrayOf(stageType),
  };

  static defaultProps = {
    stages: null,
  };

  render() {
    const {
      stages,
      selectedKey: selected,
      selectStage,
      isCompareToPreviousEnabled,
      firstSegment,
      isHideComparisonNumber,
    } = this.props;

    const mergedStages = getDefaultStageSelectorData({ firstSegment });

    if (!isEmpty(stages)) {
      for (const stage of stages) {
        if (stage.number == null) {
          stage.number = 0;
          stage.prevNumber = 0;
          stage.stageGrowth = 0;
        }
        const updatedStage = mergedStages.find((mergedStage) => mergedStage.key === stage.key);
        if (!updatedStage) {
          mergedStages.push(stage);
          continue;
        }
        updatedStage.number = stage.number;
        updatedStage.prevNumber = stage.prevNumber;
        updatedStage.stageGrowth = stage.stageGrowth;
      }
      for (const mergeStage of mergedStages) {
        if (mergeStage.number == null) {
          mergeStage.number = 0;
          mergeStage.prevNumber = 0;
          mergeStage.stageGrowth = 0;
        }
      }
    }

    const orderStages = sortBy(mergedStages, 'priority');

    return (
      <div className={this.classes.outerDiv}>
        {orderStages.map((stage) => (
          <div
            key={stage.key}
            className={classnames(this.classes.innerDiv, stage.key === selected && this.classes.active)}
            onClick={() => selectStage?.(stage.key)}
            data-testid={`stageSelector-${stage.key}`}
          >
            <div className={this.classes.stageName}>
              {stage.name}
            </div>

            <div className={this.classes.row}>
              {stage.number == null ? (
                <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
              ) : (
                <>
                  <div className={this.classes.number} data-testid={`stageAmount-${stage.key}`}>
                    {stage.number.toLocaleString()}
                  </div>

                  {(!isHideComparisonNumber && isCompareToPreviousEnabled) ? (
                    stage.stageGrowth === null ? (
                      <Loading3Dots />
                    ) : (
                      <ComparisonValue
                        value={stage.stageGrowth}
                        isGrowthDesired
                      />
                    )
                  ) : null}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
