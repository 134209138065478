function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var colors = {
  general: {
    blue: '#4D91FC',
    lightBlue: '#E4EEFF',
    pacificBlue: '#0099CC',
    green: '#2FCF5F',
    IgBlack: '#222A41',
    red: '#E43A54',
    yellow: '#F6D31E',
    lightYellow: '#FFF7D0'
  },
  texts: {
    textDarkGray: '#4B515E',
    textGray: '#707EA7',
    IgGray: '#99A4C2',
    lightGray100: '#C1CFDA',
    textWhite: '#FFF'
  },
  backgrounds: {
    backgroundWhite: '#FFF',
    backgroundLightGray: '#F6F7FB',
    backgroundLightBlue: '#E1EDFF'
  },
  inputs: {
    errorRed: '#E60000',
    grayLine: '#DCE1EF'
  },
  buttons: {
    primaryBlue: {
      primary: '#4D91FC',
      hover: '#3E7DE3',
      selected: '#3168C1',
      disabled: '#4D91FC80'
    },
    secondaryBlue: {
      primary: '#E4EEFF',
      hover: '#D1E1FC',
      selected: '#BBD2FA',
      disabled: '#E4EEFF80'
    },
    primaryRed: {
      primary: '#FADCE0',
      hover: '#FBD1D6',
      selected: '#FFBAC2',
      disabled: '#FADCE080'
    },
    success: {
      primary: '#2FCF5F',
      disabled: '#2FCF5F80'
    },
    secondaryWhite: {
      primary: '#DCE1EF',
      hover: '#FCFDFF',
      selected: '#F4F7FD',
      disabled: '#DCE1EF80'
    }
  }
};
var shadows = {
  small: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  medium: '0 3px 6px rgba(0,0,0,0.15), 0 2px 4px rgba(0,0,0,0.12)',
  large: '0 10px 20px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.10)'
};
var spacing = {
  small: '8px',
  medium: '16px',
  large: '24px'
};

// Function to convert string to camelCase
var toCamelCase = function toCamelCase(str) {
  return str.replace(/^\w|[A-Z]|\b\w/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '');
};

// Function to flatten nested objects with camelCase support from the first level
var _flattenObject = function flattenObject(obj) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.keys(obj).reduce(function (acc, key) {
    var camelKey = toCamelCase(key);
    var newKey = prefix ? "".concat(prefix).concat(camelKey.charAt(0).toUpperCase() + camelKey.slice(1)) : camelKey;
    if (_typeof(obj[key]) === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(acc, _flattenObject(obj[key], newKey));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};

// Create CSS variables
var createCssVariables = function createCssVariables(tokenGroups) {
  return Object.entries(tokenGroups).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      groupName = _ref2[0],
      group = _ref2[1];
    var flatGroup = _flattenObject(group);
    Object.entries(flatGroup).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];
      acc["".concat(toCamelCase(groupName)).concat(key.charAt(0).toUpperCase() + key.slice(1))] = value;
    });
    return acc;
  }, {});
};
var cssVariables = createCssVariables({
  colors: colors,
  shadows: shadows,
  spacing: spacing
});
export { colors, shadows, spacing, cssVariables };