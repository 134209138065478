import { TIMEFRAME_VALUES, TIMEFRAME_LABELS } from 'components/utils/timeframe';

export const timeFrameOptions = [
  { label: TIMEFRAME_LABELS.QUARTER, value: TIMEFRAME_VALUES.QUARTER },
  { label: TIMEFRAME_LABELS.YEAR, value: TIMEFRAME_VALUES.YEAR },
  { label: TIMEFRAME_LABELS.NEXT_QUARTER, value: TIMEFRAME_VALUES.NEXT_QUARTER },
  { label: TIMEFRAME_LABELS.NEXT_YEAR, value: TIMEFRAME_VALUES.NEXT_YEAR },
];

export const baselineScenario = 'baseline';
export const predictedBaselineScenario = 'predictedBaseline';

export const scenarioLabels = {
  [baselineScenario]: 'Baseline',
  [predictedBaselineScenario]: 'Predicted Baseline',
};

export const budgetChangeOperatorsOptions = [
  { label: 'Increase', value: 'increase' },
  { label: 'Decrease', value: 'decrease' },
];

export const defaultScenario = [{
  id: baselineScenario,
  label: scenarioLabels[baselineScenario],
  rules: [{}],
}];

export const defaultSettings = {
  sampleTimeframeMonths: '12',
  kpiFocus: 'pipeline',
};

export const confirmPopupTypes = {
  sampleTimeframeMonths: 'sampleTimeframeMonths',
  kpiFocus: 'kpiFocus',
};

export const confirmPopupLabels = {
  [confirmPopupTypes.sampleTimeframeMonths]: 'sample period',
  [confirmPopupTypes.kpiFocus]: 'Predicted KPI',
};

export const amountOfFirstFunnelsToRemove = 2;

export const unclickableItemsKeys = {
  baselineConversionRate: 'baselineConversionRate',
  sampleTimeframe: 'sampleTimeframe',
  supportedTimeframeThisQuarter: 'supportedTimeframeThisQuarter',
  supportedTimeframeNextQuarter: 'supportedTimeframeNextQuarter',
  supportedKpiOpps: 'supportedKpiOpps',
  supportedKpiRevenue: 'supportedKpiRevenue',
  budgetScenario: 'budgetScenario',
  addChange: 'addChange',
};
