import React from 'react';
import { get, isEmpty, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import userStore from 'stores/userStore';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';

import Component from 'components/Component';
import Page from 'components/Page';
import {
  BLOG_SUBSCRIBER_FUNNEL,
  CRMIntegrationsOptions,
  DEFAULT_FUNNELS,
  DEFAULT_PLATFORMS_MAPPING,
  FUNNEL1,
  FUNNEL2,
  funnelsNicknamesToIgnor,
  getFunnelsFromMapping,
  HUBSPOT,
  LOST_FUNNEL,
  SALESFORCE,
} from 'components/utils/indicators';
import { funnelTransitionsModels, emptyFunnelMappingRule } from 'components/pages/createIntegration/logic/enums';
import UnsavedPopup from 'components/UnsavedPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import PreDefinedFiltersTab from 'components/pages/createIntegration/PreDefinedFiltersTab';
import CreateCRMIntegration from 'components/pages/createIntegration/CreateCRMIntegration';
import CRMContextProvider from 'components/pages/createIntegration/CRMContext';
import Loader from 'components/controls/Loader';
import style from 'styles/integrations/integration.css';
import { getNickname } from 'components/utils/channels';
import {
  flattenDuplicateMergingConfigurationsByObject, fixPredefineFiltersWithOriginalCustomFieldId, fixPredefinedFiltersAccordingToFunnelChanges,
} from 'components/pages/createIntegration/logic/CreateIntegration';
import { extendedData } from 'dataExtenders/calculatedDataExtender';

class CreateIntegration extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.currentBusinessUnit = props.userBusinessUnitsWithIds.find((bu) => bu.name === props.region) || null;
    this.state = {
      unsaved: false,
      showUnsavedPopup: false,
      showConfirmDeleteFunnelPopup: null,
      showConfirmDeleteImportFieldsPopup: { isShowPopup: false, index: null, label: null },
      currentProgressStep: 0,
      selectedCRMItems: [],
      isLoadedFromCRM: {},
      isNextStepLoading: false,
      funnelList: [],
      isFunnelNickNameChanged: false,
      isFunnelMappingChanged: false,
      isFunnelOrderChanged: false,
      CRMImportFieldsMapping: [],
      salesforceUserMappings: null,
      hubspotUserMappings: null,
      isLoadingMapping: false,
      selectedBusinessUnitPreset: this.currentBusinessUnit?.crmBusinessUnitId,
      initialFunnelsMappingIdToFunnelKey: {},
      funnelsMappingIdToFunnelKey: {},
      funnelMapping: {},
    };
  }

  componentDidMount() {
    CRMIntegrationsOptions.forEach((crmPlatform) => this.getCRMMappingForCRM({ crmPlatform }));
    if (this.props.isCreateIntegrationPopup) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  async getCRMMappingForCRM({ crmPlatform }) {
    this.setState({ isLoadingMapping: true });
    await this.getCRMMappingRequest({ crmPlatform });
    this.setState({ isLoadingMapping: false });
  }

  async getCRMMappingRequest({ crmPlatform, businessUnitId }) {
    const response = await serverCommunication.serverRequest(
      'POST',
      'crm/mapping',
      JSON.stringify({ crmPlatform, businessUnitId }),
      this.props.region
    );

    if (response.ok) {
      const responseData = await response.json();
      if (responseData) {
        const crmStateName = `${crmPlatform}UserMappings`;

        this.setState({ [crmStateName]: responseData }, () => {
          const funnelList = this.getUserFunnels();
          const selectedCRMItems = this.getSavedSelectedCRMItems();
          this.setState({ selectedCRMItems }, () => {
            const currentFunnelMapping = selectedCRMItems.length ? this.getAllFunnelsMapping({ funnelList }) : {};

            this.setState({
              funnelList,
              isFunnelOrderChanged: false,
              isFunnelNickNameChanged: false,
              isFunnelMappingChanged: false,
              funnelMapping: currentFunnelMapping,
            });
          });
        });
      }
    }
  }

  getSavedSelectedCRMItems() {
    const CRMTokensExist = {
      salesforce: this.state.salesforceUserMappings != null,
      hubspot: this.state.hubspotUserMappings != null,
    };
    return CRMIntegrationsOptions.filter((crmItem) => CRMTokensExist[crmItem] && crmItem);
  }

  sortFunnels = (funnels) => {
    const getNumber = (funnel) => funnel.split('funnel')[1];
    const sorted = this.onlyFlexFunnels(funnels).sort((a, b) => getNumber(a) - getNumber(b));
    if (funnels.includes(BLOG_SUBSCRIBER_FUNNEL)) {
      sorted.unshift(BLOG_SUBSCRIBER_FUNNEL);
    }
    if (funnels.includes(LOST_FUNNEL)) {
      sorted.push(LOST_FUNNEL);
    }
    return sorted;
  };

  onlyFlexFunnels = (arr) => arr.filter((ind) => /funnel[0-9]/.test(ind));

  getUserFunnels = () => {
    const { salesforceUserMappings, hubspotUserMappings } = this.state;
    const funnels = getFunnelsFromMapping(salesforceUserMappings, hubspotUserMappings, false);
    return funnels?.length ? this.sortFunnels(funnels) : DEFAULT_FUNNELS;
  };

  getDataFromCRM = (crmPlatform) => {
    if (crmPlatform === SALESFORCE) {
      return this.state.salesforceUserMappings;
    }

    if (crmPlatform === HUBSPOT) {
      return this.state.hubspotUserMappings;
    }

    return {};
  };

  getSavedFunnelMappingData = (crmPlatform, funnel) => {
    const dataFromCRM = this.getDataFromCRM(crmPlatform);
    return get(dataFromCRM, ['mapping', 'funnelMapping', funnel], {});
  };

  getSelectedCRMPlatform = (funnel) => {
    const { selectedCRMItems } = this.state;
    const { salesforceUserMappings, hubspotUserMappings } = this.state;

    if (get(salesforceUserMappings, ['mapping', 'funnelMapping', funnel], null)) {
      return SALESFORCE;
    }
    if (get(hubspotUserMappings, ['mapping', 'funnelMapping', funnel], null)) {
      return HUBSPOT;
    }
    if (selectedCRMItems.length === 1) {
      return selectedCRMItems[0];
    }
    if (funnel === FUNNEL1) {
      return SALESFORCE;
    }
    return HUBSPOT;
  };

  getEmptyFunnelObject = (funnel) => ({
    id: uuidv4(),
    crmPlatform: this.getSelectedCRMPlatform(funnel),
    rules: [emptyFunnelMappingRule],
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
    isPipeline: false,
  });

  getFunnelObjectMapping = (funnel) => {
    const crmPlatform = this.getSelectedCRMPlatform(funnel);
    const savedFunnelMappingData = this.getSavedFunnelMappingData(crmPlatform, funnel);

    if (isEmpty(savedFunnelMappingData) && !this.state.isFunnelMappingChanged) {
      this.setState({ isFunnelMappingChanged: true });
    }

    let crmDefaults = DEFAULT_PLATFORMS_MAPPING[crmPlatform][funnel];
    if (!crmDefaults) {
      crmDefaults = this.getEmptyFunnelObject(FUNNEL2);
    }

    const id = savedFunnelMappingData.id || uuidv4();
    const crmRules = savedFunnelMappingData.rules || crmDefaults.rules;
    const groupBy = savedFunnelMappingData.groupBy || crmDefaults.groupBy;
    const funnelTransitionsModel = savedFunnelMappingData.funnelTransitionsModel || crmDefaults.funnelTransitionsModel;
    const isRevenue = savedFunnelMappingData.isRevenue == null ? crmDefaults.isRevenue : savedFunnelMappingData.isRevenue;
    const isPipeline = savedFunnelMappingData.isPipeline == null ? crmDefaults.isPipeline : savedFunnelMappingData.isPipeline;
    const title = this.getFunnelTitle(funnel);
    const { amountField } = savedFunnelMappingData;
    const currencyField = get(savedFunnelMappingData, 'currencyField');

    const rules = crmRules.map((rule) => ({
      objectType: rule.objectType || crmDefaults.objectType,
      field: rule.field || crmDefaults.field,
      values: rule.values || crmDefaults.values,
      dateField: rule.dateField || null,
      fallbackDateField: rule.fallbackDateField || null,
    }));

    return {
      id,
      ...savedFunnelMappingData,
      crmPlatform,
      rules,
      groupBy,
      funnelTransitionsModel,
      isRevenue,
      isPipeline,
      title,
      amountField,
      currencyField,
    };
  };

  getAllFunnelsMapping({ funnelList }) {
    const funnelsMapping = {};
    const updatedFunnelsMappingIdToFunnelKey = {};

    for (const funnel of funnelList) {
      const funnelMapping = this.getFunnelObjectMapping(funnel);
      funnelsMapping[funnel] = funnelMapping;
      updatedFunnelsMappingIdToFunnelKey[funnelMapping.id] = funnel;
    }

    this.setState({
      initialFunnelsMappingIdToFunnelKey: updatedFunnelsMappingIdToFunnelKey,
    });

    return funnelsMapping;
  }

  updateStateShowUnsavedPopup = (newState) => {
    this.setState({ unsaved: newState });
  };

  userAnswerCallback = (isLeavePage) => {
    if (isLeavePage) {
      this.setState({ unsaved: false });
      this.props.close();
    }
    this.setState({ showUnsavedPopup: false });
  };

  userAnswerDeleteCallback = (dontDeleteFunnel) => {
    const shouldDeleteFunnelStage = !dontDeleteFunnel;
    if (shouldDeleteFunnelStage) {
      this.removeFunnelHandle(this.state.showConfirmDeleteFunnelPopup);
    }
    this.setState({ showConfirmDeleteFunnelPopup: null });
  };

  userAnswerDeleteImportFieldsCallback = (isDisableDeleteAction) => {
    if (!isDisableDeleteAction) {
      // TODO: @LeeVak @SigalitIG - fix this. not sure what's your approach here
      // eslint-disable-next-line react/no-access-state-in-setstate
      const updatedCRMImportFieldsMapping = [...this.state.CRMImportFieldsMapping];
      const deleteIndex = this.state.showConfirmDeleteImportFieldsPopup.index;
      updatedCRMImportFieldsMapping.splice(deleteIndex, 1);
      this.setState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
    }
    this.setState({ showConfirmDeleteImportFieldsPopup: { isShowPopup: false, index: null, label: null } });
  };

  removeFunnel = (toRemove, withoutPopup = false) => {
    if (withoutPopup) {
      this.removeFunnelHandle(toRemove);
    } else {
      this.setState({ showConfirmDeleteFunnelPopup: toRemove });
    }
  };

  removeFunnelHandle = (removedFunnel) => {
    const updatedFunnels = this.state.funnelList.filter((ind) => ind !== removedFunnel);
    const updatedFunnelMapping = this.state.funnelMapping;
    delete updatedFunnelMapping[removedFunnel];

    this.updateFunnelsMappingNaming(updatedFunnels, updatedFunnelMapping);

    this.setState({ isFunnelMappingChanged: true, isFunnelOrderChanged: true });
    this.updateSelectedPresetOnChangeFunnelMapping();
  };

  closePopup = () => {
    if (this.state.unsaved) {
      this.setState({ showUnsavedPopup: true });
    } else {
      this.props.close();
    }
  };

  setAsyncState = (newState, resloveValue) => new Promise((resolve) => { this.setState(newState, () => resolve(resloveValue)); });

  setParentState = (newState) => this.setState(newState);

  authorize = (api) => serverCommunication.serverRequest('get', api, null, localStorage.getItem('region'))
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`error getting data for api: ${api}`);
      }
    })
    .then((data) => {
      const isURL = typeof data === 'string';
      if (isURL) {
        return this.getAuthorization(data);
      }
      return null;
    });

  getAuthorization = (url) => new Promise((resolve, reject) => {
    const win = window.open(url);
    const timer = setInterval(() => {
      if (win && win.closed) {
        clearInterval(timer);
        const codeFromStore = localStorage.getItem('code');
        if (codeFromStore) {
          localStorage.removeItem('code');
          resolve(codeFromStore);
        } else {
          reject();
        }
      }
    }, 1000);
  });

  afterAuthorization = (code, api) => serverCommunication.serverRequest(
    'post',
    this.props.afterAuthorizationApi || `${api}`,
    JSON.stringify({ code }),
    localStorage.getItem('region')
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Error authorizing connection to ${this.props.platformTitle}`);
      }
    });

  afterDataRetrieved = (mappingDataResponse, api) => {
    const mappingData = {
      [api]: mappingDataResponse,
    };
    const isLoadedFromCRM = {
      [api]: true,
    };
    const mapping = this.state.isLoadedFromCRM;
    Object.keys(mapping).forEach((crm) => {
      if (!mapping[crm]) {
        delete mapping[crm];
      }
    });
    return this.setAsyncState({
      mappingData: {
        ...this.state.mappingData,
        ...mappingData,
      },
      isLoadedFromCRM: {
        ...mapping,
        ...isLoadedFromCRM,
      },
    }, true);
  };

  updateSavedIntegrationsCRMItems = async () => {
    const savedIntegrationsCRMItems = this.getSavedSelectedCRMItems();
    const { selectedCRMItems } = this.state;
    const CRMitemsToDelete = [];
    for (const savedCRMItem of savedIntegrationsCRMItems) {
      if (!selectedCRMItems.includes(savedCRMItem)) {
        CRMitemsToDelete.push(savedCRMItem);
      }
    }
    const isDeleteItems = savedIntegrationsCRMItems.length > 0 && CRMitemsToDelete.length > 0;
    if (isDeleteItems) {
      for (const crmItem of CRMitemsToDelete) {
        const crmStateName = `${crmItem}UserMappings`;
        await this.setState({ [crmStateName]: null }, () => {
          this.deleteCRMMItemsRequest(crmItem.toLowerCase());
        });
      }
    }

    return this.getUserFunnels();
  };

  nextFunnelID = () => {
    const funnels = this.onlyFlexFunnels(this.state.funnelList);
    if (funnels.length === 0) {
      return 'funnel1';
    }
    const latestFunnel = funnels.slice(-1)[0];
    const numberFromLastFunnel = latestFunnel.split('funnel')[1];
    return `funnel${parseInt(numberFromLastFunnel, 10) + 1}`;
  };

  onFunnelAdd = () => {
    const funnelID = this.nextFunnelID();

    this.setState((prevState) => {
      const updatedFunnelList = [...prevState.funnelList];
      const lostFunnelIndex = updatedFunnelList.findIndex((item) => item === LOST_FUNNEL);
      if (lostFunnelIndex >= 0) {
        updatedFunnelList.splice(lostFunnelIndex, 0, funnelID);
      } else {
        updatedFunnelList.push(funnelID);
      }

      const newFunnelObject = this.getEmptyFunnelObject(funnelID);
      newFunnelObject.title = '';

      const funnelsMappingIdToFunnelKey = prevState.funnelsMappingIdToFunnelKey;
      funnelsMappingIdToFunnelKey[newFunnelObject.id] = funnelID;

      return ({
        funnelMapping: {
          ...prevState.funnelMapping,
          [funnelID]: newFunnelObject,
        },
        funnelList: updatedFunnelList,
        isFunnelNickNameChanged: true,
        funnelsMappingIdToFunnelKey,
        isFunnelOrderChanged: true,
      });
    });

    this.updateSelectedPresetOnChangeFunnelMapping();
    return funnelID;
  };

  getFunnelTitle = (funnelName) => {
    const { funnelMapping = {} } = this.state;
    const { funnelsNicknames = [] } = this.props;
    const currentFunnel = funnelMapping[funnelName];

    if (currentFunnel?.title) {
      return currentFunnel.title;
    }

    if (currentFunnel?.title === '') {
      return 'Stage name';
    }

    if (funnelsNicknames[funnelName]) {
      return funnelsNicknames[funnelName];
    }
    return userStore.getMetricNickname({ metric: funnelName });
  };

  updateFunnelNickname = (funnelName, title) => {
    const updatedFunnelMapping = cloneDeep(this.state.funnelMapping);
    updatedFunnelMapping[funnelName].title = title;
    this.setAsyncState({ isFunnelNickNameChanged: true, funnelMapping: updatedFunnelMapping });
    this.updateSelectedPresetOnChangeFunnelMapping();
  };

  updateSelectedPresetOnChangeFunnelMapping() {
    const selectedPresetIsNotEqualToCurrentBusinessUnit = this.state.selectedBusinessUnitPreset !== this.currentBusinessUnit.crmBusinessUnitId;
    const businessUnitIdIsNotEqualToParentBusinessUnitId = this.currentBusinessUnit.id !== this.currentBusinessUnit.crmBusinessUnitId;

    if (selectedPresetIsNotEqualToCurrentBusinessUnit || businessUnitIdIsNotEqualToParentBusinessUnitId) {
      this.setState({ selectedBusinessUnitPreset: null, isFunnelMappingChanged: false });
    }
  }

  updateFunnelsMappingNaming = (funnelList, funnelMapping = { ...this.state.funnelMapping }) => {
    const updatedFunnelMapping = {};
    const updatedFunnelList = [...funnelList];
    let funnelID = 1;
    for (const [index, funnelName] of updatedFunnelList.entries()) {
      const isFunnel = funnelName.includes('funnel');
      let updateFunnelName;
      if (isFunnel) {
        updateFunnelName = `funnel${funnelID}`;
        funnelID++;
      } else {
        updateFunnelName = funnelName;
      }
      updatedFunnelMapping[updateFunnelName] = { ...funnelMapping[funnelName] };
      updatedFunnelList[index] = updateFunnelName;
    }

    const updatedFunnelsMappingIdToFunnelKey = {};
    for (const [funnelKey, funnelMappingObject] of Object.entries(updatedFunnelMapping)) {
      updatedFunnelsMappingIdToFunnelKey[funnelMappingObject.id] = funnelKey;
    }

    this.setAsyncState({
      funnelList: updatedFunnelList,
      funnelMapping: updatedFunnelMapping,
      funnelsMappingIdToFunnelKey: updatedFunnelsMappingIdToFunnelKey,
      isFunnelNickNameChanged: true,
    });

    return { updatedFunnelList, updatedFunnelMapping };
  };

  divideFunnelMappingByCRM = (funnelList, funnelMapping) => {
    const divideFunnelMapping = {};
    divideFunnelMapping[SALESFORCE] = {};
    divideFunnelMapping[HUBSPOT] = {};
    for (const funnel of funnelList) {
      const currentFunnelMapping = { ...funnelMapping[funnel] };
      const { crmPlatform } = currentFunnelMapping;
      delete currentFunnelMapping.title;
      delete currentFunnelMapping.crmPlatform;
      divideFunnelMapping[crmPlatform][funnel] = currentFunnelMapping;
    }
    return divideFunnelMapping;
  };

  divideImportFieldsMappingByCRM = () => {
    const { CRMImportFieldsMapping, selectedCRMItems = [] } = this.state;
    const divideImportFieldsMapping = {};
    divideImportFieldsMapping[SALESFORCE] = { customFields: [], leadSources: [] };
    divideImportFieldsMapping[HUBSPOT] = { customFields: [], leadSources: [] };

    const copyCRMImportFieldsMapping = cloneDeep(CRMImportFieldsMapping);
    for (const importField of Object.values(copyCRMImportFieldsMapping)) {
      if (!importField.id) {
        importField.id = uuidv4();
      }

      for (const crmPlatform of selectedCRMItems) {
        const updateImportFieldByCRM = {};
        const mergingConfigurationsByCRM = Object.values(importField.mergingConfiguration).filter((field) => field.crmPlatform === crmPlatform);
        let updateMergingConfigurations = [];

        for (const mergingConfig of mergingConfigurationsByCRM) {
          delete mergingConfig.crmPlatform;
          updateMergingConfigurations.push(mergingConfig);
        }
        updateMergingConfigurations = flattenDuplicateMergingConfigurationsByObject(updateMergingConfigurations);

        updateImportFieldByCRM.mergingConfiguration = updateMergingConfigurations;
        updateImportFieldByCRM.label = importField.label;
        updateImportFieldByCRM.id = importField.id;

        if (updateImportFieldByCRM.mergingConfiguration.length > 0) {
          if (importField.isAttribution) {
            divideImportFieldsMapping[crmPlatform].leadSources.push(updateImportFieldByCRM);
          } else {
            divideImportFieldsMapping[crmPlatform].customFields.push(updateImportFieldByCRM);
          }
        }
      }
    }
    return divideImportFieldsMapping;
  };

  async updateBusinessUnitId() {
    try {
      await serverCommunication.serverRequest('PUT', 'crm/businessUnit', JSON.stringify({
        businessUnitId: this.state.selectedBusinessUnitPreset,
      }), this.props.region);
    } catch (error) {
      servicesStore.logger.error('failed to set crm businessUnit', {
        error,
        UID: this.props.UID,
      });
      throw error;
    }
  }

  saveFunnelMappingConfigProgress = async () => {
    this.setState({ isNextStepLoading: true });
    const { selectedCRMItems = [] } = this.state;
    const divideFunnelMapping = this.divideFunnelMappingByCRM(this.state.funnelList, this.state.funnelMapping);

    if (this.state.isFunnelMappingChanged || !this.state.selectedBusinessUnitPreset) {
      for (const [crmPlatform, funnelMapValues] of Object.entries(divideFunnelMapping)) {
        if (Object.keys(funnelMapValues).length > 0) {
          await this.saveFunnelMappingRequest(crmPlatform, funnelMapValues);
        }
      }
    } else {
      await this.updateBusinessUnitId();
    }

    if (this.state.isFunnelNickNameChanged) {
      await this.updateFunnelToNicknameRequest();
    }

    const divideImportFieldsMapping = this.divideImportFieldsMappingByCRM();
    for (const crmPlatform of selectedCRMItems) {
      const mappingByCRM = divideImportFieldsMapping[crmPlatform];
      await this.saveCustomFieldsMappingRequest(crmPlatform, mappingByCRM.customFields);
      await this.saveLeadSourcesMappingRequest(crmPlatform, mappingByCRM.leadSources);
      await this.saveFinishSettingCrmMappingsRequest(crmPlatform);
      await this.updatePredefinedFiltersAfterCRMConfigChangesRequest();
    }
    this.setState({ unsaved: false, isNextStepLoading: false }, this.props.close());
  };

  handleProgressSteps = async (isNext) => {
    const { currentProgressStep } = this.state;
    if (isNext) {
      if (currentProgressStep === 0) {
        this.setState({ isNextStepLoading: true, unsaved: true });
        const funnelList = await this.updateSavedIntegrationsCRMItems();

        const hasFunnelMapping = this.state.funnelMapping && Object.keys(this.state.funnelMapping).length > 0;
        if (!hasFunnelMapping) {
          const currentFunnelMapping = this.getAllFunnelsMapping({ funnelList });
          this.setAsyncState({ funnelMapping: currentFunnelMapping });
        }

        this.setState({ funnelList }, () => {
          this.authorizeCRM().then((doneAuthorizations) => {
            this.setState({
              currentProgressStep: doneAuthorizations ? 1 : 0,
              isNextStepLoading: false,
            });
          });
        });
      } else if (currentProgressStep === 1) {
        this.setState({ currentProgressStep: currentProgressStep + 1 });
      }
    } else {
      this.setState({ currentProgressStep: currentProgressStep - 1 });
    }
  };

  async authorizeCRM() {
    try {
      const { selectedCRMItems, isLoadedFromCRM } = this.state;
      let isLoaded = {};
      selectedCRMItems.forEach((crm) => {
        isLoaded = { ...isLoaded, [`${crm}api`]: false };
        isLoaded = { ...isLoaded, ...isLoadedFromCRM };
      });
      const crmAPI = Object.keys(isLoaded);

      for (const crmApiItem of crmAPI) {
        this.setState({
          loadingCRMData: crmApiItem,
        });
        const code = await this.authorize(crmApiItem);
        const crmData = await this.afterAuthorization(code, crmApiItem);
        await this.afterDataRetrieved(crmData, crmApiItem);
      }
      this.setState({
        loadingCRMData: false,
      });
      return true;
    } catch (e) {
      this.setState({
        loadingCRMData: false,
      });
      return false;
    }
  }

  async deleteCRMMItemsRequest(crmPlatform) {
    const response = await serverCommunication.serverRequest('DELETE', 'crm/apiTokens', JSON.stringify({
      crmPlatform,
    }), this.props.region);

    if (response.status === 200) {
      this.props.updateDeleteCrmDataState(crmPlatform);
      return true;
    }

    return false;
  }

  async updateFunnelToNicknameRequest() {
    const funnelToNickname = {};
    for (const [funnelKey, funnelValues] of Object.entries(this.state.funnelMapping)) {
      if (!funnelsNicknamesToIgnor.includes(funnelKey)) {
        const { title } = funnelValues;
        if (title) {
          funnelToNickname[funnelKey] = title;
        } else {
          funnelToNickname[funnelKey] = getNickname(funnelKey);
        }
      }
    }

    const [oldResponse, response] = await Promise.all([
      // TODO: Should be removed when we kill userIndicatorSchema
      serverCommunication.serverRequest('PUT', 'crm/funnelList', JSON.stringify({ funnelToNickname }), this.props.region),
      serverCommunication.serverRequest('PUT', 'funnelNicknames', JSON.stringify({ funnelToNickname }), this.props.region),
    ]);

    if (oldResponse.status === 200 && response.status === 200) {
      this.setState({ isFunnelNickNameChanged: false });
      this.props.setFunnelsNicknames(funnelToNickname);
    }
  }

  async saveFunnelMappingRequest(crmPlatform, funnelMapping) {
    const response = await serverCommunication.serverRequest('PUT', 'crm/funnelMapping', JSON.stringify({
      crmPlatform,
      funnelMapping,
    }), this.props.region);
    if (!response.ok) {
      this.setState({ isNextStepLoading: false, isFunnelOrderChanged: false });
    }
  }

  async saveCustomFieldsMappingRequest(crmPlatform, customFields) {
    const response = await serverCommunication.serverRequest('PUT', 'crm/customFields', JSON.stringify({
      crmPlatform,
      customFields,
    }), this.props.region);
    if (!response.ok) {
      this.setState({ isNextStepLoading: false });
    }
  }

  async saveLeadSourcesMappingRequest(crmPlatform, leadSources) {
    const response = await serverCommunication.serverRequest('PUT', 'crm/leadSources', JSON.stringify({
      crmPlatform,
      leadSources,
    }), this.props.region);
    if (!response.ok) {
      this.setState({ isNextStepLoading: false });
    }
  }

  async updatePredefinedFiltersAfterCRMConfigChangesRequest() {
    if (isEmpty(this.state.updatedPredefinedFiltersConfigFromSync) && !this.state.isFunnelOrderChanged && isEmpty(this.state.oldCustomFieldIdToMergedId)) {
      return;
    }

    const currentPredefinedFiltersConfig = userStore.userMonthPlan.CRMConfig.predefinedFiltersConfig;
    let predefinedFiltersConfig = { ...currentPredefinedFiltersConfig, ...this.state.updatedPredefinedFiltersConfigFromSync };
    predefinedFiltersConfig = fixPredefinedFiltersAccordingToFunnelChanges({
      predefinedFiltersConfig,
      funnelsMappingIdToFunnelKey: this.state.funnelsMappingIdToFunnelKey,
      initialFunnelsMappingIdToFunnelKey: this.state.initialFunnelsMappingIdToFunnelKey,
    });
    if (!isEmpty(this.state.oldCustomFieldIdToMergedId)) {
      fixPredefineFiltersWithOriginalCustomFieldId({ oldCustomFieldIdToMergedId: this.state.oldCustomFieldIdToMergedId, predefinedFiltersConfig });
    }

    const response = await serverCommunication.serverRequest('PUT', 'crm/predefinedFilters', JSON.stringify({ predefinedFiltersConfig }), this.props.region);

    if (response.ok) {
      const updatedUserMonthPlan = { ...userStore.userMonthPlan };
      updatedUserMonthPlan.CRMConfig.predefinedFiltersConfig = predefinedFiltersConfig;
      const { userMonthPlanDataFromState } = extendedData(updatedUserMonthPlan);
      userStore.setUserMonthPlan(userMonthPlanDataFromState);
    }
  }

  async saveFinishSettingCrmMappingsRequest(crmPlatform) {
    const response = await serverCommunication.serverRequest('POST', 'crm/finishSettingCrmMappings', JSON.stringify({
      crmPlatform,
    }), this.props.region);
    if (!response.ok) {
      this.setState({ isNextStepLoading: false });
    }
  }

  setSelectedBusinessUnitPreset({ preset }) {
    this.setState({ selectedBusinessUnitPreset: preset });
  }

  render() {
    const {
      currentProgressStep, loadingCRMData, funnelList, selectedCRMItems, mappingData, funnelMapping, isNextStepLoading,
      CRMImportFieldsMapping, salesforceUserMappings, hubspotUserMappings, selectedBusinessUnitPreset, updatedPredefinedFiltersConfigFromSync = {}, oldCustomFieldIdToMergedId = {},
    } = this.state;

    const {
      onAddCustomFilters, filterConfigs, isVisiblePreDefinedFilters, onAddPredefineFilters,
    } = this.props;

    return (
      <CRMContextProvider
        values={{
          selectedCRMItems,
          loadingCRMData,
          mappingData,
          funnelList,
          funnelMapping,
          currentProgressStep,
          isNextStepLoading,
          CRMImportFieldsMapping,
          salesforceUserMappings,
          hubspotUserMappings,
          selectedBusinessUnitPreset,
          updateStateShowUnsavedPopup: this.updateStateShowUnsavedPopup,
          closePopup: this.closePopup,
          handleProgressSteps: this.handleProgressSteps,
          setParentState: this.setParentState,
          getFunnelTitle: this.getFunnelTitle,
          onFunnelAdd: this.onFunnelAdd,
          removeFunnel: this.removeFunnel,
          updateFunnelNickname: this.updateFunnelNickname,
          getFunnelObjectMapping: this.getFunnelObjectMapping,
          saveFunnelMappingConfigProgress: this.saveFunnelMappingConfigProgress,
          updateFunnelsMappingNaming: this.updateFunnelsMappingNaming,
          getEmptyFunnelObject: this.getEmptyFunnelObject,
          getCRMMappingRequest: this.getCRMMappingRequest.bind(this),
          setSelectedBusinessUnitPreset: this.setSelectedBusinessUnitPreset.bind(this),
          updatedPredefinedFiltersConfigFromSync,
          oldCustomFieldIdToMergedId,
        }}
      >
        {isVisiblePreDefinedFilters
          ? (
            <Page popup contentClassName={this.classes.content} width={1000}>
              {this.state.isLoadingMapping
                ? <Loader newStyle />
                : (
                  <PreDefinedFiltersTab
                    onAddCustomFilters={onAddCustomFilters}
                    filterConfigs={filterConfigs}
                    onAddPredefineFilters={onAddPredefineFilters}
                  />
                )}
            </Page>
          ) : (
            <Page popup contentClassName={this.classes.content} wrapBoxStyle={this.classes.createCRMWrapBoxStyle} className={this.classes.createCRMIntegrationPopup}>
              {this.state.isLoadingMapping
                ? <Loader newStyle />
                : (
                  <CreateCRMIntegration />
                )}
            </Page>
          )}
        <UnsavedPopup hidden={!this.state.showUnsavedPopup} callback={this.userAnswerCallback} />
        <ConfirmPopup
          hidden={!this.state.showConfirmDeleteFunnelPopup}
          callback={this.userAnswerDeleteCallback}
          confirmBtn="Delete"
          title={`Are you sure you want to delete ${this.getFunnelTitle(this.state.showConfirmDeleteFunnelPopup)} stage?`}
          dataTestId="delete-funnel-popup"
        />
        <ConfirmPopup
          hidden={!this.state.showConfirmDeleteImportFieldsPopup.isShowPopup}
          callback={this.userAnswerDeleteImportFieldsCallback}
          confirmBtn="Delete"
          title={`Are you sure you want to delete ${this.state.showConfirmDeleteImportFieldsPopup.label}?`}
        />
      </CRMContextProvider>
    );
  }
}

export default CreateIntegration;
