exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".custom-checkbox__container-1BJ{display:flex;align-items:center}.custom-checkbox__size-2EX{width:16px;height:16px;min-width:16px;min-height:16px}.custom-checkbox__input-Gfi{opacity:0;position:absolute;top:0;left:0;bottom:0;right:0;padding:0;margin:0;cursor:pointer}.custom-checkbox__checkbox-Epe{border-radius:2px;position:relative;margin-right:12px;opacity:.6;background-image:linear-gradient(0deg,#fafafc,#fff)}.custom-checkbox__checkbox-Epe[data-checked]{opacity:1;background-color:#3dbcf6}.custom-checkbox__checkbox-Epe[data-disabled]{background-image:unset!important;background-color:#e6e8f0!important}.custom-checkbox__check-mark-3SD{background-repeat:no-repeat;background-position:50%;background-size:7px auto}.custom-checkbox__children-1UR{text-transform:capitalize;font-size:14px;font-weight:600;letter-spacing:.1px;color:#b2bbd5;cursor:pointer}.custom-checkbox__children-1UR[data-checked]{color:#222a41}[data-icon=\"checkbox:checked\"]{background-image:url(\"/assets/checked.svg\")}", ""]);

// exports
exports.locals = {
	"container": "custom-checkbox__container-1BJ",
	"container": "custom-checkbox__container-1BJ",
	"size": "custom-checkbox__size-2EX",
	"size": "custom-checkbox__size-2EX",
	"input": "custom-checkbox__input-Gfi custom-checkbox__size-2EX",
	"input": "custom-checkbox__input-Gfi custom-checkbox__size-2EX",
	"checkbox": "custom-checkbox__checkbox-Epe custom-checkbox__size-2EX",
	"checkbox": "custom-checkbox__checkbox-Epe custom-checkbox__size-2EX",
	"check-mark": "custom-checkbox__check-mark-3SD custom-checkbox__size-2EX",
	"checkMark": "custom-checkbox__check-mark-3SD custom-checkbox__size-2EX",
	"children": "custom-checkbox__children-1UR",
	"children": "custom-checkbox__children-1UR"
};