import React from 'react';
import filterStyles from 'styles/users/filters.css';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import { getSegmentType } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { Events } from 'trackers/analytics/enums';
import {
  getObjectType,
  getEventMethod,
  getFilterCategory,
  getEventSelectedField,
} from 'components/pages/users/Filters/logic/FilterActions';

import servicesStore from 'stores/servicesStore';

const styles = filterStyles.locals;

function FilterActions({
  onApply, onCancel, setCustomDate, showCustomDate, filtersItems = [], disabled,
}) {
  function trackFilterEvents({ filterItems }) {
    for (const filterItem of filterItems) {
      const {
        kind,
        data: {
          fieldId,
          variant,
          position,
          timeFrame,
          fieldIndex,
          selectedFunnel,
          relatedEntities,
          selectedFunnels,
          selectedOptions,
        },
      } = filterItem;

      const properties = {
        isSaved: false,
        group: getFilterCategory({ kind }),
        type: kind,
        objectType: getObjectType({ kind, selectedFunnels }),
        method: getEventMethod({ variant }),
        segmentType: getSegmentType({ filterKind: kind, segmentId: fieldId }),
        selectedField: getEventSelectedField({ kind, fieldIndex }),
        selectedValue: selectedOptions,
        checkedAssociation: relatedEntities,
        checkedBeforeFunnel: !!selectedFunnel,
        checkedCustomDate: !!timeFrame,
        touchpointPosition: position,
      };

      servicesStore.eventTracker.track({
        eventName: Events.filtersAppliedFilter,
        properties,
      });
    }
  }

  return (
    <footer className={styles.filterActions}>
      <div className={styles.row}>
        <Button
          className={classnames(styles.filterActionsButtonWrap, styles.filterActionSecondary)}
          containerClassName={styles.filterActionsButton}
          type="secondaryWhite"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        {showCustomDate && (
        <Button
          className={classnames(
            styles.filterActionsButtonWrap,
            styles.filterActionSecondary,
            styles.filterActionsCustomDate
          )}
          containerClassName={styles.filterActionsButton}
          type="secondaryWhite"
          onClick={() => setCustomDate()}
        >
          Custom Date
        </Button>
        )}
      </div>
      <Button
        type="primaryBlue"
        className={styles.filterActionsButtonWrap}
        containerClassName={styles.filterActionsButton}
        onClick={() => {
          trackFilterEvents({ filterItems: filtersItems });
          onApply();
        }}
        disabled={!filtersItems.length || disabled}
      >
        {`Apply ${filtersItems.length > 0 ? filtersItems.length : ''} filters`}
      </Button>
    </footer>
  );
}

export default FilterActions;
