import {
  cloneDeep, get, orderBy, isEmpty,
} from 'lodash';
import React from 'react';

import attributionStore from 'stores/attributionStore';
import userStore from 'stores/userStore';

import { getGroupedUserMetrics } from 'stores/logic/userStore';
import { makeRawFilters, CONTENT_VIEW_LOOKUP, CATEGORY } from 'components/utils/filters';
import {
  makeCustomFieldsFilter, VARIANTS, makeChannelsFilter, makeFunnelStagesFilter, makeCampaignBeforeStageFilter, makeContentBeforeStageFilter, makeCampaignsFilter, makeContentFilter, makeMarketingVsSalesFilter, makeChannelBeforeStageFilter,
} from 'components/utils/filters/make';
import {
  contentTypeSegmentValue, contentTitleSegmentValue, contentURLSegmentValue, unSupportedMetricForImpactBy, defaultSupportedUtms,
} from 'components/pages/analyze/SegmentsTab/logic/enums';
import { getChannelsWithProps } from 'components/utils/channels';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { filterKinds } from 'components/utils/filters/logic';
import { getFilterConfig } from 'components/pages/users/Filters/logic/FilterActions';
import { singleMetricGroupsToRemove } from 'components/pages/widgetBuilder/propertiesComponents/enums';

export function getFilteredSegmentAnalysisRecords({
  data, filters, segments,
}) {
  if (isEmpty(data)) {
    return [];
  }

  if (!segments) {
    return data;
  }

  const channelsPropsObject = getChannelsWithProps();
  const getData = (item, filterKind, fieldIndex) => {
    if (filterKind === filterKinds.CHANNELS) {
      const isCategory = fieldIndex === CATEGORY;
      if (isCategory) {
        if (item.category) {
          return item.category;
        }
        if (item.channels) {
          return item.channels.map((campaignChannel) => get(channelsPropsObject, [campaignChannel, 'category'])) || [];
        }
        if (Array.isArray(item.channel)) {
          return item.channel.map((campaignChannel) => get(channelsPropsObject, [campaignChannel, 'category'])) || [];
        }
        return get(channelsPropsObject, [item.channel, 'category']);
      }
      return item.channels || item.channel;
    }

    if (filterKind === filterKinds.CONTENT) {
      let fieldNameAccordingFieldIndex = CONTENT_VIEW_LOOKUP[fieldIndex];
      if (fieldNameAccordingFieldIndex === 'channel') {
        fieldNameAccordingFieldIndex = 'content_channel';
      }
      if (fieldNameAccordingFieldIndex === 'url') {
        fieldNameAccordingFieldIndex = 'path';
      }
      const itemToReturn = item[fieldNameAccordingFieldIndex];
      if (itemToReturn) {
        return itemToReturn;
      }
      if (segments.firstSegment === fieldNameAccordingFieldIndex) {
        return item.firstSegment;
      }
      if (segments.secondSegment === fieldNameAccordingFieldIndex) {
        return item.secondSegment;
      }
      return item[fieldNameAccordingFieldIndex];
    }

    if (filterKind === segments.secondSegment) {
      return item.secondSegment;
    }

    if (filterKind === segments.firstSegment) {
      return item.firstSegment;
    }

    return [item.firstSegment, item.secondSegment];
  };

  return attributionStore.getFilteredRecords({ records: data, getData, filters });
}

export function getFilteredImpactBySegmentRecords({
  data = [], filters, segment, metadataBySegment = {},
}) {
  if (filters.length === 0 || !filters.some((filter) => filter.isUiOnly)) {
    return data;
  }

  const segments = { firstSegment: segment };
  const parsedDataToFilter = [];
  for (const item of data) {
    const nameOfPeriod = item.name;
    for (const [segmentName, segmentValue] of Object.entries(item)) {
      if (segmentName === 'name') {
        continue;
      }
      parsedDataToFilter.push({
        name: nameOfPeriod,
        firstSegment: segmentName,
        [segment]: segmentName,
        segment: segmentName,
        value: segmentValue,
        ...(metadataBySegment[segmentName] || {}),
      });
    }
  }

  const filtered = getFilteredSegmentAnalysisRecords({
    data: parsedDataToFilter, filters, segments,
  });

  const mergedData = [];
  for (const filterItem of filtered) {
    const mergedDataIndex = mergedData.findIndex((i) => i.name === filterItem.name);
    if (mergedDataIndex !== -1) {
      mergedData[mergedDataIndex][filterItem.segment] = filterItem.value;
      continue;
    }
    mergedData.push({
      name: filterItem.name,
      [filterItem.segment]: filterItem.value,
    });
  }

  return mergedData;
}

export function getFiltersForShowOnlyRequest({
  firstSegment, value, filters = [], isContentSegmentsFirstURLSecondType = false,
}) {
  const filtersRequest = makeRawFilters({ filters });

  if (firstSegment === 'channel') {
    filtersRequest.push({ ...makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [value]), isUiOnly: true });
  } else if (firstSegment === 'category') {
    filtersRequest.push({ ...makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [value], true), isUiOnly: true });
  } else if (firstSegment === 'campaign') {
    filtersRequest.push({ ...makeCampaignsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [value]), isUiOnly: true });
  } else if (firstSegment === contentTypeSegmentValue) {
    filtersRequest.push({ ...makeContentFilter(2, VARIANTS.INCLUDE_ANY_OF_EXACT, [value]), isUiOnly: true });
  } else if (firstSegment === contentTitleSegmentValue || isContentSegmentsFirstURLSecondType) {
    filtersRequest.push({ ...makeContentFilter(0, VARIANTS.INCLUDE_ANY_OF_EXACT, [value]), isUiOnly: true });
  } else if (firstSegment === contentURLSegmentValue) {
    filtersRequest.push({ ...makeContentFilter(1, VARIANTS.INCLUDE_ANY_OF_EXACT, [value]), isUiOnly: true });
  } else if (firstSegment === 'department') {
    filtersRequest.push({ ...makeMarketingVsSalesFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [value]), isUiOnly: true });
  } else {
    filtersRequest.push({ ...makeCustomFieldsFilter(firstSegment, firstSegment, VARIANTS.INCLUDE_ANY_OF_EXACT, [value], false), isUiOnly: true });
  }

  return filtersRequest;
}

export function getFiltersForViewJourneysRequest({
  firstSegment, value, kpiFocus, funnels, relatedEntitiesIndication = {}, widgetHeaderConfig = {},
}) {
  const widgetFilters = widgetHeaderConfig.filters || [];
  const filters = [...widgetFilters];

  if (firstSegment === 'channel') {
    filters.push(makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF, [value], false, funnels));
    filters.push(makeChannelBeforeStageFilter([value], kpiFocus, VARIANTS.INCLUDE_ANY_OF, false, funnels));
  } else if (firstSegment === 'category') {
    filters.push(makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF, [value], true, funnels));
    filters.push(makeChannelBeforeStageFilter([value], kpiFocus, VARIANTS.INCLUDE_ANY_OF, true, funnels));
  } else if (firstSegment === 'campaign') {
    filters.push(makeCampaignBeforeStageFilter([value], kpiFocus, funnels));
  } else if (firstSegment === contentTypeSegmentValue) {
    filters.push(makeContentBeforeStageFilter([value], kpiFocus, VARIANTS.INCLUDE_ANY_OF, 2, funnels));
  } else if (firstSegment === contentTitleSegmentValue) {
    filters.push(makeContentBeforeStageFilter([value], kpiFocus, VARIANTS.INCLUDE_ANY_OF, 0, funnels));
  } else if (firstSegment === contentURLSegmentValue) {
    filters.push(makeContentBeforeStageFilter([value], kpiFocus, VARIANTS.INCLUDE_ANY_OF, 1, funnels));
  } else if (firstSegment === 'department') {
    filters.push(makeMarketingVsSalesFilter(VARIANTS.INCLUDE_ANY_OF, [value]));
  } else {
    let valueForNavigation = value;
    if (valueForNavigation === 'No Value') {
      valueForNavigation = 'empty';
    }
    const useRelatedEntities = Object.values(relatedEntitiesIndication).some((indication) => indication[value]);
    filters.push(makeCustomFieldsFilter(firstSegment, firstSegment, VARIANTS.INCLUDE_ANY_OF, valueForNavigation.split(','), useRelatedEntities, funnels));
  }

  return [...filters, makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [kpiFocus])];
}

export function getFiltersWithoutFocusMode({ filters = [], focusKey }) {
  const currentFilters = [...filters];
  const filtersWithoutFocusMode = currentFilters?.filter((filter) => !filter.isUiOnly || !filter.data.selectedOptions.includes(focusKey));
  const rawFilters = makeRawFilters({ filters: filtersWithoutFocusMode });

  return rawFilters;
}

export function getSegmentLabel({ segment, customFieldsIdToLabelMap, customUtmsWhitelist }) {
  const allSegmentOptions = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
  const selectedSegment = allSegmentOptions.find((segmentOption) => segmentOption.value === segment);
  return selectedSegment ? selectedSegment.label : segment;
}

export function parsedMetricsForImpactByOptions({ metrics }) {
  let updatedMetrics = cloneDeep(metrics);
  for (const metric of updatedMetrics) {
    let metricName = metric.metricName;
    if (metricName === 'newBookings') {
      metricName = 'revenue';
    }
    if (metricName === 'newPipeline') {
      metricName = 'pipeline';
    }
    if (metricName.includes('costPer')) {
      metricName = 'efficiency';
    }
    metric.metricName = metricName;
  }
  updatedMetrics = updatedMetrics.filter((metric) => (
    !unSupportedMetricForImpactBy.includes(metric.metricName)
    && !singleMetricGroupsToRemove.includes(metric.relevantFor)
  ));
  return updatedMetrics;
}

export function getDrilldownMetricsOptions({ indicator, isRemoveIndicatorFromOptions }) {
  const userMetrics = userStore.userMetrics;
  const parsedMetrics = parsedMetricsForImpactByOptions({ metrics: userMetrics });
  const groupedMetrics = getGroupedUserMetrics({ userMetrics: parsedMetrics });

  if (indicator) {
    const indicatorGroup = groupedMetrics.find((group) => group.groupName === indicator)?.options;
    if (isRemoveIndicatorFromOptions) {
      return indicatorGroup?.filter((metric) => metric.value !== indicator) || [];
    }
    return indicatorGroup || [];
  }

  return groupedMetrics;
}

export function segmentsGroupByCommonSegments({ options, withNoneValue }) {
  const commonSegmentsValues = userStore.commonSegments;
  const commonOptions = [];
  const advancedOptions = [];
  const customSegments = [];

  if (withNoneValue) {
    commonOptions.push({ label: 'None', value: null, order: 0 });
  }

  for (const segment of options) {
    const commonSegmentIndex = commonSegmentsValues.indexOf(segment.value);
    if (commonSegmentIndex === -1) {
      const isCustomSegment = !!userStore.userAccount?.customSegmentsMapping?.[segment.value];

      if (isCustomSegment) {
        customSegments.push(segment);
        continue;
      }

      advancedOptions.push({ ...segment, label: segment.label, value: segment.value });
    } else {
      commonOptions.push({
        ...segment, label: segment.label, value: segment.value, order: commonSegmentIndex + 1,
      });
    }
  }

  const reorderCommonOptions = orderBy(commonOptions, ['order'], ['asc']);

  return [{
    label: 'Common',
    options: reorderCommonOptions,
  }, {
    label: 'Advanced',
    options: [
      ...customSegments,
      ...advancedOptions,
    ],
  }];
}

export function getOptionsWithSegmentMapping({ options }) {
  for (const mainOptions of options) {
    for (const option of mainOptions.options) {
      const customSegmentMapping = userStore.userAccount?.customSegmentsMapping?.[option.value];
      if (!customSegmentMapping) {
        continue;
      }
      option.disabled = !customSegmentMapping.enabled;
      option.icon = (<img alt="customSegment" src="/assets/analyze-icons/custom-segment.svg" />);
    }
  }

  return options;
}

export function getSegmentType({ filterKind, segmentId }) {
  if (filterKind) {
    const filterConfig = getFilterConfig({ kind: filterKind });
    if (!filterConfig.isSegmentFilter) {
      return undefined;
    }
  }

  if (userStore.userAccount?.customSegmentsMapping?.[segmentId]) {
    return 'Custom Segment';
  }

  if (userStore.userAccount?.customFieldsIdToLabelMap?.[segmentId]) {
    return 'CRM Field';
  }

  const customUtms = userStore.userAccount?.customUtmsWhitelist || [];
  const allUtmItems = new Set([
    ...defaultSupportedUtms,
    ...customUtms,
  ]);

  if (allUtmItems.has(segmentId)) {
    return 'UTM';
  }

  return 'Attribution Field';
}
