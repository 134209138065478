import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { AsyncPaginate } from 'react-select-async-paginate';

import CustomCheckbox from 'components/controls/CustomCheckbox';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Tooltip from 'components/controls/Tooltip';

import { selectLoadMoreOptions } from 'components/controls/logic/AsyncSelect';
import { getFilteredOptions, flattenSelectedOptions, filterOptionByLabel } from 'components/controls/logic/multiCheckSelect';

import multiCheckStyle from 'styles/controls/multi-check-select.css';
import dropdownStyle from 'styles/controls/dropdown.css';

const styles = multiCheckStyle.locals || {};
const dropdownStyles = dropdownStyle.locals || {};

const MultiCheckSelect = ({
  selectAll,
  options = [],
  selected,
  placeholder,
  isDropDownSelect,
  maxWidth,
  maxHeight,
  searchFunction,
  isAsyncPagination,
  onChange,
  onSearchTermChange,
  className,
  style,
  isClearable,
  isGroupCheckedAsRadio,
  controlWidth,
  isSearchIconPlaceholder = true,
  isSearchable = true,
  isDropdownIndicator,
  isMultiLabelAsText,
  controlShouldRenderValue,
  overrideLoadOptions,
  debounceTimeout = 1000,
  optionRenderer,
  controlHeight,
  disabled,
  backspaceRemovesValue = true,
}) => {
  useEffect(() => {
    multiCheckStyle.use();
    dropdownStyle.use();

    return () => {
      if (typeof multiCheckStyle === 'object') {
        return;
      }
      multiCheckStyle.unuse();
      dropdownStyle.unuse();
    };
  }, []);

  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (onSearchTermChange) {
      onSearchTermChange(searchTerm);
    }
  }, [searchTerm]);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: '#222a41',
      backgroundColor: 'transparent',
      padding: '7px 0px',
      fontSize: '14px',
      fontWeight: 'normal',
      display: 'flex',
    }),
    control: (provided) => ({
      ...provided,
      maxWidth,
      borderColor: '#DCE1EF',
      color: '#707EA7',
      width: controlWidth,
      cursor: 'pointer',
      backgroundColor: disabled ? '#FBFBFD' : '#FFFFFF',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'unset',
      position: 'unset',
      maxHeight: maxHeight || 400,
      overflow: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: maxHeight - 100 || 300,
    }),
    placeholder: (provided) => (isSearchIconPlaceholder ? ({
      ...provided,
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#707EA7',
      paddingLeft: '15px',
    }) : {
      fontSize: '14px',
      color: '#707EA7',
      paddingLeft: '4px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: controlHeight || 100,
      overflow: 'auto',
    }),
    multiValueLabel: (base) => ({
      ...base,
      backgroundColor: '#eef4ff',
      color: '#4D91FC',
      fontSize: '14px',
      border: 'none',
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: '#eef4ff',
      color: '#4D91FC',
      cursor: 'pointer',
    }),
  };

  const customDropdownStyles = {
    ...customStyles,
    menu: (provided) => ({
      ...provided,
      padding: '0 0 0 12px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      marginRight: '4px',
    }),
    option: (provided) => ({
      ...provided,
      color: '#707EA7',
      backgroundColor: 'transparent',
      padding: '6px 0px',
      display: 'flex',
      fontSize: '14px',
    }),
    groupHeading: (provided) => (isGroupCheckedAsRadio ? ({
      ...provided,
      padding: '0',
      marginRight: '12px',
    }) : {
      ...provided,
      color: '#182033',
      textTransform: 'none',
      fontSize: '12px',
      padding: '0',
    }),
  };

  function getIsSelectAllClicked({ filteredOptions, selectedOptions = selected }) {
    if (!selectedOptions) {
      return false;
    }
    return filteredOptions.every((item) => selectedOptions.some((selectedItem) => selectedItem.value === item.value)) && filteredOptions.length > 0;
  }

  const selectAllHandler = (selectedOptions) => {
    if (isSelectAllClicked) {
      onChange([]);
      setIsSelectAllClicked(false);
    } else if (searchTerm !== '' && !isAsyncPagination) {
      const filteredOptions = getFilteredOptions({ searchTerm, options });
      onChange([...selected, ...filteredOptions]);
      setIsSelectAllClicked(true);
    } else {
      onChange(flattenSelectedOptions({ options: selectedOptions }));
      setIsSelectAllClicked(true);
    }
  };

  const selectOptionsHandler = (items) => {
    if (!items) {
      onChange([]);
    } else {
      onChange(items);
    }
    const filteredOptions = getFilteredOptions({ searchTerm, options });
    const isAllSelected = getIsSelectAllClicked({ filteredOptions, selectedOptions: items });
    setIsSelectAllClicked(isAllSelected);
  };

  const onInputChange = (
    inputValue,
    event
  ) => {
    if (event.action === 'input-change') {
      const filteredOptions = getFilteredOptions({ searchTerm: inputValue, options });
      const isAllSelected = getIsSelectAllClicked({ filteredOptions });
      setSearchTerm(inputValue);
      setIsSelectAllClicked(isAllSelected);
    }
  };

  const Option = useMemo(() => (props) => {
    const tooltipContent = props.data.tooltip || props.label;
    return (
      <components.Option {...props}>
        <CustomCheckbox
          key={`customCheckbox-${props.label}`}
          checked={props.isSelected || props.data.required}
          onChange={() => null}
          className={styles.checkboxContainer}
          checkboxClassName={styles.checkbox}
          checkMarkClassName={styles.checkboxMark}
          childrenClassName={styles.checkboxLabel}
          disabled={props.isDisabled}
        />
        <Tooltip
          tip={tooltipContent}
          id={uniqueId('option-label-')}
          place="right"
        >
          {optionRenderer ? optionRenderer(props) : (
            <label className={classNames(styles.optionLabel, props.isDisabled && styles.optionLabelDisabled)}>
              {props.label}
            </label>
          )}
        </Tooltip>
      </components.Option>
    );
  }, []);

  const Menu = useMemo(() => ({
    children, ...props
  }) => (
    <components.Menu {...props}>
      <div>
        {props.options.length > 0 && (
          <div className={styles.selectAllOptions}>
            <div className={styles.selectAll}>
              <CustomCheckbox
                checked={isSelectAllClicked}
                onChange={() => selectAllHandler(props.options)}
                className={styles.checkboxContainer}
                checkboxClassName={styles.checkbox}
                checkMarkClassName={styles.checkboxMark}
                childrenClassName={styles.checkboxLabel}
              />
              <label>
                {'Select all '}
                {searchTerm !== '' && `matching “${searchTerm}”`}
              </label>
            </div>
            {children}
          </div>
        )}
        {props.isLoading ? (
          <div className={styles.noOptionsMessage}>Loading...</div>
        ) : (
          props.options.length === 0 && (
          <div className={styles.noOptionsMessage}>No options</div>
          )
        )}
      </div>
    </components.Menu>
  ), [searchTerm, isSelectAllClicked]);

  const MultiValue = useMemo(() => (props) => (
    <components.MultiValue {...props}>
      <span>
        <EllipsisTooltip
          text={props.data.label}
          place="bottom"
          TooltipProps={{ wide: true }}
        />
      </span>
    </components.MultiValue>
  ), []);

  const MultiValueAsText = useMemo(() => (props) => (
    <span className={styles.multiValueAsText}>{props.data.label}</span>
  ), []);

  const Placeholder = useMemo(() => (props) => (
    <div>
      <components.Placeholder {...props} />
      <div style={{
        background: 'url(/assets/search-icon.svg) center center no-repeat',
        backgroundSize: 'contain',
        width: '12px',
        height: '12px',
      }}
      />
    </div>
  ), []);

  const DropdownIndicator = useMemo(() => (prop) => (
    <components.DropdownIndicator {...prop}>
      <div className={dropdownStyles.arrowDown} />
    </components.DropdownIndicator>
  ), []);

  const GroupHeadingDivider = (prop) => (
    <components.GroupHeading {...prop}>
      <div className={styles.groupHeadingDivider} />
    </components.GroupHeading>
  );

  const overrideComponents = {
    Option,
    MultiValue,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  if (isSearchIconPlaceholder) {
    overrideComponents.Placeholder = Placeholder;
  }

  if (isDropdownIndicator) {
    overrideComponents.DropdownIndicator = DropdownIndicator;
  }

  if (selectAll) {
    overrideComponents.Menu = Menu;
  }

  if (isGroupCheckedAsRadio) {
    overrideComponents.GroupHeading = GroupHeadingDivider;
  }

  if (isMultiLabelAsText) {
    overrideComponents.MultiValue = MultiValueAsText;
  }
  const loadMoreOptionsFunction = (searchValue, prevOptions) => selectLoadMoreOptions({ searchValue, prevOptions, searchFunction });

  return (
    <div style={style} className={className}>
      {isAsyncPagination ? (
        <AsyncPaginate
          value={selected}
          loadOptions={overrideLoadOptions || loadMoreOptionsFunction}
          onChange={selectOptionsHandler}
          debounceTimeout={debounceTimeout}
          placeholder={placeholder}
          styles={customStyles}
          components={overrideComponents}
          menuIsOpen
          hideSelectedOptions={false}
          backspaceRemovesValue={backspaceRemovesValue}
          isMulti
          controlShouldRenderValue={controlShouldRenderValue}
          isClearable={isClearable}
          className={isMultiLabelAsText ? styles.multiValueControlAsText : null}
          isDisabled={disabled}
          onInputChange={onInputChange}
          inputValue={searchTerm}
          filterOption={(option, searchText) => filterOptionByLabel({ option, searchText })}
        />
      ) : isDropDownSelect ? (
        <Select
          closeMenuOnSelect={false}
          components={overrideComponents}
          value={selected}
          options={options}
          styles={customDropdownStyles}
          hideSelectedOptions={false}
          backspaceRemovesValue={backspaceRemovesValue}
          placeholder={placeholder}
          onChange={selectOptionsHandler}
          onInputChange={onInputChange}
          isSearchable={isSearchable}
          isClearable={isClearable}
          className={isMultiLabelAsText ? styles.multiValueControlAsText : null}
          isMulti
          controlShouldRenderValue={controlShouldRenderValue}
          isDisabled={disabled}
          inputValue={searchTerm}
          filterOption={(option, searchText) => filterOptionByLabel({ option, searchText })}
        />
      ) : (
        <Select
          closeMenuOnSelect={false}
          components={overrideComponents}
          value={selected}
          options={options}
          styles={customStyles}
          hideSelectedOptions={false}
          menuIsOpen
          backspaceRemovesValue={backspaceRemovesValue}
          placeholder={placeholder}
          onChange={selectOptionsHandler}
          onInputChange={onInputChange}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isMulti
          controlShouldRenderValue={controlShouldRenderValue}
          isDisabled={disabled}
          inputValue={searchTerm}
          filterOption={(option, searchText) => filterOptionByLabel({ option, searchText })}
        />
      )}
    </div>
  );
};

export default MultiCheckSelect;
