import userStore from 'stores/userStore';
import filterStore from 'stores/filterStore';
import {
  filterKinds,
} from 'components/utils/filters/logic';
import { VARIANTS_CONFIGS } from 'components/utils/filters';
import { filtersUIConfig, filtersCategoryConfig } from 'components/pages/users/Filters/FilterPopup';

export function getFilterConfig({ kind }) {
  if (kind === filterKinds.CAMPAIGN_TAGS) {
    return filtersUIConfig[filterKinds.CAMPAIGNS];
  }
  return filtersUIConfig[kind] || {};
}

export function getFilterCategory({ kind }) {
  const filterConfig = getFilterConfig({ kind });

  const { categotyId } = filterConfig || {};

  const categoryConfig = filtersCategoryConfig.find((config) => config.id === categotyId) || {};

  return categoryConfig.title;
}

export function getObjectType({ kind, selectedFunnels }) {
  const filterConfig = getFilterConfig({ kind });

  if (!filterConfig.supportsSelecetedFunnel) {
    return 'None';
  }

  if (!selectedFunnels || selectedFunnels.length === 0) {
    return 'Accounts';
  }

  if (selectedFunnels.length === userStore.funnels.length) {
    return 'All Funnel Stages';
  }

  return 'Funnel Stages';
}

export function getEventMethod({ variant }) {
  const variantConfig = VARIANTS_CONFIGS[variant];
  return variantConfig?.label;
}

export function getEventSelectedField({ kind, fieldIndex }) {
  const filterProps = filterStore.filtersData.find((filterData) => filterData.kind === kind);

  if (!filterProps) {
    return undefined;
  }

  return filterProps.fieldNames[fieldIndex];
}
