import {
  get,
  isNaN,
  isNil,
  camelCase,
} from 'lodash';
import React from 'react';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import StatSquare from 'components/common/StatSquare';
import WidgetHeader from 'components/common/WidgetHeader';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';

import { formatBudget, roiFormatter } from 'components/utils/budget';
import { formatIndicatorDisplay, getPipelineFunnel, getRevenueFunnel } from 'components/utils/indicators';
import { makeCostGraterThenFilter, makeFunnelStagesFilter, VARIANTS } from 'components/utils/filters/make';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { widgetTypes } from 'components/pages/analyze/enums';
import { calcCostPerFunnel, getSelectedMetricsForStatsSquare } from 'components/pages/analyze/OverviewTab/logic/statsSquares';

import style from 'styles/analyze/analyze.css';

class StatsSquares extends Component {
  style = style;

  getStatSquareData() {
    const includeAttributionStoreFilters = isNil(this.props.widgetHeaderConfig);
    const {
      totalCost, widgetHeaderConfig = {}, revenueAndPipeline = {}, growthValues,
    } = this.props;
    const {
      totalPipeline, totalRevenue, marketingRevenue, marketingPipeline, marketingPipelineROI, marketingROI,
    } = revenueAndPipeline;

    const widgetFilters = widgetHeaderConfig.filters || [];
    const { timeFrame, attributionModel } = widgetHeaderConfig;

    const safeDivision = (value) => (isNaN(value) ? 0 : value);

    const percent = (v) => `${Math.round(v * 100)}%`;
    const budgetFormat = (v) => `${formatBudget(v, true, true)}`;
    const _roiFormatter = (v) => roiFormatter(safeDivision(v));
    const pipelineFunnel = getPipelineFunnel();
    const revenueFunnel = getRevenueFunnel();

    return [
      {
        title: 'Total Cost',
        formatter: budgetFormat,
        value: totalCost,
        metricName: 'totalCost',
        showGrowth: false,
        iconUrl: '/assets/analyze-icons/stat-total-cost.svg',
        navigationMenuPopupParams: [{
          title: 'View campaigns',
          subTitle: 'Analyze spend per campaign',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeCostGraterThenFilter(0, [])], tabName: 'campaigns', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View channels',
          subTitle: 'Analyze spend per channel',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeCostGraterThenFilter(0, [])], tabName: 'channels', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        }],
      },
      {
        title: 'Marketing-Attributed Pipeline',
        formatter: budgetFormat,
        value: marketingPipeline,
        metricName: 'marketingPipeline',
        iconUrl: '/assets/analyze-icons/stat-total-pipeline.svg',
        showGrowth: true,
        growth: growthValues.revenueAndPipeline?.marketingPipeline,
        context: {
          stat: percent(safeDivision(marketingPipeline / totalPipeline)),
          text: ' of total pipeline',
          type: 'positive',
          tooltipText: `Total Pipeline: ${formatBudget(totalPipeline, true, true)}`,
        },
        navigationMenuPopupParams: [{
          title: 'View journeys',
          subTitle: `Analyze ${userStore.getMetricNickname({ metric: pipelineFunnel })} created during the timeframe`,
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [pipelineFunnel])], tabName: 'journeys', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View channels',
          subTitle: 'Analyze pipeline contribution by channel',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: pipelineFunnel, tabName: 'channels', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View campaigns',
          subTitle: 'Analyze pipeline contribution by campaign',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: pipelineFunnel, tabName: 'campaigns', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View content',
          subTitle: 'Analyze pipeline contribution by content asset',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: pipelineFunnel, tabName: 'content', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        }],
      },
      {
        title: 'Marketing Pipeline ROI',
        formatter: _roiFormatter,
        value: marketingPipelineROI,
        metricName: 'marketingPipelineROI',
        iconUrl: '/assets/analyze-icons/stat-pipeline-roi.svg',
        showGrowth: true,
        growth: growthValues.revenueAndPipeline?.marketingPipelineROI,
        context: {
          stat: roiFormatter(safeDivision(totalPipeline / totalCost)),
          text: ' Total Pipeline ROI',
        },
        navigationMenuPopupParams: [{
          title: 'View journeys',
          subTitle: `Analyze ${userStore.getMetricNickname({ metric: pipelineFunnel })} created during the timeframe`,
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [pipelineFunnel])], tabName: 'journeys', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View channels',
          subTitle: 'Analyze pipeline ROI by channel',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: pipelineFunnel, tabName: 'channels', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View campaigns',
          subTitle: 'Analyze pipeline contribution by campaign',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: pipelineFunnel, tabName: 'campaigns', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View content',
          subTitle: 'Analyze pipeline ROI by content asset',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: pipelineFunnel, tabName: 'content', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        }],
      },
      {
        title: 'Marketing-Attributed Revenue ',
        formatter: budgetFormat,
        value: marketingRevenue,
        metricName: 'marketingRevenue',
        iconUrl: '/assets/analyze-icons/stat-total-revenue.svg',
        showGrowth: true,
        growth: growthValues.revenueAndPipeline?.marketingRevenue,
        context: {
          stat: percent(safeDivision(marketingRevenue / totalRevenue)),
          text: ' of total revenue',
          tooltipText: `Total Revenue: ${formatBudget(totalRevenue, true, true)}`,
          type: 'positive',
        },
        navigationMenuPopupParams: [{
          title: 'View journeys',
          subTitle: `Analyze ${userStore.getMetricNickname({ metric: revenueFunnel })} created during the timeframe`,
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [revenueFunnel])], tabName: 'journeys', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View channels',
          subTitle: 'Analyze revenue contribution by channel',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: revenueFunnel, tabName: 'channels', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View campaigns',
          subTitle: 'Analyze revenue contribution by campaign',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: revenueFunnel, tabName: 'campaigns', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View content',
          subTitle: 'Analyze revenue contribution by content asset',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: revenueFunnel, tabName: 'content', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        }],
      },
      {
        title: 'Marketing ROI',
        formatter: _roiFormatter,
        value: marketingROI,
        metricName: 'marketingROI',
        iconUrl: '/assets/analyze-icons/stat-pipeline-roi.svg',
        showGrowth: true,
        growth: growthValues.revenueAndPipeline?.marketingROI,
        context: {
          stat: roiFormatter(safeDivision(totalRevenue / totalCost)),
          text: ' Total ROI',
        },
        navigationMenuPopupParams: [{
          title: 'View journeys',
          subTitle: `Analyze ${userStore.getMetricNickname({ metric: revenueFunnel })} created during the timeframe`,
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [revenueFunnel])], tabName: 'journeys', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View channels',
          subTitle: 'Analyze ROI by channel',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: revenueFunnel, tabName: 'channels', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View campaigns',
          subTitle: 'Analyze ROI by campaign',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: revenueFunnel, tabName: 'campaigns', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        },
        {
          title: 'View content',
          subTitle: 'Analyze ROI by content asset',
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: widgetFilters, conversionIndicator: revenueFunnel, tabName: 'content', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
          }),
        }],
      },
    ];
  }

  getCostPerNavigationPopupParams(indicator) {
    const widgetFilters = this.props.widgetHeaderConfig?.filters || [];
    const includeAttributionStoreFilters = isNil(this.props.widgetHeaderConfig);
    const attributionModel = this.props.widgetHeaderConfig?.attributionModel;
    const timeFrame = this.props.widgetHeaderConfig?.timeFrame;

    return [{
      title: 'View channels',
      subTitle: `Analyze cost per ${userStore.getMetricNickname({ metric: indicator })} by channel`,
      navigationFunction: () => navigateBetweenAnalyzeTabs({
        filters: widgetFilters, conversionIndicator: indicator, tabName: 'channels', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
      }),
    },
    {
      title: 'View campaigns',
      subTitle: `Analyze cost per ${userStore.getMetricNickname({ metric: indicator })} by campaign`,
      navigationFunction: () => navigateBetweenAnalyzeTabs({
        filters: widgetFilters, conversionIndicator: indicator, tabName: 'campaigns', anchor: 'attribution-table', includeAttributionStoreFilters, timeFrame, attributionModel,
      }),
    },
    ];
  }

  render() {
    const {
      indicatorsCount,
      totalCost,
      prevIndicatorsCount,
      prevTotalCost,
      customStyle,
      widgetHeaderConfig = {
        title: 'Overview metrics',
        type: widgetTypes.statsSquare,
        selectedMetrics: getSelectedMetricsForStatsSquare({ userFunnels: userStore.funnels }),
      },
      widgetHeaderProps = {},
      isCompareToPreviousEnabled,
      growthValues,
      isLoaded,
      isFailedToLoad,
    } = this.props;

    if (isFailedToLoad) {
      return (
        <ErrorWidgetWithBlur
          status="error"
          widgetType={widgetTypes.statsSquare}
        />
      );
    }

    const costPerFunnel = calcCostPerFunnel({ indicatorsCount, totalCost });
    const prevCostPerFunnel = calcCostPerFunnel({ indicatorsCount: prevIndicatorsCount, totalCost: prevTotalCost });

    const getTooltipText = (indicator) => `
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px 0; font-weight: 500;">
            <div style="font-size: 12px; color: #99a4c2; margin-bottom: 7px;">
                Based on
            </div>
            <span style="font-size: 16px; color: white">
                ${formatIndicatorDisplay(indicator, get(costPerFunnel, [indicator, 'funnelCount'], 0))} ${userStore.getMetricNickname({ metric: indicator })}
            </span>
        </div>
    `;
    return (
      <div className={this.classes.widgetSpace}>
        <WidgetHeader
          widgetHeaderConfig={widgetHeaderConfig}
          {...widgetHeaderProps}
        />
        <div className={this.classes.statsWrapper} style={customStyle}>
          {this.getStatSquareData().map((statProps) => (
            <StatSquare
              className={this.classes.statSquare}
              containerClassName={this.classes.statSquareContainer}
              key={statProps.title}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...statProps}
              stat={statProps.formatter(statProps.value)}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isLoaded={isLoaded}
            />
          ))}
          {Object.keys(costPerFunnel).map((indicator) => (
            <StatSquare
              key={indicator}
              metricName={camelCase(`costPer ${indicator}`)}
              stat={costPerFunnel[indicator].cost ? formatBudget(costPerFunnel[indicator].cost) : '-'}
              value={costPerFunnel[indicator].cost}
              prevValue={get(prevCostPerFunnel, [indicator, 'cost'], 0)}
              iconText={userStore.getMetricNickname({ metric: indicator, isSingular: true })}
              iconTooltip={getTooltipText(indicator)}
              title={`Cost per ${userStore.getMetricNickname({ metric: indicator, isSingular: true })}`}
              className={this.classes.statSquare}
              containerClassName={this.classes.statSquareContainer}
              navigationMenuPopupParams={this.getCostPerNavigationPopupParams(indicator)}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              showGrowth
              growth={growthValues.costPer?.[indicator]}
              isLoaded={isLoaded}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default WithPersonalSpinner()(StatsSquares);
