import React, { useRef, useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import InfoMarker from 'components/pages/InfoMarker';
import Tooltip from 'components/controls/Tooltip';
import Textfield from 'components/controls/Textfield';
import CustomCheckbox from 'components/controls/CustomCheckbox';

import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { getEmptyMergingConfiguration } from 'components/pages/createIntegration/logic/CRMImportFieldsGroup';

import integrationStyle from 'styles/integrations/integration.css';

import CRMImportFieldsRow from './CRMImportFieldsRow';

const styles = integrationStyle.locals;

function CRMImportFieldsGroup({
  fieldGroupIndex, fieldsGroupData,
}) {
  const {
    selectedCRMItems, CRMImportFieldsMapping, setParentState,
  } = useCRMCContext();
  const [showMergedFields, setShowMergedFields] = useState(false);
  const fieldsGroupLabelRef = useRef();

  const addMergingConfiguration = () => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    const updatedMergingConfiguration = updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration;
    updatedMergingConfiguration.push(getEmptyMergingConfiguration(selectedCRMItems));
    updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration = updatedMergingConfiguration;
    setShowMergedFields(true);
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const onDeleteMergingConfiguration = (rowIndex, fieldToMergeIndex) => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    const updatedMergingConfiguration = updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration;
    const updatedFieldsToMerge = updatedMergingConfiguration[rowIndex].fieldsToMerge;

    if (updatedFieldsToMerge.length > 1) {
      updatedFieldsToMerge.splice(fieldToMergeIndex, 1);
      updatedMergingConfiguration[rowIndex].fieldsToMerge = updatedFieldsToMerge;
      setShowMergedFields(true);
      setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
    } else if (updatedMergingConfiguration.length > 1) {
      updatedMergingConfiguration.splice(rowIndex, 1);
      setShowMergedFields(true);
      setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
    } else {
      setParentState({ showConfirmDeleteImportFieldsPopup: { isShowPopup: true, index: fieldGroupIndex, label: updatedCRMImportFieldsMapping[fieldGroupIndex].label } });
    }
  };

  const onShowMergedFields = () => {
    setShowMergedFields(!showMergedFields);
  };

  const onFinishEditFieldsGroupLabel = (event) => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    updatedCRMImportFieldsMapping[fieldGroupIndex].isRenameFieldsGroup = true;
    updatedCRMImportFieldsMapping[fieldGroupIndex].label = event.target.value;
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const onEditFieldsGroupLabel = (event) => {
    if (event.key === 'Enter') {
      fieldsGroupLabelRef.current.refs.input.blur();
    }
    onFinishEditFieldsGroupLabel(event);
  };

  const onChangeIsAttribution = () => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    updatedCRMImportFieldsMapping[fieldGroupIndex].isAttribution = !updatedCRMImportFieldsMapping[fieldGroupIndex].isAttribution;
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const countImportFieldGroup = () => {
    let count = 0;
    for (const mergingConfig of fieldsGroupData.mergingConfiguration) {
      const { fieldsToMerge } = mergingConfig;
      count += fieldsToMerge.length;
    }
    return count;
  };

  const isEmptyMergingConfigurationFields = fieldsGroupData.mergingConfiguration.every((mergingItem) => get(mergingItem, ['fieldGroupIndex', 'length']) === 0);

  if (isEmptyMergingConfigurationFields) {
    return <></>;
  }

  return (
    <div className={styles.customFieldsGroup}>
      <div className={styles.customFieldWrapper}>
        <div className={styles.customFieldItemNameGroup}>
          <div className={styles.customFieldItemName}>
            <h2>
              <Tooltip
                id="channelEdit"
                tip="Rename"
              >
                <Textfield
                  value={fieldsGroupData.label}
                  placeHolder="Name..."
                  className={styles.funnelInputTitle}
                  onChange={onEditFieldsGroupLabel}
                  onKeyDown={onEditFieldsGroupLabel}
                  ref={fieldsGroupLabelRef}
                />
              </Tooltip>
            </h2>
            <div className={styles.customFieldItemAttribution}>
              <CustomCheckbox
                checked={fieldsGroupData.isAttribution}
                onChange={onChangeIsAttribution}
                className={styles.checkboxContainer}
                checkboxClassName={styles.checkbox}
                checkMarkClassName={styles.checkboxMark}
              />
              Use for attribution
              <InfoMarker tooltipText="Check the box if this field will contain touchpoint data (such as source, campaign, etc)" TooltipProps={{ place: 'right' }} />
            </div>
          </div>
          {countImportFieldGroup() > 1 && (
            <div onClick={onShowMergedFields} className={styles.customFieldItemShowItems}>
              <div className={classNames(showMergedFields ? styles.arrowDown : styles.arrowUp)} />
              {showMergedFields ? 'Hide ' : 'Show '}
              {`merged fields (${countImportFieldGroup()})`}
            </div>
          )}
        </div>
        <div className={styles.customFieldItemSettingGroup}>
          {fieldsGroupData.mergingConfiguration.map((crmFields, rowIndex) => {
            const { fieldsToMerge } = crmFields;
            return (
              fieldsToMerge.map((fieldToMergeLabel, fieldToMergeIndex) => {
                const CRMImportFieldsRowKey = `CRMImportFieldsRowKey-${fieldToMergeLabel}${fieldToMergeIndex}`;
                return (
                  <CRMImportFieldsRow
                    key={CRMImportFieldsRowKey}
                    onDeleteMergingConfiguration={() => onDeleteMergingConfiguration(rowIndex, fieldToMergeIndex)}
                    crmFields={crmFields}
                    fieldGroupIndex={fieldGroupIndex}
                    rowIndex={rowIndex}
                    fieldToMergeIndex={fieldToMergeIndex}
                    fieldToMergeLabel={fieldToMergeLabel}
                    showMergedFields={showMergedFields}
                  />
                );
              })
            );
          })}
          <Button onClick={addMergingConfiguration} type="secondaryBlue">
            <div className={styles.addFieldButtonIcon} data-icon="platform:addFieldIconBlue" />
            Merge additional field
          </Button>
        </div>
      </div>

    </div>
  );
}

export default CRMImportFieldsGroup;
