import { isNil } from 'lodash';

import userStore from 'stores/userStore';

import { formatIndicatorDisplay } from 'components/utils/indicators';

export function getUpliftData({
  totalAmountScenario, totalAmountBaseline, scenarioLabel, kpiFocus, isBaseline,
}) {
  if (isBaseline || isNil(totalAmountScenario) || isNil(totalAmountBaseline)) {
    return {};
  }
  const upliftAmount = (((totalAmountScenario - totalAmountBaseline) / totalAmountBaseline) * 100).toFixed(2);
  const isDesired = upliftAmount > 0;
  const difference = Math.abs(totalAmountScenario - totalAmountBaseline);
  const tooltip = `${scenarioLabel} ${isDesired ? 'increases' : 'decreases'} your ${userStore.getMetricNickname({ metric: kpiFocus })} by ${formatIndicatorDisplay(kpiFocus, difference, true)} (a ${upliftAmount}% uplift vs. baseline).`;

  return { value: upliftAmount, isDesired, tooltip };
}
