import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTable, { ReactTableDefaults } from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import {
  cloneDeep, groupBy, isEqual, orderBy, sumBy, throttle,
} from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Tooltip from 'components/controls/Tooltip';
import CustomCheckbox from 'components/controls/CustomCheckbox';
import Popup, { TextContent } from 'components/pages/plan/Popup';
import EditableBudgetCell from 'components/pages/plan/EditableBudgetCell';
import NumberWithArrow from 'components/NumberWithArrow';
import CellWrapper from 'components/pages/plan/CellWrapper';
import ManageColumnsButton from 'components/pages/plan/ManageColumnsButton';
import ChannelIcon from 'components/common/ChannelIcon';
import PlanVsActualTitleCell from 'components/pages/plan/PlanVsActualTitleCell';
import SubTable from 'components/pages/plan/PlanVsActualSubTable';
import { amountTypes, generalCampaignName } from 'stores/plan/logic/enums';
import { TIME_FRAME } from 'components/utils/timeframe';
import { viewStyleAccordingDataType, planVsActualColumns } from 'components/pages/plan/logic/enums';

import { getNickname as getChannelNickname } from 'components/utils/channels';
import {
  getIndicatorsWithProps,
  getIndicators,
} from 'components/utils/indicators';
import { formatBudget, formatNumber, sign } from 'components/utils/budget';
import {
  DoubleScroll,
  isMasterRegion,
  modArray,
  safeEvocation,
  sortRegions,
  compose,
} from 'components/utils/utils';

import style from 'styles/plan/planned-vs-actual-table.css';
import tablePopup from 'styles/plan/table-popup.css';
import reactTableStyle from 'react-table/react-table.css';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const COLUMNS_KEY = 'columns__plan-vs-actual';
const INDICATORS_KEY = 'indicators__plan-vs-actual';

const enhance = compose(
  inject((stores) => {
    const {
      planStore,
    } = stores;
    const {
      channelsCostsData,
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      updateCampaignAmount,
      updateCampaignProperties,
      startDate,
      endDate,
    } = planStore;
    return {
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      channelsCostsData,
      updateCampaignAmount,
      updateCampaignProperties,
      startDate,
      endDate,
    };
  }),
  observer
);

class PlannedVsActualTable extends Component {
  style = style;

  styles = [reactTableStyle, tablePopup];

  static getFromStorage = safeEvocation((key) => {
    const data = localStorage.getItem(key);
    return data && JSON.parse(data);
  });

  static commitToStorage = safeEvocation((...args) => {
    args.forEach(([key, data]) => {
      localStorage.setItem(key, JSON.stringify(data));
    });
  });

  static propTypes = {
    isCurrentMonth: PropTypes.bool.isRequired,
    showPopup: PropTypes.func.isRequired,
    viewStyle: PropTypes.number.isRequired,
    expanded: PropTypes.object,
    onExpand: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const colsFromStorage = PlannedVsActualTable.getFromStorage(COLUMNS_KEY);
    const metricsFromStorage = PlannedVsActualTable.getFromStorage(INDICATORS_KEY);
    const defaultColumns = Object.values(planVsActualColumns).map(({ value }) => value);
    const userIndicators = ['spending'];
    const { flags } = props;
    const defaultFunnelIndicators = ['spending'];
    this.state = {
      expandedCategories: this.getUniqueCategories(props.channelsCostsData),
      expandedRegions: this.props.regions,
      expandedChannels: this.getUniqueChannels(props.channelsCostsData),
      activeIndicators: metricsFromStorage || defaultFunnelIndicators.filter((indicatorName) => userIndicators.includes(indicatorName)),
      selectedIndicators: metricsFromStorage || defaultFunnelIndicators.filter((indicatorName) => userIndicators.includes(indicatorName)),
      activeColumns: colsFromStorage || defaultColumns,
      selectedColumns: colsFromStorage || defaultColumns,
      spendingSort: 'desc',
      isHeaderFixed: false,
      editingChannelKey: null,
    };
    this.handleScroll = throttle(this._handleScroll, 50);
  }

  componentDidMount() {
    this.element = document.querySelector('div > div > div.rt-table');
    this.header = document.querySelector('div.rt-thead.-header.planned-vs-actual-table__thead-3A8 > div');
    if (this.element) {
      this.doubleScroll = DoubleScroll(this.element);
    }
    if (this.header) {
      this.headerFirstColumn = this.header.querySelector(':first-child');
    }
    document.addEventListener('scroll', this.handleScroll, { passive: true });
    this.element.addEventListener('scroll', this.handleScrollHorizontal);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, channelsCostsData } = this.props;
    if (!isEqual(prevProps.startDate, startDate) || !isEqual(prevProps.endDate, endDate) || channelsCostsData.length !== prevProps.channelsCostsData.length) {
      this.setState({ expandedCategories: this.getUniqueCategories(channelsCostsData) });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
    this.element.removeEventListener('scroll', this.handleScrollHorizontal);
  }

  handleScrollHorizontal = ({ target }) => {
    this.header.scrollLeft = target.scrollLeft;
    this.headerScrollShouldBe = target.scrollLeft;
  };

  _handleScroll = () => {
    const isHeaderFixed = this.element.getBoundingClientRect().top <= 77;
    if (!this.header) {
      return;
    }
    if (isHeaderFixed) {
      this.header.classList.add(this.classes.theadFixed);
      this.headerFirstColumn.classList.add(this.classes.headerColFixed);
      if (this.headerScrollShouldBe >= 0) {
        this.header.scrollLeft = this.headerScrollShouldBe;
      }
    } else {
      this.header.classList.remove(this.classes.theadFixed);
      this.headerFirstColumn.classList.remove(this.classes.headerColFixed);
    }
  };

  getUniqueCategories = (channels) => Object.keys(groupBy(channels, (channel) => channel.category));

  getUniqueChannels = (channels) => Object.keys(groupBy(channels, (channel) => channel.channel));

  toggleTableSort = () => {
    const { spendingSort } = this.state;
    if (spendingSort === 'desc') {
      this.setState({ spendingSort: 'asc' });
    } else {
      this.setState({ spendingSort: 'desc' });
    }
  };

  saveSettings = () => {
    this.setState((prevState) => ({
      activeIndicators: prevState.selectedIndicators,
      activeColumns: prevState.selectedColumns,
    }), () => this.doubleScroll.refresh());
    const {
      selectedColumns, selectedIndicators,
    } = this.state;
    PlannedVsActualTable.commitToStorage([COLUMNS_KEY, selectedColumns], [INDICATORS_KEY, selectedIndicators]);
  };

  cancelSettings = () => {
    this.setState((prevState) => ({
      selectedIndicators: prevState.activeIndicators,
      selectedColumns: prevState.activeColumns,
    }));
  };

  openPopup = () => {
    this.popup.open();
  };

  closePopup = (saveSettings) => {
    if (saveSettings) {
      this.saveSettings();
    }
    this.popup.close();
  };

  // getIndicatorsOptions = () => {
  //   const { selectedIndicators } = this.state;
  //   const indicatorsProperties = getIndicatorsWithProps();
  //
  //   return getIndicators().map((indicator) => (
  //     <CustomCheckbox
  //       key={indicator}
  //       checked={selectedIndicators.includes(indicator)}
  //       onChange={() => this.changeIndicators(indicator)}
  //       className={tablePopup.locals.checkboxContainer}
  //       checkboxClassName={tablePopup.locals.checkbox}
  //       checkMarkClassName={tablePopup.locals.checkboxMark}
  //       childrenClassName={tablePopup.locals.checkboxLabel}
  //     >
  //       {indicator === 'spending'
  //         ? 'spending'
  //         : indicatorsProperties[indicator].nickname}
  //     </CustomCheckbox>
  //   ));
  // };

  // changeIndicators = (indicator) => {
  //   const selectedIndicators = this.state.selectedIndicators.slice();
  //
  //   const index = selectedIndicators.findIndex((item) => item === indicator);
  //   if (index !== -1) {
  //     selectedIndicators.splice(index, 1);
  //   } else {
  //     selectedIndicators.push(indicator);
  //   }
  //   this.setState({ selectedIndicators });
  // };

  getColumnsOptions = () => {
    const { selectedColumns } = this.state;

    return Object.values(planVsActualColumns).map((column) => (
      <CustomCheckbox
        key={column.value}
        checked={selectedColumns.includes(column.value)}
        onChange={() => this.changeColumns(column.value)}
        className={tablePopup.locals.checkboxContainer}
        checkboxClassName={tablePopup.locals.checkbox}
        checkMarkClassName={tablePopup.locals.checkboxMark}
        childrenClassName={tablePopup.locals.checkboxLabel}
      >
        {column.label}
      </CustomCheckbox>
    ));
  };

  changeColumns = (column) => {
    const selectedColumns = this.state.selectedColumns.slice();
    const index = selectedColumns.findIndex((item) => item === column);
    if (index !== -1) {
      selectedColumns.splice(index, 1);
    } else {
      const order = {
        [planVsActualColumns.planned.value]: 0,
        [planVsActualColumns.actual.value]: 1,
        [planVsActualColumns.planVsActual.value]: 2,
        [planVsActualColumns.pacingFor.value]: 3,
      };
      selectedColumns.push(column);
      selectedColumns.sort((a, b) => order[a] - order[b]);
    }
    this.setState({ selectedColumns });
  };

  toggleAllCategory = () => {
    const { channelsCostsData } = this.props;
    const { expandedCategories } = this.state;

    if (expandedCategories.length) {
      this.setState({ expandedCategories: [] });
    } else {
      this.setState({ expandedCategories: Object.keys(groupBy(channelsCostsData, (channel) => channel.category)) });
    }
  };

  toggleRows = (rowToExpland, type) => () => {
    const expandedArr = [...this.state[type]];
    modArray(expandedArr, rowToExpland);
    this.setState({ [type]: expandedArr });
  };

  // conditionalRound = (number, digitsAfterDecimalPoint = 2) => {
  //   // only process float numbers which does not equal to zero
  //   if (number === 0 || parseInt(number) === number) {
  //     return number;
  //   } else {
  //     return parseFloat(number.toFixed(digitsAfterDecimalPoint));
  //   }
  // };

  makeSort = (key) => (data) => orderBy(data, ['spending.planned', key], [this.state.spendingSort, 'asc']);

  addCategories = (channels, isTopLevelRow) => this.aggregateBy(
    channels,
    this.state.expandedCategories,
    'category',
    this.props.isMergedView ? this.addMergedChannels : this.makeSort('channel'),
    { isTopLevelRow },
    this.makeSort('category')
  );

  addRegions = (channels) => this.aggregateBy(
    channels,
    this.state.expandedRegions,
    'region',
    this.addCategories,
    { isRegionRow: true, isTopLevelRow: true },
    sortRegions
  );

  addMergedChannels = (channels) => this.aggregateBy(
    channels,
    this.state.expandedChannels,
    'channel',
    sortRegions,
    { isMergedChannel: true }
  );

  aggregateBy = (channels = [], substances, dataKey, processChunk, additionalData = {}, customSort) => {
    let aggregated = channels.reduce((result, channel) => {
      const index = result.findIndex((item) => item[dataKey] === channel[dataKey]);
      if (index >= 0) {
        ['spending'].forEach((indicator) => {
          result[index][indicator].planVsActual += channel[indicator].planVsActual;
          result[index][indicator].pacing += channel[indicator].pacing;
          result[index][indicator].pacingVsPlanned += channel[indicator].pacingVsPlanned;
          result[index][indicator].planned += channel[indicator].planned;
          result[index][indicator].actual += channel[indicator].actual;
        });
      } else {
        result.push({
          ...cloneDeep(channel),
          isPivotRow: true,
          children: [],
          channel: channel[dataKey],
          ...additionalData,
        });
      }
      return result;
    }, []);
    if (customSort) {
      aggregated = customSort(aggregated);
    }
    const groupedData = groupBy(channels, (channel) => channel[dataKey]);

    substances.forEach((type) => {
      const index = aggregated.findIndex((item) => item[dataKey] === type);
      if (index < 0) {
        return;
      }
      const chunk = groupedData[type];
      const processedChunk = processChunk(chunk);
      aggregated.splice(index + 1, 0, ...processedChunk);
    });
    return aggregated;
  };

  channelsEditable = () => this.props.viewStyle === viewStyleAccordingDataType.CHANNEL;

  getColumns = (data) => {
    const {
      isCurrentMonth,
      isMergedView,
      timeFrame,
      startDate,
      endDate,
    } = this.props;
    const {
      expandedCategories,
      expandedRegions,
      expandedChannels,
      activeIndicators,
      activeColumns,
      spendingSort,
    } = this.state;

    const pivotRows = data.filter((row) => row.isTopLevelRow);

    const notEdible = !this.channelsEditable();
    const getProps = () => ({ style: { fontWeight: notEdible && 'normal' } });

    const leftColumn = {
      id: 'channel',
      accessor: (item) => item,
      className: this.classes.fixed,
      Header: (
        <>
          <button
            type="button"
            onClick={this.toggleAllCategory}
            className={classnames(
              this.classes.collapseAllButton,
              expandedCategories.length && this.classes.collapsed
            )}
          />
          channel
        </>
      ),
      Footer: 'total',
      Cell: (cellData) => {
        const { value } = cellData;
        if (!value.isPivotRow) {
          cellData.expander = true;
          return null;
        }
        if (value.isMergedChannel) {
          return (
            <PlanVsActualTitleCell
              classes={this.classes}
              items={expandedChannels}
              itemsType="expandedChannels"
              toggleRows={this.toggleRows}
              value={value.channel}
              titleRender={() => (
                <div className={this.classes.mergedChannelRow}>
                  <ChannelIcon
                    className={this.classes.channelIcon}
                    channel={value.channel}
                  />
                  <div>{getChannelNickname(value.channel)}</div>
                </div>
              )}
            />
          );
        }
        if (value.isRegionRow) {
          return (
            <PlanVsActualTitleCell
              classes={this.classes}
              items={expandedRegions}
              itemsType="expandedRegions"
              toggleRows={this.toggleRows}
              value={value.region}
            />
          );
        }
        return (
          <PlanVsActualTitleCell
            classes={this.classes}
            items={expandedCategories}
            itemsType="expandedCategories"
            toggleRows={this.toggleRows}
            value={value.category}
          />
        );
      },
      Expander: (cellData) => {
        const { value, original } = cellData;
        return value.isPivotRow ? value.category : (
          <div className={this.classes.withIcon}>
            <i
              className={classnames(
                this.classes.icon,
                this.classes.iconChannel,
                this.classes.iconUp,
                {
                  [this.classes.iconDown]: cellData.isExpanded,
                }
              )}
              data-icon="plan:monthNavigation"
            />
            {!isMergedView && (
              <ChannelIcon
                className={this.classes.channelIcon}
                channel={value.channel}
              />
            )}
            <div className={this.classes.channelName}>
              <EllipsisTooltip
                text={isMergedView ? value.region : getChannelNickname(value.channel)}
                place="top"
                TooltipProps={{
                  className: this.classes.channelNicknameTip,
                }}
              />
            </div>
            {value.isAutomatic && !isMergedView && (
              <div className={this.classes.channelAuto}>Auto</div>
            )}
            {cellData.isExpanded && !value.isAutomatic && (
              <div
                className={this.classes.addCampaign}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.addCampaign(original);
                }}
              >
                Add
              </div>
            )}
          </div>
        );
      },
      minWidth: 300,
      width: 300,
    };

    const columnsData = [{
      Header: '',
      columns: [leftColumn, {
        Header: '',
        width: 0,
        filterable: false,
        resizable: false,
        sortable: false,
        expander: true,
        show: false,
      }],
      fixed: 'left',
    }].concat(activeIndicators.map((indicator) => {
      if (indicator === 'spending') {
        return {
          Header: (
            <div className={this.classes.group}>
              <span className={this.classes.arrow} />
              spending
            </div>
          ),
          columns: activeColumns.map((column, index) => {
            let columnProps;
            switch (column) {
              case planVsActualColumns.planned.value:
                columnProps = {
                  id: 'spendingPlanned',
                  accessor: (item) => item[indicator].planned,
                  Header: (
                    <button
                      type="button"
                      onClick={this.toggleTableSort}
                      className={classnames(this.classes.sortButton, spendingSort === 'asc' && this.classes.ascending)}
                    >
                      planned budget
                    </button>
                  ),
                  Footer: formatBudget(sumBy(pivotRows, (item) => item[indicator].planned)),
                  Cell: ({ original }) => {
                    const { channel: key, region } = original;
                    const { planned } = original[indicator];
                    return original.isPivotRow ? formatBudget(planned) : (
                      <EditableBudgetCell
                        onEdit={() => this.setEditingChannel(key)}
                        onEditEnd={() => this.onEditEnd(key)}
                        value={planned}
                        formatter={formatBudget}
                        dontUpdateCell
                        save={async (budget) => {
                          const allCampaignsWithoutGeneral = original.children.filter((campaign) => campaign.campaign !== generalCampaignName);
                          const allCampaignsBudgetsForGivenChannel = sumBy(allCampaignsWithoutGeneral, (campaign) => campaign.budgetAmount);
                          const generalCampaignBudget = budget - allCampaignsBudgetsForGivenChannel;
                          if (generalCampaignBudget >= 0) {
                            this.props.updateCampaignAmount({
                              campaignName: generalCampaignName,
                              channel: key,
                              startDate,
                              endDate,
                              amount: generalCampaignBudget,
                              amountType: amountTypes.budget,
                              region,
                            });
                            this.props.updateCampaignProperties({
                              name: generalCampaignName,
                              channel: key,
                              sources: [key],
                              startDate,
                              endDate,
                              status: 'In Progress',
                              id: `${generalCampaignName}_${key}`,
                              region,
                            });
                          }
                          await Promise.all([
                            this.props.sendUpdateCampaignsAmountRequestToServer({ startDate, endDate }),
                            this.props.sendUpdateCampaignsPropertiesRequestToServer({ startDate, endDate }),
                          ]);
                        }}
                        disabled={timeFrame !== TIME_FRAME.MONTH}
                      />
                    );
                  },
                  getProps,
                };
                break;
              case planVsActualColumns.actual.value:
                columnProps = {
                  id: 'spendingActual',
                  accessor: (item) => item,
                  getProps,
                  Header: 'actual cost',
                  Footer: formatBudget(sumBy(pivotRows, (item) => item[indicator].actual)),
                  Cell: ({ value, original }) => {
                    const { channel: key, isAutomatic, region } = original;
                    const { actual, isEstimated } = value[indicator];
                    return value.isPivotRow ? formatBudget(actual) : (
                      <CellWrapper showToolTip={isEstimated && isCurrentMonth}>
                        <EditableBudgetCell
                          onEdit={() => this.setEditingChannel(key)}
                          onEditEnd={() => this.onEditEnd(key)}
                          value={actual}
                          formatter={formatBudget}
                          dontUpdateCell
                          save={async (actualSpent) => {
                            const allCampaignsWithoutGeneral = original.children.filter((campaign) => campaign.campaign !== generalCampaignName);
                            const allCampaignsCostsForGivenChannel = sumBy(allCampaignsWithoutGeneral, (campaign) => campaign.costAmount);
                            const generalCampaignActualCost = actualSpent - allCampaignsCostsForGivenChannel;
                            if (generalCampaignActualCost >= 0) {
                              this.props.updateCampaignAmount({
                                campaignName: generalCampaignName,
                                channel: value.channel,
                                startDate,
                                endDate,
                                amount: generalCampaignActualCost,
                                amountType: amountTypes.actualSpent,
                                region,
                              });
                              this.props.updateCampaignProperties({
                                name: generalCampaignName,
                                channel: value.channel,
                                sources: [value.channel],
                                startDate,
                                endDate,
                                status: 'In Progress',
                                id: `${generalCampaignName}_${value.channel}`,
                                region,
                              });
                            }
                            await Promise.all([
                              this.props.sendUpdateCampaignsAmountRequestToServer({ startDate, endDate }),
                              this.props.sendUpdateCampaignsPropertiesRequestToServer({ startDate, endDate }),
                            ]);
                          }}
                          disabled={isAutomatic || timeFrame !== TIME_FRAME.MONTH}
                        />
                      </CellWrapper>
                    );
                  },
                };
                break;
              case planVsActualColumns.planVsActual.value:
                columnProps = {
                  id: 'spendingPlanVsActual',
                  accessor: (item) => item,
                  getProps,
                  Header: 'planned - actual',
                  Footer: formatBudget(
                    sumBy(pivotRows, (item) => item[indicator].planVsActual),
                    true,
                    false,
                    true
                  ),
                  Cell: ({ value }) => formatBudget(value[indicator].planVsActual, true, false, true),
                };
                break;
              case planVsActualColumns.pacingFor.value:
                columnProps = {
                  id: 'spendingPacingFor',
                  accessor: (item) => item,
                  getProps,
                  Header: (
                    <Tooltip
                      tip={`Assuming you’ll keep investing at the same pace, that’s what you can expect to invest at the end of the ${timeFrame}`}
                      id="pacing-for-tooltip"
                    >
                      pacing for
                    </Tooltip>
                  ),
                  Footer: () => {
                    if (!isCurrentMonth) {
                      return '-';
                    }
                    const sumPlanned = sumBy(pivotRows, (item) => item[indicator].planned);
                    const sumPacing = sumBy(pivotRows, (item) => item[indicator].pacing);
                    const diff = sumPacing !== 0 ? sumPacing - sumPlanned : 0;

                    return diff === 0 ? formatBudget(sumPacing) : (
                      <div style={{ display: 'flex' }}>
                        {formatBudget(sumPacing)}
                        <div style={{ marginLeft: 10 }}>
                          <NumberWithArrow
                            stat={formatBudget(diff, true, false, true)}
                            isNegative={diff > 0}
                            arrowStyle={{ display: 'none' }}
                            statStyle={{ alignSelf: 'center', fontWeight: '500', paddingTop: 1 }}
                          />
                        </div>
                      </div>
                    );
                  },
                  Cell: ({ value }) => {
                    if (!isCurrentMonth) {
                      return '-';
                    }
                    const { pacing } = value[indicator];
                    const diff = value[indicator].pacingVsPlanned;

                    return diff === 0 ? formatBudget(pacing) : (
                      <div style={{ display: 'flex' }}>
                        {formatBudget(pacing)}
                        <div style={{ marginLeft: 10 }}>
                          <NumberWithArrow
                            stat={formatBudget(diff, true, false, true)}
                            isNegative={diff > 0}
                            arrowStyle={{ display: 'none' }}
                            statStyle={{ alignSelf: 'center', fontWeight: '500', paddingTop: 1 }}
                          />
                        </div>
                      </div>
                    );
                  },
                };
                break;
              default:
                columnProps = null;
                break;
            }
            if (columnProps && index === 0) {
              columnProps.className = this.classes.separator;
              columnProps.headerClassName = this.classes.separator;
            }
            return columnProps;
          }),
        };
      }
      // else {
      // return {
      //   Header: (
      //     <div className={this.classes.group}>
      //       <span className={this.classes.arrow} />
      //       {getIndicatorNickname(indicator)}
      //     </div>
      //   ),
      //   columns: activeColumns.map((column, index) => {
      //     let columnProps;
      //     switch (column) {
      //       case planVsActualColumns.planned.value:
      //         columnProps = {
      //           id: `${indicator}Planned`,
      //           accessor: (item) => item,
      //           getProps,
      //           Header: (
      //             <Tooltip
      //               tip={'what\'s your expectation?'}
      //               id={`planned-${indicator}-tooltip`}
      //             >
      //               {`planned ${getIndicatorNickname(indicator)}`}
      //             </Tooltip>
      //           ),
      //           Footer: formatNumber(this.conditionalRound(sumBy(pivotRows, (item) => item[indicator].planned))),
      //           Cell: ({ value }) => (value.isPivotRow
      //             ? formatNumber(value[indicator].planned)
      //             : (
      //               <EditableBudgetCell
      //                 value={value[indicator].planned}
      //                 formatter={formatNumber}
      //                 save={(budget) => console.log('')}
      //                 disabled={!this.channelsEditable()}
      //                 isPlainNumber
      //               />
      //             )),
      //         };
      //         break;
      //       case planVsActualColumns.actual.value:
      //         columnProps = {
      //           id: `${indicator}Actual`,
      //           accessor: (item) => item,
      //           getProps,
      //           Header: `actual ${getIndicatorNickname(indicator)}`,
      //           Footer: formatNumber(this.conditionalRound(sumBy(pivotRows, (item) => item[indicator].actual))),
      //           Cell: ({ value }) => formatNumber(value[indicator].actual, true),
      //         };
      //         break;
      //       case planVsActualColumns.planVsActual.value:
      //         columnProps = {
      //           id: `${indicator}PlanVsActual`,
      //           accessor: (item) => item,
      //           getProps,
      //           Header: 'actual - planned',
      //           Footer: formatNumber(this.conditionalRound(sumBy(pivotRows, (item) => item[indicator].actual - item[indicator].planned))),
      //           Cell: ({ value }) => {
      //             const formatted = formatNumber(value[indicator].actual - value[indicator].planned, true);
      //             return `${sign(formatted)}${formatted}`;
      //           },
      //         };
      //         break;
      //       case planVsActualColumns.pacingFor.value:
      //         columnProps = {
      //           id: `${indicator}PacingFor`,
      //           accessor: (item) => item,
      //           getProps,
      //           Header: (
      //             <Tooltip
      //               tip={`Assuming you’ll keep investing at the same pace, that’s what you can expect to invest at the end of the ${timeFrame}`}
      //               id="pacing-for-tooltip"
      //             >
      //               pacing for
      //             </Tooltip>
      //           ),
      //           Footer: () => {
      //             if (!isCurrentMonth) {
      //               return '-';
      //             }
      //             const pacing = sumBy(pivotRows, (item) => item[indicator].pacing);
      //             const planned = sumBy(pivotRows, (item) => item[indicator].planned);
      //             const diff = pacing !== 0 ? pacing - planned : 0;
      //
      //             return diff === 0 ? extrapolateValue(pacing) : (
      //               <div style={{ display: 'flex' }}>
      //                 {formatNumber(pacing, true)}
      //                 <div style={{ marginLeft: 10 }}>
      //                   <NumberWithArrow
      //                     stat={diff > 0 ? `+${formatNumber(diff, true)}` : formatNumber(diff, true)}
      //                     isNegative={diff <= 0}
      //                     arrowStyle={{ display: 'none' }}
      //                     statStyle={{ alignSelf: 'center', fontWeight: '500', paddingTop: 1 }}
      //                   />
      //                 </div>
      //               </div>
      //             );
      //           },
      //           Cell: ({ value }) => {
      //             if (!isCurrentMonth) {
      //               return '-';
      //             }
      //             const { pacing } = value[indicator];
      //             const diff = pacing !== 0 ? pacing - value[indicator].planned : 0;
      //
      //             return diff === 0 ? extrapolateValue(pacing) : (
      //               <div style={{ display: 'flex' }}>
      //                 {formatNumber(pacing, true)}
      //                 <div style={{ marginLeft: 10 }}>
      //                   <NumberWithArrow
      //                     stat={diff > 0 ? `+${formatNumber(diff, true)}` : formatNumber(diff, true)}
      //                     isNegative={diff <= 0}
      //                     arrowStyle={{ display: 'none' }}
      //                     statStyle={{ alignSelf: 'center', fontWeight: '500', paddingTop: 1 }}
      //                   />
      //                 </div>
      //               </div>
      //             );
      //           },
      //         };
      //         break;
      //       default:
      //         columnProps = null;
      //         break;
      //     }
      //     if (columnProps && index === 0) {
      //       columnProps.className = this.classes.separator;
      //       columnProps.headerClassName = this.classes.separator;
      //     }
      //     return columnProps;
      //   }),
      // };
      // }
    }));
    return columnsData;
  };

  getDecorators = () => ({
    getTheadGroupProps: () => ({
      className: this.classes.theadGroup,
    }),
    getTheadGroupThProps: () => ({
      className: classnames(this.classes.theadGroupTh, this.classes.separator),
    }),
    getTheadProps: () => ({
      className: this.classes.thead,
    }),
    getTheadTrProps: () => ({
      className: classnames(this.classes.theadRowContainer, {
        [this.classes.theadFixed]: this.state.isHeaderFixed,
      }),
    }),
    getTheadThProps: () => ({
      className: this.classes.theadTh,
    }),
    getTbodyProps: () => ({
      className: this.classes.tbody,
    }),
    getTrGroupProps: (state, rowInfo) => ({
      className: classnames(
        this.classes.trGroup,
        rowInfo.original.isPivotRow && this.classes.pivotRow
      ),
    }),
    getTrProps: (state, rowInfo) => ({}),
    getTdProps: (state, rowInfo) => ({
      className: classnames(
        this.classes.td,
        rowInfo.original.isPivotRow && this.classes.pivotRow,
        rowInfo.original.isTopLevelRow && this.classes.regionRow
      ),
    }),
    getTfootProps: () => ({
      className: this.classes.tfoot,
    }),
    getTfootTdProps: () => ({
      className: this.classes.tfootTd,
    }),
  });

  getSubTableDecorators = () => ({
    getTrGroupProps: (state, rowInfo) => ({
      className: classnames(
        this.classes.trGroup
      ),
    }),
    getTdProps: (state, rowInfo) => ({
      className: classnames(
        this.classes.td
      ),
    }),
  });

  setEditingChannel = (key) => this.setState({ editingChannelKey: key });

  onEditEnd = (currentChannelKey) => {
    if (this.state.editingChannelKey === currentChannelKey) {
      this.setEditingChannel(null);
    }
  };

  render() {
    const { activeColumns, activeIndicators } = this.state;
    const {
      showPopup, viewStyle, expanded, region, startDate, endDate, timeFrame,
      isMergedView, onExpand, isCurrentMonth, channelsCostsData,
    } = this.props;

    const subColumns = activeIndicators.reduce((acc, i) => {
      const columnsData = activeColumns.map((column) => ({ column, indicator: i }));
      acc = [...acc, ...columnsData];
      return acc;
    }, []);

    const data = (isMasterRegion(region) && !isMergedView)
      ? this.addRegions(channelsCostsData)
      : this.addCategories(channelsCostsData, true);

    const columns = this.getColumns(data);
    return (
      <div className={this.classes.container}>
        <ManageColumnsButton onClick={this.openPopup} />
        <ReactTableFixedColumns
          data={data}
          columns={columns}
          column={{
            ...ReactTableDefaults.column,
            minWidth: 160,
          }}
          className={this.classes.table}
          pageSize={data.length}
          sortable={false}
          showPagination={false}
          showFooterTop
          onExpandedChange={onExpand}
          expanded={expanded}
          {...this.getDecorators()}
          SubComponent={(row) => (
            <SubTable
              columns={subColumns}
              activeColumns={activeColumns}
              row={row}
              classes={this.classes}
              getDecorators={this.getSubTableDecorators}
              showPopup={showPopup}
              viewStyle={viewStyle}
              activeIndicators={activeIndicators}
              isCurrentMonth={isCurrentMonth}
              editingChannelKey={this.state.editingChannelKey}
              setEditingChannel={this.setEditingChannel}
              onEditEnd={this.onEditEnd}
              startDate={startDate}
              endDate={endDate}
              timeFrame={timeFrame}
            />
          )}
        />
        <Popup
          ref={(el) => this.popup = el}
          title="Manage columns and metrics"
          className={tablePopup.locals.popup}
          onClose={() => this.cancelSettings}
          primaryButton={{
            text: 'Save',
            onClick: () => this.closePopup(true),
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: () => this.closePopup(false),
          }}
        >
          <TextContent>
            <div className={tablePopup.locals.popupContentContainer}>
              <div className={tablePopup.locals.popupContentColumn}>
                <div className={tablePopup.locals.popupContentColumnTitle}>
                  Columns
                </div>
                {this.getColumnsOptions()}
              </div>
            </div>
          </TextContent>
        </Popup>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(PlannedVsActualTable));
