import moment from 'moment';
import { cloneDeep, upperFirst } from 'lodash';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import timeFrameModule from 'modules/timeframe';

import { mergeAllFunnelsTableData } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { mapChartIndicator } from 'components/utils/indicators';
import { attributionModelsItems } from 'attribution/models';
import { amountOfFirstFunnelsToRemove } from 'components/widgets/whatIf/enums';

export function getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths }) {
  const timeframe = {
    rollingValue: `last_${amountOfSampleTimeframeMonths}_months_months`,
    value: 'rolling',
  };

  return {
    filters: [],
    timeFrame: {
      ...timeFrameModule.getTimeframeParams({
        ...timeframe, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
      }),
    },
    attributionModel: attributionModelsItems[0],
    shouldUsePredefinedFilters: true,
  };
}

export function getParsedMockBaseData({ baselineConfig, channelConfig }) {
  const parsedData = {};
  const kpiStages = attributionStore.metricsOptions.slice(amountOfFirstFunnelsToRemove);
  for (const stage of kpiStages) {
    parsedData[stage.value] = {
      conversionRate: baselineConfig?.conversionRate,
      velocity: baselineConfig.velocity,
      avgDealSize: baselineConfig.ARPA,
    };
  }

  const paidChannels = userStore.userPaidChannels;
  for (const channel of paidChannels) {
    parsedData[channel] = {
      conversionRate: channelConfig.conversionRate,
      velocity: channelConfig.velocity,
      avgDealSize: channelConfig.ARPA,
    };
  }

  return parsedData;
}

export function getParsedAccountBaseData({ data }) {
  const parsedData = {};
  const kpiStages = attributionStore.metricsOptions.slice(amountOfFirstFunnelsToRemove);
  for (const stage of kpiStages) {
    parsedData[stage.value] = {
      conversionRate: data?.[`funnel2To${upperFirst(stage.originalFunnel)}ConversionRate`]?.total?.value,
      velocity: data?.[`funnel2To${upperFirst(stage.originalFunnel)}Velocity`]?.total?.value,
      avgDealSize: data?.ARPA?.total?.value,
    };
  }
  return parsedData;
}

export function getParsedChannelBaseData({ data, kpiFocus }) {
  const parsedData = {};
  const paidChannels = userStore.userPaidChannels;
  const currentStage = mapChartIndicator(kpiFocus);
  const selectedMetricKey = `funnel2To${upperFirst(currentStage)}`;
  const mergedSegmentsAnalysisData = mergeAllFunnelsTableData({ tableDataByFunnel: data, selectedStageKey: currentStage });

  for (const channel of paidChannels) {
    const channelConfig = mergedSegmentsAnalysisData.find((item) => item.firstSegment === channel);
    if (channelConfig) {
      parsedData[channel] = {
        conversionRate: channelConfig[`${selectedMetricKey}ConversionRate`] || 0,
        velocity: channelConfig[`${selectedMetricKey}Velocity`] || 0,
        avgDealSize: channelConfig.ARPA || 0,
      };
    }
  }
  return parsedData;
}

export function getSelectedMetricsForRequest() {
  const kpiStages = attributionStore.metricsOptions.slice(amountOfFirstFunnelsToRemove);
  const selectedMetrics = new Set(['ARPA']);
  for (const stage of kpiStages) {
    const selectedMetricKey = `funnel2To${upperFirst(stage.originalFunnel)}`;
    selectedMetrics.add(`${selectedMetricKey}ConversionRate`);
    selectedMetrics.add(`${selectedMetricKey}Velocity`);
  }
  return Array.from(selectedMetrics);
}

export function getPreviousMonthShortLabel({ chartDataFirstMonth }) {
  const currentMonth = moment().format('MMM');
  if (currentMonth === chartDataFirstMonth) {
    return currentMonth;
  }
  return moment().subtract(1, 'month').format('MMM');
}

export function getParsedScenarioChartData({ data, timeFrameLabel }) {
  let isFutureMonth = timeFrameLabel.includes('next');
  const currentMonthLabel = getPreviousMonthShortLabel({ chartDataFirstMonth: data[0]?.name });
  const updatedChartData = cloneDeep(data);

  for (const chartItem of updatedChartData) {
    if (chartItem.name === currentMonthLabel && !isFutureMonth) {
      chartItem.predictedBaseline = chartItem.baseline;
      isFutureMonth = true;
      continue;
    }
    if (isFutureMonth) {
      chartItem.predictedBaseline = chartItem.baseline;
      delete chartItem.baseline;
      continue;
    }
    for (const chartItemKey in chartItem) {
      if (chartItemKey !== 'name' && chartItemKey !== 'baseline') {
        delete chartItem[chartItemKey];
      }
    }
  }
  return updatedChartData;
}
