export const tabsPaths = {
  aiPrompts: '/insights/ai-prompts',
  campaigns: '/analyze/campaigns',
  whatIf: '/analyze/what-if',
};

export const tabsToFeatureFlags = {
  [tabsPaths.aiPrompts]: 'aiPromptTab',
  [tabsPaths.campaigns]: 'headerCampaignsTab',
  [tabsPaths.whatIf]: 'whatIfAnalyzeTab',
};
