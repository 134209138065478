import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import { Button } from '@infinigrow/libs';

import CustomCheckbox from 'components/controls/CustomCheckbox';
import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';
import Textfield from 'components/controls/Textfield';

import { TIMEFRAME_VALUES, includeTimeframeCheckboxTooltips } from 'components/utils/timeframe';

import style from 'styles/controls/rollingTimeframeSelect.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';

const styles = style.locals;
const checkboxStyles = checkboxStyle.locals;

export default function RollingTimeframeSelect({
  className,
  onApply,
  onCancel,
  timeFrame,
  timeframeTypeOptions,
  maxMonths = 24,
  maxDays = 365,
  shouldIncludeThisMonthData = false,
}) {
  const [timeframeAmount, setTimeframeAmount] = useState();
  const [timeframeObject, setTimeframeObject] = useState(timeFrame);
  const [isIncludeToday, setIsIncludeToday] = useState(shouldIncludeThisMonthData || !isNil(timeFrame.excludeToday) ? !timeFrame.excludeToday : undefined);
  const [isIncludeThisMonth, setIsIncludeThisMonth] = useState(shouldIncludeThisMonthData || !isNil(timeFrame.excludeThisMonthData) ? !timeFrame.excludeThisMonthData : undefined);
  const [isShowIncludeTodayCheckbox, setIsShowIncludeTodayCheckbox] = useState(false);
  const [reachedMaxAmountErrorMessage, setReachedMaxAmountErrorMessage] = useState('');
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);

  useEffect(() => {
    style.use();
    checkboxStyle.use();

    return () => {
      style.unuse();
      checkboxStyle.unuse();
    };
  }, []);

  useEffect(() => {
    if (timeFrame.rollingValue) {
      const [, amountOfUnit, unit, value] = timeFrame.rollingValue.split('_');
      setTimeframeObject({ ...timeFrame, value });
      setTimeframeAmount(amountOfUnit);
      if (unit && amountOfUnit) {
        setIsApplyDisabled(false);
      }
      if (value !== 'month') {
        setIsShowIncludeTodayCheckbox(true);
      }
    } else {
      setTimeframeObject({ value: 'day', type: 'day' });
      setIsShowIncludeTodayCheckbox(true);
    }
  }, [timeFrame]);

  function validateTimeframeParameters({ type, amount }) {
    if (isNil(type) || isNil(amount)) {
      setIsApplyDisabled(true);
      return;
    }
    if (type === 'month' && amount > maxMonths) {
      setReachedMaxAmountErrorMessage(`The maximum months are ${maxMonths}`);
      setIsApplyDisabled(true);
    } else if (type === 'day' && amount > maxDays) {
      setReachedMaxAmountErrorMessage(`The maximum days are ${maxDays}`);
      setIsApplyDisabled(true);
    } else if (reachedMaxAmountErrorMessage !== '') {
      setReachedMaxAmountErrorMessage('');
      setIsApplyDisabled(false);
    } else {
      setIsApplyDisabled(false);
    }
  }

  function updateTimeframeAmount({ newAmount }) {
    const isOnlyNumbers = /^\d+$/.test(newAmount);
    if (!isOnlyNumbers) {
      return;
    }

    validateTimeframeParameters({ type: timeframeObject.type, amount: newAmount });

    setTimeframeAmount(newAmount);
  }

  function updateTimeframeObject({ newTimeframe }) {
    validateTimeframeParameters({ type: newTimeframe.type, amount: timeframeAmount });

    setIsShowIncludeTodayCheckbox(newTimeframe.value !== 'month');

    setTimeframeObject(newTimeframe);
  }

  function setNewRollingDate() {
    const newRollingValue = `last_${timeframeAmount}_${timeframeObject.type}_${timeframeObject.value}`;

    onApply({
      ...timeframeObject,
      rollingValue: newRollingValue,
      excludeToday: isShowIncludeTodayCheckbox ? !isIncludeToday : undefined,
      excludeThisMonthData: isShowIncludeTodayCheckbox ? undefined : !isIncludeThisMonth,
      value: TIMEFRAME_VALUES.ROLLING,
    });
  }

  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.row}>
        Last
        <Textfield
          className={styles.textField}
          value={timeframeAmount}
          onChange={(newAmount) => updateTimeframeAmount({ newAmount: newAmount.target.value })}
          placeHolder="e.g. 7"
          isShowError={reachedMaxAmountErrorMessage !== ''}
        />
        <Dropdown
          options={timeframeTypeOptions}
          selectedKey={timeframeObject.value}
          onChange={(newTimeframe) => updateTimeframeObject({ newTimeframe })}
          className={styles.dropdown}
          optionsTooltipProps={{ place: 'bottom' }}
        />
      </div>
      <div className={styles.errorMessage} hidden={reachedMaxAmountErrorMessage === ''}>
        {reachedMaxAmountErrorMessage}
      </div>
      <div className={classnames(styles.row, styles.checkboxContainer)}>
        <CustomCheckbox
          key="include timeframe data"
          checked={isShowIncludeTodayCheckbox ? isIncludeToday : isIncludeThisMonth}
          onChange={() => (isShowIncludeTodayCheckbox ? setIsIncludeToday((prev) => !prev) : setIsIncludeThisMonth((prev) => !prev))}
          className={checkboxStyles.checkboxContainer}
          checkboxClassName={checkboxStyles.checkbox}
          checkMarkClassName={checkboxStyles.checkboxMark}
          childrenClassName={checkboxStyles.checkboxLabel}
          disabled={shouldIncludeThisMonthData}
        >
          {isShowIncludeTodayCheckbox ? 'Including today' : 'Add this month'}
        </CustomCheckbox>
        <InfoMarker tooltipText={`${includeTimeframeCheckboxTooltips[timeframeObject.value]}`} />
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.cancelButton}
          containerClassName={styles.cancelButtonText}
          type="secondaryWhite"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="primaryBlue"
          className={styles.applyButton}
          containerClassName={styles.applyButtonText}
          onClick={() => setNewRollingDate()}
          disabled={isApplyDisabled}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}
