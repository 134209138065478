import { get } from 'lodash';

export function getFunnelsFromUMP(userMonthPlan, withFilter = true, commbined = false) {
  const FUNNEL_MAPPING_PATH = 'mapping.funnelMapping';
  const { salesforceapi, hubspotapi, marketoapi } = userMonthPlan;
  const isLostOrBlogSubscriberFunnel = (funnel) => funnel === 'lost' || funnel === 'blogSubscribers';
  let salesforceFunnels = get(salesforceapi, FUNNEL_MAPPING_PATH) ? Object.keys(get(salesforceapi, FUNNEL_MAPPING_PATH, [])) : [];
  let hubspotFunnels = get(hubspotapi, FUNNEL_MAPPING_PATH) ? Object.keys(get(hubspotapi, FUNNEL_MAPPING_PATH, [])) : [];
  let marketoFunnels = get(marketoapi, FUNNEL_MAPPING_PATH) ? Object.keys(get(marketoapi, FUNNEL_MAPPING_PATH, [])) : [];
  if (withFilter) {
    salesforceFunnels = salesforceFunnels.filter((funnel) => !isLostOrBlogSubscriberFunnel(funnel));
    hubspotFunnels = hubspotFunnels.filter((funnel) => !isLostOrBlogSubscriberFunnel(funnel));
    marketoFunnels = marketoFunnels.filter((funnel) => !isLostOrBlogSubscriberFunnel(funnel));
  }
  if (commbined) {
    const commbinedFunnel = salesforceFunnels.filter((funnel) => !hubspotFunnels.includes(funnel));
    return [...new Set([...commbinedFunnel])].sort();
  }
  return [...new Set([...salesforceFunnels, ...hubspotFunnels, ...marketoFunnels])].sort();
}

export function getGroupedUserMetrics({ userMetrics = [] } = {}) {
  const groupLabels = {};
  const groups = {};

  for (const metricData of userMetrics) {
    const {
      relevantFor, title, metricName, isRelevantForTarget, nickname, enabled, metricType,
    } = metricData;

    const optionItem = {
      label: nickname,
      value: metricName,
      enabled,
      metricType,
      relevantFor,
    };

    const relevantForValue = relevantFor || metricName;
    if (!groups[relevantForValue]) {
      groups[relevantForValue] = [];
    }

    groups[relevantForValue].push(optionItem);

    if (isRelevantForTarget || relevantForValue === metricName) {
      groupLabels[relevantForValue] = title;
    }
  }

  const groupedMetrics = Object.entries(groups).map(([groupName, options]) => {
    const label = groupLabels[groupName] || groupName;
    return {
      label,
      groupName,
      options,
    };
  });
  return groupedMetrics;
}
