import React from 'react';
import Popup from 'components/pages/plan/Popup';
import attributionStyle from 'styles/analyze/analyze-attribution-table.css';
import Component from 'components/Component';
import style from 'styles/analyze/analyze.css';
import CustomCheckbox from 'components/controls/CustomCheckbox';
import { modArray } from 'components/utils/utils';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import tablePopup from 'styles/plan/table-popup.css';
import SearchInput from 'components/controls/SearchInput';
import CheckListWithDragAndDrop from 'components/controls/CheckListWithDragAndDrop';
import { handleReorderAndUpdatedColumnsOptions } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTablePopup';
import { defaultFirstAndSecondColumns } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';

class AttributionTablePopup extends Component {
  style = style;

  styles = [attributionStyle, tablePopup];

  static propTypes = {
    setPopupRef: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    closePopup: PropTypes.func.isRequired,
    selectedStageKey: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: props.selectedColumnsInitial,
      isAllOptionsSelected: this.checkIfAllOptionsSelected(props.options, props.selectedColumnsInitial),
      columnsOptions: props.options,
      isColumnsReordered: false,
    };
    this.popupRef = React.createRef();
    this.refSearchInput = React.createRef();
  }

  checkIfAllOptionsSelected = (options, selectedOptions) => options.length === selectedOptions.length;

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.selectedStageKey, this.props.selectedStageKey)) {
      this.setState({
        selectedColumns: [...this.props.selectedColumnsInitial],
      });
    }

    if (!isEqual(prevProps.options, this.props.options)) {
      this.setState({
        columnsOptions: this.props.options,
      });
    }
  }

  cancelSettings = (callback = () => true) => {
    const isAllOptionsSelected = this.checkIfAllOptionsSelected(this.props.options, this.props.selectedColumnsInitial);
    this.refSearchInput.current.handleSearchQueryClear();
    this.setState({
      selectedColumns: this.props.selectedColumnsInitial, isAllOptionsSelected, isColumnsReordered: false, columnsOptions: this.props.options,
    }, callback);
  };

  changeIndicators = (indicator) => {
    const { selectedColumns } = this.state;
    const updatedSelectedColumn = [...selectedColumns];
    modArray(updatedSelectedColumn, indicator);
    const isAllOptionsSelected = this.checkIfAllOptionsSelected(this.props.options, updatedSelectedColumn);
    this.setState({ selectedColumns: updatedSelectedColumn, isAllOptionsSelected });
  };

  selectAllOptionsHandler = () => {
    const { isAllOptionsSelected } = this.state;
    const updatedIsAllOptionsSelected = !isAllOptionsSelected;
    const selectedColumns = [...this.state.selectedColumns];
    let columnsFieldsOptions = [...this.props.options];
    const searchQuery = this.refSearchInput.current.state.search;
    if (searchQuery) {
      columnsFieldsOptions = columnsFieldsOptions.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    if (updatedIsAllOptionsSelected) {
      for (const option of columnsFieldsOptions) {
        const isSelectedOption = selectedColumns.includes(option.id);
        if (!isSelectedOption) {
          modArray(selectedColumns, option.id);
        }
      }
    } else {
      for (const option of columnsFieldsOptions) {
        const isSelectedOption = selectedColumns.includes(option.id);
        if (isSelectedOption) {
          modArray(selectedColumns, option.id);
        }
      }
    }
    const updatedSelectedColumns = new Set([...defaultFirstAndSecondColumns, ...selectedColumns]);
    this.setState({ selectedColumns: Array.from(updatedSelectedColumns), isAllOptionsSelected: updatedIsAllOptionsSelected });
  };

  handleSearchOptionsQueryChange = (searchQuery) => {
    const updatedColumnsOptions = this.props.options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
    const filterSelectedColumns = updatedColumnsOptions.map((option) => option.id).filter((item) => this.state.selectedColumns.includes(item));
    const isAllOptionsSelected = this.checkIfAllOptionsSelected(updatedColumnsOptions, filterSelectedColumns);
    this.setState({ columnsOptions: updatedColumnsOptions, isAllOptionsSelected });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onOutsideClick, true);
    document.addEventListener('touchstart', this.onOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideClick, true);
    document.removeEventListener('touchstart', this.onOutsideClick, true);
  }

  onOutsideClick = (e) => {
    const popupRef = this.popupRef.current;
    if (popupRef !== e.target && !popupRef.contains(e.target)) {
      this.cancelSettings(this.props.closePopup(false));
    }
  };

  handleDragDrop = async (droppedItem) => {
    if (!droppedItem.destination) { return; }
    const { columnsOptions } = this.state;
    const updatedColumnsOptions = handleReorderAndUpdatedColumnsOptions(columnsOptions, droppedItem);
    this.setState({ columnsOptions: updatedColumnsOptions, isColumnsReordered: true });
  };

  onClosePopup = () => {
    const { selectedColumns, columnsOptions, isColumnsReordered } = this.state;
    this.props.closePopup(selectedColumns, isColumnsReordered ? columnsOptions : null);
  };

  render() {
    const {
      setPopupRef, closePopup, selectedStageKey,
    } = this.props;

    const {
      selectedColumns = [], isColumnsReordered, isAllOptionsSelected, columnsOptions,
    } = this.state;
    const hideOptionsFromColumns = ['firstSegment', 'secondSegment'];

    return (
      <div ref={this.popupRef}>
        <Popup
          ref={setPopupRef}
          title="Manage columns and metrics"
          className={attributionStyle.locals?.popup}
          onClose={() => this.cancelSettings()}
          primaryButton={{
            text: 'Save',
            onClick: () => this.onClosePopup(selectedColumns, isColumnsReordered),
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: () => this.cancelSettings(closePopup(false)),
          }}
          selectedStageKey={selectedStageKey}
        >
          <SearchInput
            ref={this.refSearchInput}
            debounce={0}
            hideSearchIcon
            onSearch={this.handleSearchOptionsQueryChange}
            placeholder="Search..."
            classes={{
              label: attributionStyle.locals?.optionsSearchLabel,
              input: attributionStyle.locals?.optionsSearchInput,
            }}
          />
          {columnsOptions.length === 0 ? (
            <div className={attributionStyle.locals?.notingFound}>
              Noting Found
            </div>
          )
            : (
              <div className={attributionStyle.locals?.selectAllOptions}>
                <CustomCheckbox
                  checked={isAllOptionsSelected}
                  onChange={this.selectAllOptionsHandler}
                  className={tablePopup.locals?.checkboxContainer}
                  checkboxClassName={tablePopup.locals?.checkbox}
                  checkMarkClassName={tablePopup.locals?.checkboxMark}
                  childrenClassName={tablePopup.locals?.checkboxLabel}
                >
                  Select all
                </CustomCheckbox>
              </div>
            )}

          <div className={attributionStyle.locals?.popupOptionsWrapper}>
            <div className={tablePopup.locals?.popupContentContainer}>
              <div className={tablePopup.locals?.popupContentColumn}>
                <CheckListWithDragAndDrop
                  listOptions={columnsOptions}
                  hideOptionsFromColumns={hideOptionsFromColumns}
                  selectedListOptions={selectedColumns}
                  onChangeCheckbox={this.changeIndicators}
                  handleDragDrop={this.handleDragDrop}
                />
              </div>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default AttributionTablePopup;
