import axios from 'axios';
import { action, decorate, observable } from 'mobx';
import { persist } from 'mobx-persist';
import hydrate from 'stores/hydrate';
import { isEmpty, cloneDeep } from 'lodash';

import { getWidgetDefinitions } from 'widgetDefinitions';
import config from 'components/utils/Configuration';
import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';

import { getWidgetHashKey } from 'stores/logic/widgetsAnalysisStore';
import { widgetsConcatResultFunction } from 'components/pages/analyze/enums';

class WidgetsAnalysisStore {
  constructor() {
    this.accountWithoutDataV2 = false;
    this.dataPerWidget = {};
    this.configPerWidget = {};
    this.widgetRequestToRequestId = {};
    this.widgetIdToRequestId = {};
    this.timeoutBetweenServerRequests = 2000;
    this.apiUrl = config.overrideURL ? config.overrideURL : window.location.hostname;
    this.protocol = config.overrideProtocol ? config.overrideProtocol : window.location.protocol;
  }

  async getWidgetData({
    widget,
    widgetConfig,
    configKeyName,
    useAccountViewLogic = true,
    isConcatResult = false,
    parentRequestId,
  }) {
    const widgetDefinitions = getWidgetDefinitions({ widget });

    const { UID } = userStore.userMonthPlan;
    const region = (useAccountViewLogic && attributionStore.isAccountMode) ? userStore.userMonthPlan.accountViewRegion : userStore.userMonthPlan.region;
    const isCompareEnabled = widgetConfig.compareToPreviousTimeFrame;

    if (!this.dataPerWidget[widget]) {
      this.dataPerWidget[widget] = {};
    }

    let requestId = this.getWidgetRequestId({
      widget,
      widgetConfig,
      isSkipGettingResultWithoutCompare: true,
    });
    if (widgetConfig.id) {
      const widgetIds = widgetConfig.unifyIds || [widgetConfig.id];
      this.setRequestIdToWidgetId({ widgetIds, requestId });
    }
    if (requestId) {
      return;
    }

    const requestHash = getWidgetHashKey({ widget, widgetConfig });
    const requestHashForWidgetWithoutCompare = this.getHashForWidgetWithoutCompare({
      widget,
      widgetConfig,
    });

    let tempRequestId;

    if (isCompareEnabled) {
      const widgetConfigWithoutCompare = this.getConfigForWidgetWithoutCompare({
        widget,
        widgetConfig,
      });
      const requestIdForWidgetWithoutCompare = this.getWidgetRequestId({
        widget,
        widgetConfig: widgetConfigWithoutCompare,
      });
      const widgetDataForWidgetWithoutCompare = this.dataPerWidget[widget][requestIdForWidgetWithoutCompare];

      if (!isEmpty(widgetDataForWidgetWithoutCompare?.result)) {
        tempRequestId = `temp_${requestIdForWidgetWithoutCompare}`;
        this.widgetRequestToRequestId[requestHash] = tempRequestId;
        this.dataPerWidget[widget][tempRequestId] = cloneDeep(widgetDataForWidgetWithoutCompare);
        this.dataPerWidget[widget][tempRequestId].status = 'inProgress';
      }
    }

    let response;
    try {
      response = await axios({
        method: 'post',
        url: this.getBaseUrl({ route: 'widget' }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${servicesStore.authService.getApiGatewayAccessToken()}`,
        },
        data: {
          UID,
          region,
          widgetName: widget,
          widgetConfig: widgetDefinitions ? widgetDefinitions.getWidgetConfig({ widgetConfig }) : widgetConfig,
        },
      });
    } catch (exception) {
      servicesStore.logger.error('failed to run widget server request from widget analysis store', {
        UID,
        region,
        method: 'post',
        widgetName: widget,
        widgetConfig,
        exception,
      });
      return;
    }

    let widgetData = response.data;
    const responseRequestId = response.data.requestId;
    requestId = parentRequestId || responseRequestId;

    if (widgetDefinitions) {
      widgetData.result = widgetDefinitions.processResponseFromServer({ result: widgetData.result, widgetConfig });
    }

    if (widgetConfig.id) {
      const widgetIds = widgetConfig.unifyIds || [widgetConfig.id];
      this.setRequestIdToWidgetId({ widgetIds, requestId });
    }

    if (isCompareEnabled && !this.widgetRequestToRequestId[requestHashForWidgetWithoutCompare]) {
      this.widgetRequestToRequestId[requestHashForWidgetWithoutCompare] = requestId;
    }

    const isNeedToPopulateDataFromFirstResponse = !tempRequestId || !isEmpty(widgetData?.result);
    if (isNeedToPopulateDataFromFirstResponse) {
      this.widgetRequestToRequestId[requestHash] = requestId;
      tempRequestId = null;
    }

    let currentWidgetData = this.dataPerWidget[widget][requestId]?.result;
    if (isConcatResult && currentWidgetData) {
      if (widgetsConcatResultFunction[widget]) {
        widgetData.result = widgetsConcatResultFunction[widget].concatResultFunction({
          currentWidgetData,
          newWidgetData: widgetData.result,
        });
      } else {
        widgetData.result = [...currentWidgetData, ...widgetData.result];
      }
    }

    let updatedWidgetData = { ...this.dataPerWidget[widget] };
    updatedWidgetData[requestId] = widgetData;
    this.dataPerWidget[widget] = updatedWidgetData;

    if (configKeyName) {
      const updatedConfigPerWidget = { ...this.configPerWidget };
      if (!updatedConfigPerWidget[widget]) {
        updatedConfigPerWidget[widget] = {};
      }
      if (!updatedConfigPerWidget[widget][configKeyName]) {
        updatedConfigPerWidget[widget][configKeyName] = {};
      }
      const widgetConfigToSave = { ...widgetConfig };
      delete widgetConfigToSave.filters;
      delete widgetConfigToSave.timeFrame;
      delete widgetConfigToSave.attributionModel;
      delete widgetConfigToSave.conversionIndicator;
      updatedConfigPerWidget[widget][configKeyName] = { ...widgetConfigToSave };
      this.configPerWidget = updatedConfigPerWidget;
    }

    if (widgetData.status === 'finished' || widgetData.status === 'failed') {
      return;
    }

    while (widgetData.status === 'inProgress') {
      await new Promise((resolve) => setTimeout(resolve, this.timeoutBetweenServerRequests));

      let widgetDataResponse;
      try {
        widgetDataResponse = await axios({
          method: 'get',
          url: `${this.getBaseUrl({ route: 'widget' })}&requestId=${responseRequestId}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${servicesStore.authService.getApiGatewayAccessToken()}`,
          },
        });
      } catch (exception) {
        servicesStore.logger.error('failed to run widget request for receiving widget data from widget analysis store', {
          UID,
          region,
          method: 'get',
          widgetName: widget,
          widgetConfig,
          exception,
        });
        return;
      }

      if (widgetDataResponse.data?.errorCode === 'ACCOUNT_WITHOUT_DATA') {
        this.accountWithoutDataV2 = true;
      }

      updatedWidgetData = { ...this.dataPerWidget[widget] };
      widgetData = widgetDataResponse.data;

      if (widgetDefinitions) {
        widgetData.result = widgetDefinitions.processResponseFromServer({ result: widgetData.result, widgetConfig });
      }

      currentWidgetData = this.dataPerWidget[widget][requestId]?.result;

      if (isConcatResult && currentWidgetData) {
        if (widgetsConcatResultFunction[widget]) {
          widgetData.result = widgetsConcatResultFunction[widget].concatResultFunction({
            currentWidgetData,
            newWidgetData: widgetData.result,
          });
        } else {
          widgetData.result = [...currentWidgetData, ...widgetData.result];
        }
      }

      updatedWidgetData[requestId] = widgetData;
      this.dataPerWidget[widget] = updatedWidgetData;

      const isNeedToPopulateDataFromCurrentResponse = this.widgetRequestToRequestId[requestHash] !== requestId && (!tempRequestId || !isEmpty(widgetData?.result));
      if (isNeedToPopulateDataFromCurrentResponse) {
        this.widgetRequestToRequestId[requestHash] = requestId;
        tempRequestId = null;
      }
    }
  }

  getWidgetRequestId({
    widget,
    widgetConfig,
    isSkipGettingResultWithoutCompare,
  }) {
    const requestHash = getWidgetHashKey({ widget, widgetConfig });
    const result = this.widgetRequestToRequestId[requestHash];

    if (result || !widgetConfig?.compareToPreviousTimeFrame || isSkipGettingResultWithoutCompare) {
      return result;
    }

    const requestHashForWidgetWithoutCompare = this.getHashForWidgetWithoutCompare({
      widget,
      widgetConfig,
    });
    return this.widgetRequestToRequestId[requestHashForWidgetWithoutCompare];
  }

  getBaseUrl({ route }) {
    const { UID, region } = userStore.userMonthPlan;
    const port = this.protocol === 'https:' ? '/api/' : `:${config.port}/`;
    return `${this.protocol}//${this.apiUrl}${port}${route}/${UID}?region=${region}`;
  }

  setRequestIdToWidgetId({ widgetIds, requestId }) {
    const updatedWidgetIdToRequestId = { ...this.widgetIdToRequestId };
    for (const widgetId of widgetIds) {
      updatedWidgetIdToRequestId[widgetId] = requestId;
    }
    this.widgetIdToRequestId = updatedWidgetIdToRequestId;
  }

  getHashForWidgetWithoutCompare({ widget, widgetConfig }) {
    const configForWidgetWithoutCompare = this.getConfigForWidgetWithoutCompare({
      widget,
      widgetConfig,
    });
    if (configForWidgetWithoutCompare) {
      return getWidgetHashKey({
        widget,
        widgetConfig: configForWidgetWithoutCompare,
      });
    }

    return null;
  }

  getConfigForWidgetWithoutCompare({ widget, widgetConfig }) {
    const widgetDefinitions = getWidgetDefinitions({ widget });
    const widgetConfigForHashCalculation = widgetDefinitions ? widgetDefinitions.getWidgetConfig({ widgetConfig }) : widgetConfig;

    if (widgetConfigForHashCalculation.compareToPreviousTimeFrame) {
      const clonedConfig = { ...widgetConfigForHashCalculation };
      delete clonedConfig.compareToPreviousTimeFrame;
      delete clonedConfig.previousTimeFrame;
      return clonedConfig;
    }
    return null;
  }

  resetWidgetData({ widget, widgetConfig }) {
    const requestId = this.getWidgetRequestId({ widget, widgetConfig });
    if (this.dataPerWidget[widget]?.[requestId]) {
      delete this.dataPerWidget[widget]?.[requestId];
    }
  }

  updateWidgetResultData({
    widget, newWidgetData, widgetRequestId,
  }) {
    if (this.dataPerWidget[widget][widgetRequestId]) {
      this.dataPerWidget[widget][widgetRequestId].result = newWidgetData;
    }
  }

  updateWidgetStatusIndication({ widget, widgetRequestId, newStatusIndication }) {
    if (this.dataPerWidget[widget][widgetRequestId]) {
      this.dataPerWidget[widget][widgetRequestId].status = newStatusIndication;
    }
  }
}

decorate(WidgetsAnalysisStore, {
  widgetRequestToRequestId: observable,
  accountWithoutDataV2: observable,
  dataPerWidget: observable,
  widgetIdToRequestId: observable,
  setRequestIdToWidgetId: action.bound,
  getWidgetRequestId: action.bound,
  updateWidgetRequestId: action.bound,
  resetWidgetData: action.bound,
  configPerWidget: observable.ref,
  updateWidgetResultData: action.bound,
  updateWidgetStatusIndication: action.bound,
});

const schema = {
  configPerWidget: {
    type: 'object',
  },
};

const store = persist(schema)(new WidgetsAnalysisStore());

hydrate('widgetsAnalysisStore', store);

export default store;
