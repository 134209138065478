exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".emptyStateWithImage__wrapper-3-x{background:#fff;text-align:center;align-items:center;color:#000;font-weight:600;display:flex;border-radius:12px;flex-direction:row;justify-content:space-between;box-shadow:0 4px 24px rgba(24,32,51,.03)}.emptyStateWithImage__leftGroup-tnB{text-align:left;padding-left:10%;max-width:400px}.emptyStateWithImage__leftGroup-tnB h1{font-style:normal;font-weight:600;font-size:24px;line-height:32px;color:#000;margin:0 0 6px;padding-bottom:16px}.emptyStateWithImage__half-1Rg{width:50%;flex-grow:1}.emptyStateWithImage__subTitle-2-C{font-style:normal;font-weight:400;font-size:16px;color:var(--black);margin-bottom:24px}", ""]);

// exports
exports.locals = {
	"wrapper": "emptyStateWithImage__wrapper-3-x",
	"wrapper": "emptyStateWithImage__wrapper-3-x",
	"leftGroup": "emptyStateWithImage__leftGroup-tnB",
	"leftGroup": "emptyStateWithImage__leftGroup-tnB",
	"half": "emptyStateWithImage__half-1Rg",
	"half": "emptyStateWithImage__half-1Rg",
	"subTitle": "emptyStateWithImage__subTitle-2-C",
	"subTitle": "emptyStateWithImage__subTitle-2-C"
};