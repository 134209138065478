import React from 'react';
import { inject, observer } from 'mobx-react';

import TrendAnalysis from 'components/pages/analyze/OverviewTab/TrendAnalysis';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

function TrendAnalysisWrapper({
  trendAnalysis,
  trendAnalysisParams,
  updateTrendAnalysisFrequency,
  updateTrendAnalysisMetrics,
  updateTrendAnalysisShouldUseRelativeTimeframe,
  selectedReport,
  widgetHeaderConfig,
  metricsOptions,
  widgetHeaderProps,
  widgetConfig,
  getWidgetRequestId,
}) {
  const requestId = getWidgetRequestId({ widget: widgetTypes.trendAnalysis, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });

  const trendAnalysisData = trendAnalysis?.[requestId]?.result;

  const isLoaded = trendAnalysis?.[requestId]?.status === 'finished';
  const isFailedToLoad = trendAnalysis?.[requestId]?.status === 'failed';

  return (
    <TrendAnalysis
      isLoaded={isLoaded}
      isFailedToLoad={isFailedToLoad}
      trendAnalysis={trendAnalysisData}
      trendAnalysisFrequency={trendAnalysisParams?.frequency}
      trendAnalysisShouldUseRelativeTimeframe={trendAnalysisParams?.shouldUseRelativeTimeframe}
      trendAnalysisMetrics={trendAnalysisParams?.metrics}
      updateTrendAnalysisFrequency={(frequencyValue) => updateTrendAnalysisFrequency({ reportId: selectedReport.value, widgetId: widgetHeaderConfig.widgetId, trendAnalysisFrequency: frequencyValue })}
      updateTrendAnalysisShouldUseRelativeTimeframe={() => updateTrendAnalysisShouldUseRelativeTimeframe({ reportId: selectedReport.value, widgetId: widgetHeaderConfig.widgetId, trendAnalysisShouldUseRelativeTimeframe: !(trendAnalysisParams?.shouldUseRelativeTimeframe) })}
      updateTrendAnalysisMetrics={(metrics) => updateTrendAnalysisMetrics({ reportId: selectedReport.value, widgetId: widgetHeaderConfig.widgetId, trendAnalysisMetrics: metrics })}
      widgetHeaderConfig={widgetHeaderConfig}
      metricsOptions={metricsOptions}
      widgetHeaderProps={widgetHeaderProps}
      isCompareToPreviousEnabled={widgetHeaderConfig.isCompareToPreviousEnabled}
    />
  );
}

export default inject(({
  reportsStore: {
    updateTrendAnalysisFrequency,
    updateTrendAnalysisShouldUseRelativeTimeframe,
    updateTrendAnalysisMetrics,
    selectedReport,
  },
  widgetsAnalysisStore: {
    getWidgetRequestId,
    dataPerWidget: {
      [widgetTypes.trendAnalysis]: trendAnalysis,
    },
  },
  attributionStore: {
    metricsOptions,
  },
}) => ({
  updateTrendAnalysisFrequency,
  updateTrendAnalysisShouldUseRelativeTimeframe,
  updateTrendAnalysisMetrics,
  selectedReport,
  metricsOptions,
  trendAnalysis,
  getWidgetRequestId,
}),
observer)(TrendAnalysisWrapper);
