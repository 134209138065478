import {
  action, computed, decorate, observable,
} from 'mobx';
import moment from 'moment';
import history from 'history';

import userStore from 'stores/userStore';
import serverCommunication from 'data/serverCommunication';
import timeFrameModule from 'modules/timeframe';
import attributionStore from 'stores/attributionStore';

import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

class DashboardStore {
  actualIndicators = null;

  funnelStagesToTheirGroupByType = null;

  isSimpleConversionRate = null;

  totalActualSpent = null;

  channelsSpendVsAttributedImpact = null;

  campaignsSpendVsAttributedImpact = null;

  totalBudgetedAmount = null;

  totalCampaignsCost = null;

  totalChannelsCost = null;

  previousTimeFrameActualIndicators = null;

  startDate = moment().startOf('month').toDate();

  endDate = moment().endOf('day').toDate();

  budgetPeriodEndDate = moment().endOf('month').toDate();

  prevPeriodStartDate = moment().subtract(1, 'months').startOf('month').toDate();

  prevPeriodEndDate = moment().subtract(1, 'months').endOf('month').toDate();

  timeFrameValue = this.defaultTimeFrameValue;

  accountWithoutData = false;

  setDashboardData({
    actualIndicators,
    funnelStagesToTheirGroupByType,
    isSimpleConversionRate,
    previousTimeFrameActualIndicators,
    totalPipeline,
    previousTimeFrameTotalPipeline,
    channelsSpendVsAttributedImpact,
    campaignsSpendVsAttributedImpact,
    totalActualSpent,
    totalBudgetedAmount,
    totalCampaignsCost,
    totalChannelsCost,
  }) {
    this.actualIndicators = actualIndicators || {};
    this.funnelStagesToTheirGroupByType = funnelStagesToTheirGroupByType || {};
    this.isSimpleConversionRate = isSimpleConversionRate;
    this.previousTimeFrameActualIndicators = previousTimeFrameActualIndicators;
    this.totalPipeline = totalPipeline;
    this.previousTimeFrameTotalPipeline = previousTimeFrameTotalPipeline;
    this.channelsSpendVsAttributedImpact = channelsSpendVsAttributedImpact;
    this.campaignsSpendVsAttributedImpact = campaignsSpendVsAttributedImpact;
    this.totalActualSpent = totalActualSpent;
    this.totalBudgetedAmount = totalBudgetedAmount;
    this.totalCampaignsCost = totalCampaignsCost;
    this.totalChannelsCost = totalChannelsCost;
  }

  setTimeFrame({
    startDate, endDate, prevPeriodStartDate, prevPeriodEndDate, budgetPeriodEndDate,
  }) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.budgetPeriodEndDate = budgetPeriodEndDate;
    this.prevPeriodStartDate = prevPeriodStartDate;
    this.prevPeriodEndDate = prevPeriodEndDate;
    return this.getDashboardData();
  }

  setTimeFrameValue({ timeFrameValue }) {
    this.timeFrameValue = timeFrameValue;
  }

  setDefaultTimeFrameValue() {
    this.timeFrameValue = this.defaultTimeFrameValue;
    const {
      startDate, endDate, prevPeriodStartDate, prevPeriodEndDate, budgetPeriodEndDate,
    } = this.getDatesAccordingTimeframeValue(this.defaultTimeFrameValue);
    this.startDate = startDate;
    this.endDate = endDate;
    this.budgetPeriodEndDate = budgetPeriodEndDate;
    this.prevPeriodStartDate = prevPeriodStartDate;
    this.prevPeriodEndDate = prevPeriodEndDate;
  }

  getDatesAccordingTimeframeValue = (timeframeValue) => {
    const { fiscalYearFirstMonth } = userStore.userMonthPlan;
    const {
      startDate, endDate, previousStartDate: prevPeriodStartDate, previousEndDate: prevPeriodEndDate,
    } = timeFrameModule.getTimeframeParams({ value: timeframeValue, fiscalYearFirstMonth });
    const { endDate: budgetPeriodEndDate } = timeFrameModule.getTimeframeParams({
      value: timeframeValue, fiscalYearFirstMonth, useEndOfToday: false,
    });

    return {
      startDate,
      endDate,
      budgetPeriodEndDate,
      prevPeriodStartDate,
      prevPeriodEndDate,
    };
  };

  getDashboardData() {
    const region = attributionStore.isAccountMode ? userStore.userMonthPlan.accountViewRegion : userStore.userMonthPlan?.region;
    const requestBody = {
      startDate: new Date(getTSForTimezone(this.startDate.getTime())),
      endDate: new Date(getTSForTimezone(this.endDate.getTime())),
      budgetPeriodEndDate: new Date(getTSForTimezone(this.budgetPeriodEndDate.getTime())),
      prevPeriodStartDate: new Date(getTSForTimezone(this.prevPeriodStartDate.getTime())),
      prevPeriodEndDate: new Date(getTSForTimezone(this.prevPeriodEndDate.getTime())),
    };

    return serverCommunication.serverRequest('post', 'dashboard', JSON.stringify(requestBody), region)
      .then((response) => {
        if (response.ok) {
          return response.json()
            .then((data) => {
              if (data.errorCode === 'ACCOUNT_WITHOUT_DATA') {
                this.accountWithoutData = true;
              }
              this.setDashboardData(data);
            });
        } else if (response.status === 401) {
          history.push('/');
          return Promise.reject();
        }
        return null;
      });
  }

  get defaultTimeFrameValue() {
    return userStore?.userMonthPlan?.dashboardConfig?.defaultTimeFrame || TIMEFRAME_VALUES.QUARTER;
  }
}

decorate(DashboardStore, {
  actualIndicators: observable,
  funnelStagesToTheirGroupByType: observable,
  isSimpleConversionRate: observable,
  channelsSpendVsAttributedImpact: observable,
  totalActualSpent: observable,
  campaignsSpendVsAttributedImpact: observable,
  totalPipeline: observable,
  previousTimeFrameTotalPipeline: observable,
  previousTimeFrameActualIndicators: observable,
  startDate: observable,
  endDate: observable,
  budgetPeriodEndDate: observable,
  timeFrameValue: observable,
  setDashboardData: action.bound,
  setTimeFrame: action.bound,
  setTimeFrameValue: action.bound,
  setDefaultTimeFrameValue: action.bound,
  getDashboardData: action.bound,
  defaultTimeFrameValue: computed,
  accountWithoutData: observable,
  totalBudgetedAmount: observable,
  totalCampaignsCost: observable,
  totalChannelsCost: observable,
});

export default new DashboardStore();
