import React, { useEffect } from 'react';
import { Button } from '@infinigrow/libs';

import CohortChart from 'components/pages/dashboard/CohortChart';
import WidgetHeader from 'components/common/WidgetHeader';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';

import { parseIndicatorsFromHistoricalPerformanceData, getIndicatorGrowths } from 'components/pages/analyze/OverviewTab/logic/FunnelView';
import { widgetTypes } from 'components/pages/analyze/enums';

import funnelViewStyles from 'styles/analyze/funnel-view.css';

const styles = funnelViewStyles.locals;

function FunnelView({
  navigateToJourneys,
  funnels,
  data,
  timeFrame,
  widgetHeaderConfig,
  isFailedToLoad,
  isLoaded,
  isCompareToPreviousEnabled,
  widgetHeaderProps = {},
}) {
  useEffect(() => {
    funnelViewStyles.use();
    return () => {
      funnelViewStyles.unuse();
    };
  }, []);

  const actualIndicators = parseIndicatorsFromHistoricalPerformanceData({ data });
  const indicatorGrowths = getIndicatorGrowths({ data });

  const funnelStagesToTheirGroupByType = data?.funnelStagesToTheirGroupByType || {};
  const isSimpleConversionRate = data?.isSimpleConversionRate;
  const widgetHeaderConfigWithDefaults = {
    title: 'Funnel View',
    ...(widgetHeaderConfig || {}),
    type: widgetTypes.funnelView,
  };

  function checkIfAllFunnelsIsLoaded() {
    const funnelNames = Object.values(funnels).map((funnel) => `new${funnel[0].toUpperCase() + funnel.slice(1)}`);
    return funnelNames.every((funnelName) => actualIndicators[funnelName] >= 0);
  }

  if (isFailedToLoad) {
    const allFunnelsIsLoaded = checkIfAllFunnelsIsLoaded();
    if (!allFunnelsIsLoaded) {
      return (
        <ErrorWidgetWithBlur
          status="error"
          widgetType={widgetTypes.funnelView}
        />
      );
    }
  }

  const widgetHeaderChildren = (
    <Button
      type="secondaryBlue"
      onClick={() => navigateToJourneys()}
    >
      Show journeys
    </Button>
  );

  return (
    <div>
      <WidgetHeader
        {...widgetHeaderProps}
        widgetHeaderConfig={widgetHeaderConfigWithDefaults}
      >
        {widgetHeaderChildren}
      </WidgetHeader>
      <div className={styles.wrapper}>
        <CohortChart
          withVelocity
          funnels={funnels}
          actualIndicators={actualIndicators}
          funnelStagesToTheirGroupByType={funnelStagesToTheirGroupByType}
          isSimpleConversionRate={isSimpleConversionRate}
          timeFrame={timeFrame}
          widgetHeaderConfig={widgetHeaderConfig}
          isLoaded={isLoaded}
          indicatorGrowths={indicatorGrowths}
          isCompareToPreviousEnabled={isCompareToPreviousEnabled}
        />
      </div>
    </div>
  );
}

export default FunnelView;
