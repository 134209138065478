import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';
import Textfield from 'components/controls/Textfield';
import Tooltip from 'components/controls/Tooltip';
import ScenarioRules from 'components/widgets/whatIf/ScenarioRules';
import IndicationTag from 'components/common/IndicationTag';
import ScenarioUplift from 'components/widgets/whatIf/ScenarioUplift';
import Skeleton from 'components/common/Skeleton';

import { getNickname } from 'components/utils/channels';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { baselineScenario, unclickableItemsKeys } from 'components/widgets/whatIf/enums';
import { skeletonSmallTextSizes } from 'components/common/enums';
import { getUpliftData } from 'components/widgets/whatIf/logic/scenarioBox';

import style from 'styles/analyze/scenario-box.css';

const styles = style.locals || {};

function ScenarioBox({
  kpiFocus,
  scenario,
  setScenario,
  onDeleted,
  scenariosBaseData,
  totalAmountScenariosData,
  isLoaded,
  isLoadedBaseData,
  onAddRule,
  isEditingScenarios,
  setIsEditingScenarios,
  resetScenarioSettingsById,
  userPaidChannels,
  flags,
}) {
  useStyles([style]);

  const [paidChannelsOptions, setPaidChannelsOptions] = useState(userPaidChannels.map((channel) => ({
    label: userStore.userChannelsSchema[channel]?.nickname || channel,
    value: channel,
  })));

  function onSetRules({ newRules, ruleIndex }) {
    const newScenario = cloneDeep(scenario);
    newScenario.rules[ruleIndex] = newRules;
    setScenario(newScenario);
  }

  function onChangeCollapse() {
    const updatedIsEditingScenarios = { ...isEditingScenarios };
    if (isEditing) {
      resetScenarioSettingsById({ scenarioId: scenario.id });
      delete updatedIsEditingScenarios[scenario.id];
    } else {
      updatedIsEditingScenarios[scenario.id] = true;
    }
    setIsEditingScenarios(updatedIsEditingScenarios);

    servicesStore.eventTracker.track({
      eventName: isEditing ? Events.whatIfCollapsedCard : Events.whatIfExpandedCard,
      properties: {
        scenarioType: scenario.type || baselineScenario,
      },
    });
  }

  function onAddChange() {
    onAddRule();

    servicesStore.eventTracker.track({
      eventName: Events.whatIfClickedAddChangeInScenario,
    });
  }

  const isEditing = isEditingScenarios[scenario.id] || false;
  const hadChartDataForCurrentScenario = totalAmountScenariosData[scenario.id];
  const isBaseline = scenario.id === baselineScenario;

  const upliftData = useMemo(() => {
    const totalAmountScenario = totalAmountScenariosData[scenario.id];
    const totalAmountBaseline = totalAmountScenariosData.predictedBaseline;
    return getUpliftData({
      totalAmountScenario, totalAmountBaseline, scenarioLabel: scenario.label, kpiFocus, isBaseline,
    });
  }, [totalAmountScenariosData]);

  return (
    <div className={styles.wrapper} style={{ borderColor: !hadChartDataForCurrentScenario && !isBaseline ? '#99A4C2' : scenario.borderColor }}>
      <div className={styles.header}>
        {isEditing && !isBaseline ? (
          <Tooltip
            id="scenarioBoxEdit"
            tip="Rename"
          >
            <Textfield
              value={scenario.label}
              className={styles.renameTitle}
              onChange={(e) => {
                const newScenario = cloneDeep(scenario);
                newScenario.label = e.target.value;
                setScenario(newScenario);
              }}
              dataTestId={`scenario-box-rename-${scenario.id}`}
            />
          </Tooltip>
        ) : (
          <div className={styles.scenarioLabel}>
            {scenario.label}
          </div>
        )}

        {(hadChartDataForCurrentScenario || isBaseline) ? (
          <div
            onClick={() => onChangeCollapse()}
            className={classnames(styles.collapse, isEditing && styles.rotate)}
            data-testid="scenario-box-collapse"
          />
        ) : null}
      </div>

      {isEditing ? (
        <>
          {scenario.rules.map((rule, index) => {
            const ruleKey = `ruleKey-${index}-${rule.channel}`;
            return (
              <div key={ruleKey}>
                {index > 0 ? (
                  <div className={styles.ruleGroupDivider}>
                    <div className={styles.dividerText}>AND</div>
                  </div>
                ) : null}
                <ScenarioRules
                  rules={rule}
                  paidChannelsOptions={paidChannelsOptions}
                  setPaidChannelsOptions={setPaidChannelsOptions}
                  setRules={(newRules) => onSetRules({ newRules, ruleIndex: index })}
                  isBaseline={isBaseline}
                  ruleBaseData={scenariosBaseData[rule?.channel || kpiFocus]}
                  scenarioType={scenario.type}
                  isLoadedBaseData={isLoadedBaseData}
                  kpiFocus={kpiFocus}
                />
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className={styles.scenarioPreview}>
            {scenario.rules.map((rule, index) => {
              const ruleKey = `preview-ruleKey-${index}-${rule.channel}`;
              return (
                <div className={styles.scenarioTags} key={ruleKey}>
                  {rule.channel ? <IndicationTag text={`${getNickname(rule.channel)}`} /> : null}
                  {rule.budgetChange && rule.budgetChangeOperator ? <IndicationTag text={`Budget ${rule.budgetChangeOperator} ${rule.budgetChange}%`} /> : null}
                  {rule.conversionRate ? <IndicationTag text={`Conversion Rate ${rule.conversionRate}%`} /> : null}
                  {rule.velocity ? <IndicationTag text={`Velocity ${rule.velocity} days`} /> : null}
                  {rule.avgDealSize ? <IndicationTag text={`Avg. Deal Size ${rule.avgDealSize}`} /> : null}
                  {index !== scenario.rules.length - 1 && scenario.rules.length > 1 ? <div className={styles.andText}>AND</div> : null}
                </div>
              );
            })}
          </div>

          <div className={styles.scenarioPreviewTotals}>
            <div className={styles.totalAmount}>
              {isLoaded ? (
                <>
                  {`${userStore.getMetricNickname({ metric: kpiFocus })}: `}
                  <b>
                    {isBaseline ? (
                      formatIndicatorDisplay(kpiFocus, totalAmountScenariosData.predictedBaseline, true)
                    ) : (
                      formatIndicatorDisplay(kpiFocus, totalAmountScenariosData[scenario.id], true)
                    )}
                  </b>
                </>
              ) : (
                <Skeleton {...skeletonSmallTextSizes} />
              )}
            </div>
            {!isBaseline && upliftData?.value ? (
              <ScenarioUplift
                value={upliftData.value}
                isDesired={upliftData.isDesired}
                isLoaded={isLoaded}
                tooltip={upliftData.tooltip}
              />
            ) : null}
          </div>
        </>
      )}

      {isEditing && !isBaseline ? (
        <div className={styles.buttons}>
          <Button
            type="primaryRed"
            onClick={() => onDeleted()}
          >
            Delete
          </Button>
          {scenario.type === 'channel' ? (
            <Button
              type="secondaryBlue"
              onClick={() => (flags.whatIfUnclickableItems[unclickableItemsKeys.addChange] ? null : onAddChange())}
            >
              Add Change
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      userPaidChannels,
    },
  }) => ({
    userPaidChannels,
  }),
  observer
)(ScenarioBox));
