import userStore from 'stores/userStore';
import React from 'react';

export const numberWithZeroDefault = (getter) => (arg, ...other) => getter(arg, ...other) || 0;

export const getMappingOptions = (idToLabelMap = {}) => {
  const mappingOptions = [];
  for (const [id, label] of Object.entries(idToLabelMap)) {
    const optionObject = { value: id, label };
    const customSegmentMapping = userStore.userAccount?.customSegmentsMapping?.[id];
    if (customSegmentMapping) {
      optionObject.disabled = !customSegmentMapping.enabled;
      optionObject.icon = (<img alt="customSegment" src="/assets/analyze-icons/custom-segment.svg" />);
    }
    mappingOptions.push(optionObject);
  }

  return mappingOptions;
};
