exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".Checkbox-module__wrapper___86Xzj{cursor:pointer;display:flex;align-items:center;gap:8px}.Checkbox-module__wrapper___86Xzj input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}.Checkbox-module__wrapper___86Xzj:hover input:checked~.Checkbox-module__checkmark___2Yu3C,.Checkbox-module__wrapper___86Xzj input:checked~.Checkbox-module__checkmark___2Yu3C{background-color:var(--blue);border:solid 1px var(--blue)}.Checkbox-module__wrapper___86Xzj input:checked~.Checkbox-module__checkmark___2Yu3C:after{opacity:1}.Checkbox-module__wrapper___86Xzj:hover input~.Checkbox-module__checkmark___2Yu3C{border:solid 1px var(--blue)}.Checkbox-module__disabled___2ROyX{cursor:default;pointer-events:none;.Checkbox-module__checkmark___2Yu3C{background-color:var(--lightGrayBackground)}}.Checkbox-module__checkmark___2Yu3C{height:20px;width:20px;background-color:var(--white);transition:all .2s ease;border-radius:4px;border:solid 1px var(--grayLine);position:relative}.Checkbox-module__checkmark___2Yu3C:after{content:\"\";position:absolute;left:7px;top:3px;width:4px;height:9px;border:solid var(--white);border-width:0 2px 2px 0;transform:rotate(45deg);opacity:0;transition:opacity .2s ease}.Checkbox-module__label___1C-xC{font-size:14px;color:var(--black);cursor:pointer}", ""]);

// exports
exports.locals = {
	"wrapper": "Checkbox-module__wrapper___86Xzj",
	"checkmark": "Checkbox-module__checkmark___2Yu3C",
	"disabled": "Checkbox-module__disabled___2ROyX",
	"label": "Checkbox-module__label___1C-xC"
};