import React from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';

import { formatBudget, roiFormatter } from 'components/utils/budget';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import MetricsItem from 'components/pages/dashboard/dashboardNavigate/MetricsItem';
import Component from 'components/Component';

import style from 'styles/dashboard/metrics.css';

class Metrics extends Component {
  style = style;

  render() {
    const {
      currentTimeFrame, containerClass, budget, funnelMetric1, funnelMetric2,
    } = this.props;
    const { funnelMetric1Value, funnelMetric2Value, newPipeline } = currentTimeFrame;
    const isCorrect = (v) => v >= 0 && isFinite(v);

    const efficiencySQL = isCorrect(budget / funnelMetric2Value) ? budget / funnelMetric2Value : 0;
    const efficiencyMQL = isCorrect(budget / funnelMetric1Value) ? budget / funnelMetric1Value : 0;
    const pipelineROI = roiFormatter(newPipeline / budget);
    const titleSQL = userStore.getMetricNickname({ metric: funnelMetric2, isSingular: true });
    const titleMQL = userStore.getMetricNickname({ metric: funnelMetric1, isSingular: true });
    return (
      <div className={classnames(containerClass, this.classes.metrics)}>
        <MetricsItem
          formatter={(v) => formatBudget(v, true, true)}
          value={budget}
          metricName="Spend"
          textClass={this.classes.metricsText}
          bold
        />
        <MetricsItem
          formatter={(v) => formatIndicatorDisplay(funnelMetric1, v, true)}
          value={funnelMetric1Value}
          metricName={userStore.getMetricNickname({ metric: funnelMetric1 })}
          metric
          textClass={this.classes.metricsText}
          bold
          tipText={`Cost per ${titleMQL}: ${formatBudget(efficiencyMQL, true, true)}`}
        />
        <MetricsItem
          formatter={(v) => formatIndicatorDisplay(funnelMetric2, v, true)}
          value={funnelMetric2Value}
          metricName={userStore.getMetricNickname({ metric: funnelMetric2 })}
          metric
          textClass={this.classes.metricsText}
          bold
          tipText={`Cost per ${titleSQL}: ${formatBudget(efficiencySQL, true, true)}`}
        />
        <MetricsItem
          formatter={(v) => formatBudget(v, true, true)}
          value={newPipeline}
          metricName={userStore.getMetricNickname({ metric: 'newPipeline' })}
          metric
          textClass={this.classes.metricsText}
          bold
          tipText={`Pipeline ROI: ${pipelineROI}`}
        />
      </div>
    );
  }
}

export default Metrics;
