import serverCommunication from 'data/serverCommunication';
import { isEqual } from 'lodash';

import servicesStore from 'stores/servicesStore';

export async function handleSendReportLink({
  region,
  handleError,
  requestData,
  handleResponseData,
}) {
  try {
    const response = await serverCommunication.serverRequest('PUT', 'crm/reports/run', JSON.stringify(requestData), region);

    const responseData = await response.json();
    handleResponseData({
      responseData: {
        ...responseData,
        status: responseData.status || 'inProgress',
      },
    });
    const crmReportRequestId = responseData.crmReportRequestId;

    if (!crmReportRequestId) {
      throw new Error('No crmReportRequestId received');
    }

    let responseStatus = 'inProgress';

    while (responseStatus === 'inProgress') {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const pollingResponse = await serverCommunication.serverRequest('GET', 'crm/reports', undefined, region, undefined, (URL) => `${URL}&crmReportRequestId=${crmReportRequestId}`);

      const pollingResponseData = await pollingResponse.json();
      handleResponseData({ responseData: pollingResponseData });
      responseStatus = pollingResponseData.status;
    }
  } catch (error) {
    servicesStore.logger.error('failed to send report-sync link', { error });
    handleError();
  }
}

export async function getReportSyncStatus({
  region,
  handleResponseData,
}) {
  try {
    const response = await serverCommunication.serverRequest('get', 'crm/reports', null, region, undefined, undefined, true);

    const responseData = await response.json();
    handleResponseData({ responseData });
  } catch (error) {
    servicesStore.logger.error('failed to get report-sync status', { error });
  }
}

export function mergeCustomFields({ funnelNewCustomFields = [], customFields = [] }) {
  const oldCustomFieldIdToMergedId = {};

  for (const newCustomField of funnelNewCustomFields) {
    const hasEmptyMergingConfiguration = newCustomField.mergingConfiguration.length === 0;

    if (hasEmptyMergingConfiguration) {
      continue;
    }

    const similarCustomField = customFields.find((existingCustomField) => {
      const hasTheSameAmountOfConfiguration = newCustomField.mergingConfiguration.length === existingCustomField.mergingConfiguration.length;

      if (!hasTheSameAmountOfConfiguration) {
        return false;
      }

      const hasTheSameObject = newCustomField.mergingConfiguration[0].objectName === existingCustomField.mergingConfiguration[0].objectName;
      const hasTheSameFields = isEqual(newCustomField.mergingConfiguration[0].fieldsToMerge, existingCustomField.mergingConfiguration[0].fieldsToMerge);

      return hasTheSameObject && hasTheSameFields;
    });

    if (similarCustomField) {
      oldCustomFieldIdToMergedId[newCustomField.id] = similarCustomField.id;
      continue;
    }

    customFields.push(newCustomField);
  }

  return { customFields, oldCustomFieldIdToMergedId };
}

export function isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey, funnelStage }) {
  for (const [funnelName, mapping] of Object.entries(funnelMapping)) {
    if (funnelStage === funnelName) {
      continue;
    }

    if (mapping[revenueKey]) {
      return true;
    }
  }

  return false;
}
