import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import useWindowDimensions from 'components/utils/getWindowDimensions';

import style from 'styles/analyze/emptyStateWithImage.css';

const styles = style.locals || {};

export default function EmptyStateWithImage({
  title, subTitle, buttonName, onClick, imageClassName, className,
}) {
  const { height: screenHeight } = useWindowDimensions();
  const popupHeight = `${screenHeight - 600}px`;

  useEffect(() => {
    style.use();

    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  return (
    <div className={classNames(styles.wrapper, className)} style={{ height: popupHeight }}>
      <div className={classNames(styles.half, styles.leftGroup)}>
        <h1>{title}</h1>
        {subTitle ? (
          <div className={styles.subTitle}>{subTitle}</div>
        ) : null}
        <Button
          type="primaryBlue"
          onClick={() => onClick()}
        >
          {buttonName}
        </Button>
      </div>
      <div className={classNames(styles.half, imageClassName)} />
    </div>
  );
}
