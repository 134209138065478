import React from 'react';
import { inject, observer } from 'mobx-react';
import history from 'history';
import { isEmpty } from 'lodash';

import useStyles from 'hooks/useStyles';
import { getComponentAccordingWidgetType, getComponentProps } from 'components/pages/reports/logic/reports';

import style from 'styles/questions/questions.css';

const styles = style.locals || {};

function QuestionResults({ selectedQuestionWidgetsMetadataResults, questionWidgetsDataResults }) {
  useStyles([style]);

  if (isEmpty(selectedQuestionWidgetsMetadataResults)) {
    history.push('/questions');
    return null;
  }

  const widgetProps = getComponentProps({ widgetData: { ...selectedQuestionWidgetsMetadataResults, ...questionWidgetsDataResults }, isInReports: false, isShowTimeframeSelect: true });
  const WidgetComponent = getComponentAccordingWidgetType({ widgetType: selectedQuestionWidgetsMetadataResults.type });

  return (
    <div className={styles.wrapper}>
      <WidgetComponent
        key={`widget-${selectedQuestionWidgetsMetadataResults.type}-${selectedQuestionWidgetsMetadataResults.id}`}
        {...widgetProps}
        isReadOnly
        isLoaded={questionWidgetsDataResults.isLoaded}
        isLoadedTrendData={questionWidgetsDataResults.isLoadedTrendData}
      />
    </div>
  );
}

export default inject(({
  questionsStore: {
    selectedQuestionWidgetsMetadataResults,
    questionWidgetsDataResults,
  },
}) => ({
  selectedQuestionWidgetsMetadataResults,
  questionWidgetsDataResults,
}),
observer)(QuestionResults);
