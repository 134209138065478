import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';

import { impactChartTypes } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { FREQUENCY_OPTIONS } from 'components/utils/frequency';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { getImpactByFieldsToEnrichAccordingSegment } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { getDrilldownMetricsOptions, segmentsGroupByCommonSegments } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { nonCumulativeMetricTypes } from 'components/pages/settings/goals/logic/enums';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function ImpactBySegmentProperties({
  selectedWidgetProperties,
  onSelectedWidgetProperties,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  widgetProps,
}) {
  useStyles([style]);

  useEffect(() => {
    const defaultProperties = {
      toggleViewType: impactChartTypes.bar,
      impactBySegmentParams: {
        segmentFrequency: FREQUENCY_OPTIONS.DAY?.value,
      },
      checkedSegments: [],
      uncheckedSegments: [],
    };
    onSelectedWidgetProperties({ values: defaultProperties });
  }, []);

  function onChangeBreakdownBy({ value }) {
    const impactBySegmentParams = {
      segmentFrequency: selectedWidgetProperties.impactBySegmentParams?.segmentFrequency,
      firstSegment: value,
      fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: value } }),
    };
    const updatedValues = { impactBySegmentParams };
    if (widgetProps.isCalculateAdvancedMetrics) {
      const trendBySegmentsParams = {
        firstSegment: value, secondSegment: null,
      };
      updatedValues.trendBySegmentsParams = trendBySegmentsParams;
    }
    onSelectedWidgetProperties({ values: updatedValues });
  }

  const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });

  function onChangeMetric({ value, relevantFor }) {
    const isNonCumulativeMetric = nonCumulativeMetricTypes.some((metric) => value.toLowerCase().includes(metric.toLowerCase()));
    onSelectedWidgetProperties({
      values: {
        kpiFocus: value,
        conversionIndicator: relevantFor || value,
        toggleViewType: isNonCumulativeMetric ? impactChartTypes.line : impactChartTypes.bar,
      },
    });
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>Metric</div>
        <Dropdown
          onChange={({ value, relevantFor }) => onChangeMetric({ value, relevantFor })}
          options={getDrilldownMetricsOptions({})}
          selectedKey={selectedWidgetProperties.kpiFocus}
          isSearchable
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Breakdown by</div>
        <Dropdown
          onChange={({ value }) => onChangeBreakdownBy({ value })}
          options={segmentsGroupByCommonSegments({ options: segmentsOptionsFields })}
          selectedKey={selectedWidgetProperties.impactBySegmentParams?.firstSegment}
          isSearchable
          disabled={!selectedWidgetProperties.kpiFocus}
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Frequency</div>
        <Dropdown
          onChange={({ value }) => onSelectedWidgetProperties({
            values: {
              impactBySegmentParams: {
                segmentFrequency: value,
                firstSegment: selectedWidgetProperties.impactBySegmentParams?.firstSegment,
                fieldsToEnrich: selectedWidgetProperties.impactBySegmentParams?.fieldsToEnrich,
              },
            },
          })}
          options={[
            FREQUENCY_OPTIONS.DAY,
            FREQUENCY_OPTIONS.WEEK,
            FREQUENCY_OPTIONS.MONTH,
            FREQUENCY_OPTIONS.QUARTER,
          ]}
          selectedKey={selectedWidgetProperties.impactBySegmentParams?.segmentFrequency}
          disabled={!selectedWidgetProperties.kpiFocus || !selectedWidgetProperties.impactBySegmentParams?.firstSegment}
        />
      </div>
    </>
  );
}

export default inject(({
  userStore: {
    userAccount: {
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
    } = {},
  } = {},
}) => ({
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
}),
observer)(ImpactBySegmentProperties);
