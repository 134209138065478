import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import AIFloatSuggestionBox from 'components/pages/questions/AIFloatSuggestionBox';
import AskQuestionInput from 'components/pages/questions/AskQuestionInput';
import Suggestions from 'components/pages/questions/Suggestions';

import {
  defaultSuggestionsBox,
  suggestionsResultsByType,
  suggestionsTypes,
  mockQuestions,
  suggestionsFloatBoxByType,
} from 'components/pages/questions/logic/mockAIAnswers';
import { getComponentAccordingWidgetType, getComponentProps } from 'components/pages/reports/logic/reports';
import { getWidgetConfigWithPlaceHolderValues } from 'components/pages/questions/logic/askQuestion';

import style from 'styles/questions/ai-answers.css';
import questionsStyle from 'styles/questions/questions.css';

const styles = style.locals || {};
const questionsStyles = questionsStyle.locals || {};

function AIAnswers({
  flags,
  setQuestionWidgetsMetadataResults,
  aiAnswersDataResults,
  questionWidgetsMetadataResults,
}) {
  useStyles([style, questionsStyle]);

  if (!flags.aiAnswersDemoOnly) {
    history.push('/questions');
    return null;
  }

  const [answersWidgets, setAnswersWidgets] = useState([]);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [isSuggestionsPopupOpen, setIsSuggestionsPopupOpen] = useState(false);
  const [questionInputText, setQuestionInputText] = useState('');
  const [currentAnswerType, setCurrentAnswerType] = useState(null);

  const askQuestionRef = useRef(null);
  const lastWidgetRef = useRef(null);

  useOnClickOutside(askQuestionRef, () => {
    setIsSuggestionsPopupOpen(false);
  });

  function getSuggestionsResultsDataByText() {
    if (suggestionsData.length > 0) {
      setSuggestionsData([]);
    }
    let suggestionsType = mockQuestions.find((question) => question.text.toLowerCase().includes(questionInputText.toLowerCase()))?.type;
    if (!suggestionsType) {
      suggestionsType = suggestionsTypes.default;
    }
    setTimeout(() => setSuggestionsData(suggestionsResultsByType[suggestionsType]), 500);
  }

  useEffect(() => {
    if (isEmpty(aiAnswersDataResults)) {
      setAnswersWidgets([]);
    }
  }, [aiAnswersDataResults]);

  useEffect(() => {
    if (questionInputText !== '') {
      const delayDebounce = setTimeout(() => {
        setIsSuggestionsPopupOpen(true);
        getSuggestionsResultsDataByText();
      }, 1000);
      return () => clearTimeout(delayDebounce);
    }
    return () => ({});
  }, [questionInputText]);

  useEffect(() => {
    if (lastWidgetRef?.current) {
      lastWidgetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersWidgets]);

  function onSelectedSuggestion({ questionId, placeHoldersValues }) {
    const questionData = suggestionsData.find((question) => question.questionId === questionId);
    if (questionData?.widget) {
      const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
      setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId, shouldAppendDataById: true });
      setCurrentAnswerType(questionData.questionType);
      setIsSuggestionsPopupOpen(false);
      setAnswersWidgets([...answersWidgets, { ...questionData.widget, questionId }]);
      setQuestionInputText('');
    }
  }

  function onSelectedSuggestionFloatBox({ questionData, placeHoldersValues }) {
    const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
    setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId: questionData.questionId, shouldAppendDataById: true });
    setCurrentAnswerType(questionData.questionType);
    setAnswersWidgets([...answersWidgets, { ...questionData.widget, questionId: questionData.questionId }]);
  }

  const suggestionsFloatBox = useMemo(() => {
    if (answersWidgets.length === 0) {
      return [];
    }

    return suggestionsFloatBoxByType[currentAnswerType] || [];
  }, [answersWidgets]);

  const isShowFloatSuggestions = answersWidgets.length > 0 && suggestionsFloatBox.length > 0 && questionInputText === '' && !isSuggestionsPopupOpen;

  return (
    <div className={styles.answersSuggestionWrapper}>
      {answersWidgets.length === 0 ? (
        <div className={styles.answersEmptyState}>
          <div className={styles.answersTitle}>
            <div className={styles.answersIcon} />
            AI Answers
            <div className={styles.betaTag}>Beta</div>
          </div>
          <div className={styles.answersSubTitle}>Ask questions in natural language, and InfiniGrow will automatically generate an answer and suggest followup analyses.</div>
          <div className={styles.answersSuggestionBoxWrapper}>
            {defaultSuggestionsBox.map((suggestion) => (
              <AIFloatSuggestionBox
                key={suggestion.id}
                content={suggestion.content}
                onClick={suggestion.question ? () => {
                  onSelectedSuggestionFloatBox({ questionData: suggestion.question, placeHoldersValues: suggestion.question.defaultPlaceHoldersValues });
                } : null}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          {answersWidgets.map((widget, index) => {
            const currentAIAnswersLoadingResults = aiAnswersDataResults[widget.questionId] || {};
            const currentWidgetsMetadataResults = questionWidgetsMetadataResults[widget.questionId] || {};
            const widgetProps = getComponentProps({ widgetData: { ...widget, ...currentWidgetsMetadataResults, ...currentAIAnswersLoadingResults }, isInReports: false, isShowTimeframeSelect: true });
            const WidgetComponent = getComponentAccordingWidgetType({ widgetType: widget.type });
            const answerKey = `widget-${currentWidgetsMetadataResults.type}-${index}`;
            return (
              <div key={answerKey}>
                {index === answersWidgets.length - 1 && answersWidgets.length > 1 && <div ref={lastWidgetRef} />}
                <WidgetComponent
                  {...widgetProps}
                  isLoaded={currentAIAnswersLoadingResults.isLoaded}
                  isLoadedTrendData={currentAIAnswersLoadingResults.isLoadedTrendData}
                  isReadOnly
                />
              </div>
            );
          })}
        </>
      )}

      <div className={styles.aiAnswersAskWrapper} ref={askQuestionRef}>
        {isShowFloatSuggestions ? (
          <div className={styles.answersSuggestionBoxWrapper}>
            {suggestionsFloatBox.map((suggestion) => (
              <AIFloatSuggestionBox
                key={suggestion.id}
                content={suggestion.content}
                iconPosition="left"
                onClick={suggestion.question ? () => {
                  onSelectedSuggestionFloatBox({ questionData: suggestion.question, placeHoldersValues: suggestion.question.defaultPlaceHoldersValues });
                } : null}
              />
            ))}
          </div>
        ) : null}
        <div className={styles.aiAnswersAskInputWrapper}>
          <AskQuestionInput
            value={questionInputText}
            placeholder={answersWidgets.length === 0 ? 'Ask any question' : 'Ask any followup question'}
            onFocus={() => (isSuggestionsPopupOpen || !questionInputText ? null : setIsSuggestionsPopupOpen(true))}
            onChange={(event) => setQuestionInputText(event.target.value)}
          />
          <div className={classnames(styles.aiAnswersResultsWrapper, questionsStyles.questionResultsWrapper, isSuggestionsPopupOpen && styles.aiAnswersResultsHeight)}>
            <Suggestions
              data={suggestionsData}
              onSelectSuggestion={({ questionId, placeHoldersValues }) => onSelectedSuggestion({ questionId, placeHoldersValues })}
              isShowHeaderTitle={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withLDConsumer()(inject(({
  questionsStore: {
    setQuestionWidgetsMetadataResults,
    questionWidgetsMetadataResults,
    aiAnswersDataResults,
    setAIAnswersDataResults,
  },
}) => ({
  setQuestionWidgetsMetadataResults,
  questionWidgetsMetadataResults,
  aiAnswersDataResults,
  setAIAnswersDataResults,
}),
observer)(AIAnswers));
