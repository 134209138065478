import React, { useMemo } from 'react';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import Dropdown from 'components/controls/Dropdown';

import { getGroupedUserMetrics } from 'stores/logic/userStore';
import { getFunnelNameWithoutNew } from 'components/pages/analyze/OverviewTab/logic/PerformanceItem';
import { singleMetricGroupsToRemove } from 'components/pages/widgetBuilder/propertiesComponents/enums';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function SingleMetricProperties({
  selectedWidgetProperties, onSelectedWidgetProperties,
}) {
  useStyles([style]);

  function onChangeProperty({ value }) {
    onSelectedWidgetProperties({ values: value });
  }

  const metricsOptions = useMemo(() => {
    const metricsWithNew = userStore.getUserMetricsWithNew();
    const groupedMetrics = getGroupedUserMetrics({ userMetrics: metricsWithNew });
    return groupedMetrics.filter((group) => !singleMetricGroupsToRemove.includes(group.groupName));
  }, []);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>Metric</div>
        <Dropdown
          onChange={({ value }) => {
            const valueWithNew = getFunnelNameWithoutNew(value);
            onChangeProperty({
              value: {
                kpiFocus: value,
                indicator: value,
                selectedMetrics: [valueWithNew],
              },
            });
          }}
          options={metricsOptions}
          selectedKey={selectedWidgetProperties.kpiFocus}
          isSearchable
        />
      </div>
    </>
  );
}

export default SingleMetricProperties;
