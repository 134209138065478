export function numberOrZero(number) {
  return (number && isFinite(number)) ? number : 0;
}

export function formatNumberWithDecimalPoint(number, numberOfDigitsAfterPoint = 2) {
  if (isNaN(number)) {
    return '0';
  }
  return (Math.round(number * 100) / 100).toFixed(numberOfDigitsAfterPoint);
}

export function formatNumberAvgRatioWithDecimalPoint({ value, numberOfDigitsAfterPoint }) {
  const avgRatio = 1 + (value / 100);
  return formatNumberWithDecimalPoint(avgRatio, numberOfDigitsAfterPoint);
}

export function formatNumberWithCommas({ number }) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatToFloatWithoutZeros({ number, numberOfDigitsAfterPoint = 2 }) {
  const floatNumber = parseFloat(number).toFixed(numberOfDigitsAfterPoint);
  const floatWithoutZeros = floatNumber.replace(/\.00$/, '');
  return floatWithoutZeros;
}
