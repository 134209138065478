import React from 'react';
import PropTypes from 'prop-types';
import {
  isEqual, orderBy, isNil, sumBy, camelCase,
} from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment/moment';

import AttributionTableRowWithNavigationMenu from 'components/pages/analyze/AttribuitonTable/AttributionTableRowWithNavigationMenu';
import ChannelIcon from 'components/common/ChannelIcon';
import Component from 'components/Component';
import DrillDownPopup from 'components/pages/analyze/inspectData/DrillDownPopup';
import ExpendCell from 'components/pages/analyze/AttribuitonTable/ExpendCell';
import FooterExpendCell from 'components/pages/analyze/AttribuitonTable/FooterExpendCell';
import HeaderExpendColumn from 'components/pages/analyze/AttribuitonTable/HeaderExpendColumn';
import Inspect from 'components/pages/analyze/inspectData/Inspect';
import TrendMark from 'components/pages/analyze/TrendMark';
import ComparisonValue from 'components/common/ComparisonValue';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Skeleton from 'components/common/Skeleton';
import Loading3Dots from 'components/controls/Loading3Dots';

import {
  FUNNEL1, mapChartIndicator, CROSS_FUNNEL_TOGGLE, getPipelineFunnel, getRevenueFunnel,
} from 'components/utils/indicators';
import ChannelList from 'components/common/ChannelList';

import { getStagesAmountsForSegments } from 'components/pages/analyze/logic/segmentAnalysis';
import { averageFormatter, getDaysPluralName, compose } from 'components/utils/utils';
import {
  crossFunnel, webVisits, metricWithoutShareOfTotal, pageViews,
  segmentsKeys,
} from 'components/pages/analyze/AttribuitonTable/enums';
import { conversionRateFormatter, formatBudget, roiFormatter } from 'components/utils/budget';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { getCategoryIcon, getChannelIcon } from 'components/utils/filters/channels';
import { getSavedSelectedSegments, parseSegmentsForRequest } from 'components/pages/analyze/OverviewTab/logic';
import { numberWithZeroDefault } from 'components/utils/logic/utils';
import { widgetsConfig, widgetTypes, queryParamsNames } from 'components/pages/analyze/enums';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import {
  calculateAverage,
  getFooterTotalsForKey,
  getTrendValuesForCell,
  tableDataWithoutNoValue,
  tableDataWithoutOtherCampaigns,
  sortColumnMethod,
  getCrossFunnelData,
  getTableColumnKeys,
  getColumnOptionsByData,
  getChannelsColumnOptionsByData,
  getUpdateFilteredTableData,
} from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { getItemLabelWithNoValue } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { getFiltersForShowOnlyRequest, getFiltersForViewJourneysRequest, getFiltersWithoutFocusMode } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { makeChannelsFilter, VARIANTS } from 'components/utils/filters/make';
import { setQueryParams, getQueryParams } from 'components/utils/UrlParamsProvider';
import { getWidgetsData } from 'components/pages/analyze/widgetsRequest';
import { getAllColumnOptionsAndGroupedOptions, mergeAllFunnelsTableData, getColumnMetricNickName } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import { contentTypeSegmentValue, contentURLSegmentValue, contentPathSegmentValue } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { getDefaultStageSelectorData } from 'components/pages/analyze/AttribuitonTable/logic/StageSelector';
import { skeletonSmallTableCellSizes, skeletonTableRows, skeletonSmallTextSizes } from 'components/common/enums';

import style from 'styles/analyze/analyze.css';

const enhance = compose(
  inject((stores) => {
    const {
      userStore = {},
      analysisStore = {},
    } = stores;
    const {
      funnels,
      userMonthPlan: {
        CRMConfig: { timezone } = {},
      } = {},
      getMetricType,
      getUserDynamicMetricsPerFunnel,
      userFunnels,
      userMetrics,
      getMetricRelevantFor,
    } = userStore;
    const {
      dataPerWidget: {
        [widgetTypes.creditBySessions]: creditBySessions,
      } = {},
    } = analysisStore;
    return {
      funnels,
      creditBySessions,
      timezone,
      getMetricType,
      getUserDynamicMetricsPerFunnel,
      userFunnels,
      userMetrics,
      getMetricRelevantFor,
    };
  }),
  observer
);

const AttributionSegmentsTableParseData = class extends Component {
  style = style;

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
    columnsBefore: PropTypes.array,
    columnsAfter: PropTypes.array,
    showTotalRow: PropTypes.bool,
    TableProps: PropTypes.object,
    defaultStageKey: PropTypes.string,
    expendColumnsKeys: PropTypes.array,
  };

  static defaultProps = {
    title: '',
    data: {},
    TableProps: {},
    defaultStageKey: '',
    columnsBefore: [],
    columnsAfter: [],
    showTotalRow: true,
    expendColumnsKeys: [],
  };

  constructor(props) {
    super(props);

    const openSegmentAnalysisWhatsNextPopup = getQueryParams({ queryParamKey: queryParamsNames.openSegmentAnalysisWhatsNextPopup });
    if (openSegmentAnalysisWhatsNextPopup) {
      this.segmentToOpenWhatsNextPopup = openSegmentAnalysisWhatsNextPopup;
    }

    const selectedStageKey = mapChartIndicator(this.props.defaultStageKey) || FUNNEL1;
    const defaultSortByColumn = { id: selectedStageKey === crossFunnel ? 'attributedFunnel1' : camelCase(`attributed ${selectedStageKey}`), desc: true };
    this.state = {
      selectedStageKey,
      selectedSegments: getSavedSelectedSegments(props.segmentsOptionsFields, props.segmentsAnalysisParams),
      expendColumnsKeys: this.props.expendColumnsKeys,
      focusMode: null,
      crossFunnelToggle: this.props.crossFunnelToggle || CROSS_FUNNEL_TOGGLE.EFFECTIVENESS,
      isShowInspectPopup: false,
      drillDownPopupEntityId: null,
      inspectPopupData: {},
      tableData: this.props.data || [],
      originalTableData: this.props.data || [],
      prevTableData: this.props.prevData || [],
      sortByColumn: props.sortByColumn?.id ? props.sortByColumn : defaultSortByColumn,
      filtersByColumn: props.filtersByColumn || {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.segmentsOptionsFields, this.props.segmentsOptionsFields)) {
      this.setState({ selectedSegments: getSavedSelectedSegments(nextProps.segmentsOptionsFields, nextProps.segmentsAnalysisParams) });
    }

    if (!isEqual(nextProps.segmentsAnalysisParams, this.props.segmentsAnalysisParams)) {
      const updateSelectedSegments = getSavedSelectedSegments(this.props.segmentsOptionsFields, nextProps.segmentsAnalysisParams);
      this.setState({ selectedSegments: updateSelectedSegments, expendColumnsKeys: [] });
    }

    if (!isEqual(nextProps.data, this.props.data)) {
      const fullTableData = this.getTableDataWithCrossFunnel({ data: nextProps.data });
      this.setState({ tableData: fullTableData, originalTableData: fullTableData });
      if (this.state.searchTableValues || this.state.filtersByColumn) {
        this.setState((prevState) => {
          const filteredData = getUpdateFilteredTableData({
            data: fullTableData,
            segment: prevState.firstSegment?.value,
            searchValue: prevState.searchTableValues?.value || '',
            searchColumn: prevState.searchTableValues?.searchColumn,
            filtersByColumn: prevState.filtersByColumn,
            stageAmountKeys: this.stageAmountKeys,
            isOtherCampaignsHidden: nextProps.isOtherCampaignsHidden,
          });
          return { tableData: filteredData };
        });
      } else {
        this.setState({ focusMode: false, tableData: nextProps.data });
      }
    }

    if (nextProps.isOtherCampaignsHidden !== this.props.isOtherCampaignsHidden) {
      const fullTableData = this.getTableDataWithCrossFunnel({ data: nextProps.data });
      this.setState((prevState) => {
        const filteredData = getUpdateFilteredTableData({
          data: fullTableData,
          segment: prevState.firstSegment?.value,
          searchValue: prevState.searchTableValues?.value || '',
          searchColumn: prevState.searchTableValues?.searchColumn,
          filtersByColumn: prevState.filtersByColumn,
          stageAmountKeys: this.stageAmountKeys,
          isOtherCampaignsHidden: nextProps.isOtherCampaignsHidden,
        });
        return { tableData: filteredData };
      });
    }
  }

  getTableDataWithCrossFunnel({ data }) {
    const crossFunnelData = getCrossFunnelData({
      data,
      funnels: this.props.funnels,
      type: this.state.crossFunnelToggle,
    });

    return {
      ...data,
      [crossFunnel]: crossFunnelData,
    };
  }

  get isCategoryView() {
    return this.state.selectedSegments.firstSegment.value === 'category';
  }

  getEfficiencyColumn = (value, indicator) => (
    <div className={this.classes.averageCell}>
      <span style={{ fontWeight: 600 }}>
        {averageFormatter(value)}
      </span>
      {` per ${indicator}`}
    </div>
  );

  getChannelColumn({ channel, isCategoryViewByColumn }) {
    return (
      <div className={this.classes.firstSegmentChannelIcon}>
        {isCategoryViewByColumn ? (
          <div
            className={this.classes.categoryIcon}
            data-icon={getCategoryIcon(channel)}
          />
        ) : (
          <ChannelIcon
            className={this.classes.channelIcon}
            channelIcon={getChannelIcon(channel)}
            channel={channel}
          />
        ) }
        <div
          className={this.classes.firstSegmentClickable}
          onClick={() => {
            const showOnlyFilter = [makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [channel], isCategoryViewByColumn)];
            return navigateBetweenAnalyzeTabs({
              filters: showOnlyFilter,
              conversionIndicator: this.state.selectedStageKey,
              tabName: 'campaigns',
              anchor: 'attribution-table',
              includeAttributionStoreFilters: this.props.includeAttributionStoreFilters,
              timeFrame: this.props.timeframe,
              attributionModel: this.props.attributionModel,
            });
          }}
        >
          {getChannelNickname(channel) || channel}
        </div>
      </div>
    );
  }

  getNavigationMenuPopupsParams({
    valueToNavigate, segmentLabel, rowData, originalData,
  }) {
    const {
      widgetHeaderConfig = {}, relatedEntitiesIndication = {}, funnels, isInsightsTab, filters,
    } = this.props;
    const { selectedSegments, selectedStageKey } = this.state;
    const includeAttributionStoreFilters = isNil(widgetHeaderConfig) && !isInsightsTab;
    const firstSegment = selectedSegments.firstSegment.value;
    const secondSegment = selectedSegments.secondSegment.value;
    const itemToNavigationLabel = getItemLabelWithNoValue({ value: valueToNavigate, segment: firstSegment, segmentLabel });
    const navigations = [];

    if ([firstSegment, secondSegment].includes(contentURLSegmentValue)) {
      const urlLink = firstSegment === contentURLSegmentValue ? rowData.firstSegment : rowData.secondSegment;
      navigations.push({
        title: 'View content',
        subTitle: 'View this content asset on your website',
        navigationFunction: () => window.open(urlLink),
      });
    }

    if (!this.state.focusMode) {
      let updatedValueToNavigate = valueToNavigate;
      let updatedItemToNavigationLabel = itemToNavigationLabel;
      if (this.isContentSegmentsFirstURLSecondType) {
        updatedValueToNavigate = originalData.title;
        updatedItemToNavigationLabel = getItemLabelWithNoValue({ value: updatedValueToNavigate, segment: firstSegment, segmentLabel });
      }
      navigations.push({
        title: `Focus on ${updatedItemToNavigationLabel}`,
        subTitle: `Show only ${updatedItemToNavigationLabel} to uncover additional insights using secondary dimensions.`,
        navigationFunction: () => {
          this.setState({ focusMode: updatedValueToNavigate });
          this.props.innerPageNavigation({
            firstSegment,
            filters: getFiltersForShowOnlyRequest({
              firstSegment, value: updatedValueToNavigate, filters, isContentSegmentsFirstURLSecondType: this.isContentSegmentsFirstURLSecondType,
            }),
            isNewFiltersUIOnly: true,
          });
        },
      });
    }

    if (segmentLabel === 'Channel') {
      const subTitleCampaigns = `Analyze the campaigns driving ${getColumnMetricNickName({ metric: selectedStageKey })} from ${itemToNavigationLabel} over the selected time period`;
      const widgetFilters = widgetHeaderConfig.filters || [];
      navigations.push(
        {
          title: 'Breakdown by campaign',
          subTitle: subTitleCampaigns,
          navigationFunction: () => navigateBetweenAnalyzeTabs({
            filters: [...widgetFilters, makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [valueToNavigate], this.isCategoryView)],
            conversionIndicator: selectedStageKey,
            tabName: 'campaigns',
            anchor: 'attribution-table',
            includeAttributionStoreFilters,
            timeFrame: this.props.timeframe,
            attributionModel: this.props.attributionModel,
          }),
        }
      );
    }

    if (![webVisits, crossFunnel, pageViews].includes(selectedStageKey)) {
      const dateFormatForLabel = 'MM-DD-YY-hh-mm-ss';
      let queryParamValues = rowData.firstSegment;
      if (rowData.secondSegment) {
        queryParamValues = `${queryParamValues}.${rowData.secondSegment}`;
      }
      queryParamValues = `${queryParamValues}.start-${moment(this.props.timeframe.startDate).format(dateFormatForLabel)}.end-${moment(this.props.timeframe.endDate).format(dateFormatForLabel)}`;

      navigations.push({
        title: `Inspect ${getColumnMetricNickName({ metric: selectedStageKey })}: ${itemToNavigationLabel}`,
        subTitle: `See which ${getColumnMetricNickName({ metric: selectedStageKey })} make up the Touched/Attributed ${getColumnMetricNickName({ metric: selectedStageKey })} number.`,
        navigationFunction: () => {
          this.setState({
            isShowInspectPopup: true,
            inspectPopupData: {
              firstSegmentType: selectedSegments.firstSegment.value,
              secondSegmentType: selectedSegments.secondSegment.value,
              firstSegmentValue: rowData.firstSegment,
              secondSegmentValue: rowData.secondSegment,
              totals: {
                touched: rowData[camelCase(`touched ${selectedStageKey}`)],
                attributed: rowData[camelCase(`attributed ${selectedStageKey}`)],
              },
            },
          });
          setQueryParams({ queryParamKey: 'inspect', queryParamValues });
        },
        hasNewTag: true,
      });
    }

    if (firstSegment !== contentPathSegmentValue) {
      navigations.push({
        title: 'View journeys',
        subTitle: `Explore the journeys that were influenced by the ${itemToNavigationLabel} segment.`,
        navigationFunction: () => navigateBetweenAnalyzeTabs({
          filters: getFiltersForViewJourneysRequest({
            firstSegment,
            value: valueToNavigate,
            kpiFocus: selectedStageKey,
            widgetHeaderConfig,
            relatedEntitiesIndication,
            funnels,
          }),
          tabName: 'journeys',
          includeAttributionStoreFilters,
          timeFrame: this.props.timeframe,
          attributionModel: this.props.attributionModel,
        }),
      });
    }

    if (this.props.showCampaignPopup) {
      const isOtherCampaign = valueToNavigate.includes('- other');
      const campaignData = { ...rowData, name: valueToNavigate };
      if (!isOtherCampaign) {
        navigations.push({
          title: 'Map campaign',
          subTitle: 'Map this campaign\'s data with another campaign',
          navigationFunction: () => this.props.showCampaignPopup(campaignData),
        });
      }
    }

    return navigations;
  }

  updateExpandColumnsKeys = (key) => {
    this.setState((prevState) => {
      const updateExpandColumnsKeys = [...prevState.expendColumnsKeys];
      const ExpendColumnsKeyIndex = updateExpandColumnsKeys.indexOf(key);
      if (ExpendColumnsKeyIndex !== -1) {
        updateExpandColumnsKeys.splice(ExpendColumnsKeyIndex, 1);
      } else {
        updateExpandColumnsKeys.push(key);
      }
      return { expendColumnsKeys: updateExpandColumnsKeys };
    });
  };

  get isContentSegmentsFirstURLSecondType() {
    const { selectedSegments } = this.state;
    return selectedSegments.firstSegment?.value === contentURLSegmentValue && selectedSegments.secondSegment?.value === contentTypeSegmentValue;
  }

  getCellSkeleton() {
    return <Skeleton enableAnimation={this.props.isEnableSkeletonAnimation} {...skeletonSmallTableCellSizes} isLightTheme />;
  }

  setColumnByKey({ columnKey, stagesAmount, stagesAmountPrev }) {
    const {
      selectedStageKey, prevTableData, tableData, selectedSegments, expendColumnsKeys,
    } = this.state;
    const {
      isLoadedTrendData, dataTotals, hideNoValuesRows, isLoaded, isCompareToPreviousEnabled, isEnableNavigationMenu = true,
    } = this.props;

    const columnTitle = getColumnMetricNickName({ metric: columnKey });
    const isExpendOpen = expendColumnsKeys.includes(columnKey);
    const isChannelColumn = selectedSegments[columnKey]?.value === 'channel' || selectedSegments[columnKey]?.value === 'category';
    const isContentChannelColumn = selectedSegments[columnKey]?.value === contentTypeSegmentValue;

    const isCostColumns = ['LTV', 'pipeline', 'newPipeline', 'revenue', 'newBookings', 'MRR', 'ARR', 'touchedRevenue', 'touchedPipeline', 'cost', 'estimatedCost'].includes(columnKey);

    const isPgeViewsColumn = selectedStageKey === pageViews;
    const isWebVisitsColumn = selectedStageKey === webVisits;
    const columnsWithoutTrend = ['cost', isPgeViewsColumn || isWebVisitsColumn ? 'attributed' : null];

    const isGrowthDesired = getIsGrowthDesired({ metricName: columnKey });
    const cellSkeleton = this.getCellSkeleton();

    const newColumn = {
      id: columnKey,
      priority: 100,
      headerKey: columnTitle,
      inlineFiltersParams: {
        columnKey,
        selectedSegments,
        sortByColumn: this.state.sortByColumn,
        filtersByColumn: this.state.filtersByColumn,
        setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
        updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
        isNumeric: true,
      },
      header: columnTitle,
      accessor: columnKey,
      footer: Object.values(getFooterTotalsForKey({
        columnKey, indicator: this.props.getMetricRelevantFor({ metric: columnKey }) || selectedStageKey, data: tableData, prevData: prevTableData, hideNoValuesRows,
      })),
      getProps: () => ({
        style: {
          background: isExpendOpen ? '#F7F8FC' : '#FFFFFF',
        },
      }),
      cell: (item) => (item == null && !isLoaded ? cellSkeleton : item),
      sortMethod: (a, b) => sortColumnMethod(a, b),
    };

    if (isExpendOpen) {
      if (columnsWithoutTrend.includes(columnKey)) {
        newColumn.width = 340;
      } else {
        newColumn.width = 510;
      }
    }

    if (columnKey === 'cost') {
      newColumn.priority = 0;
    }

    if (columnKey === segmentsKeys.firstSegment || columnKey === segmentsKeys.secondSegment) {
      const columnFiltersOptions = getColumnOptionsByData({
        selectedSegments, tableData: this.getTableDataOfAllFunnels({ fromOriginal: true }), columnKey,
      });

      if (columnKey === segmentsKeys.firstSegment) {
        return {
          ...newColumn,
          fixed: 'left',
          headerKey: selectedSegments[columnKey].label,
          inlineFiltersParams: {
            columnKey,
            selectedSegments,
            sortByColumn: this.state.sortByColumn,
            filtersByColumn: this.state.filtersByColumn,
            setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
            updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
            columnFiltersOptions,
          },
          header: (
            <div className={this.classes.segmentTableFirstHeader}>
              {this.state.focusMode ? (
                <div onClick={() => this.exitFocusMode()} className={this.classes.backButton}>
                  {'< Back'}
                </div>
              ) : null}
              {selectedSegments[columnKey]?.label || <Skeleton {...skeletonSmallTextSizes} enableAnimation={this.props.isEnableSkeletonAnimation} isLightTheme />}
            </div>
          ),
          cell: (item, props) => {
            if (item == null && !isLoaded) {
              return (cellSkeleton);
            }
            let itemToNavigationLabel = getItemLabelWithNoValue({
              value: item, segment: selectedSegments[columnKey].value, segmentLabel: selectedSegments[columnKey].label,
            });

            let itemIcon = null;
            if (isChannelColumn) {
              itemIcon = this.isCategoryView ? getCategoryIcon(item) : getChannelIcon(item);
            }
            if (isContentChannelColumn) {
              itemIcon = getChannelIcon(item);
            }
            if (this.isContentSegmentsFirstURLSecondType) {
              itemToNavigationLabel = props.original?.title || itemToNavigationLabel;
            }
            return (
              <AttributionTableRowWithNavigationMenu
                getNavigationMenuPopupParams={(valueToNavigate) => this.getNavigationMenuPopupsParams({
                  valueToNavigate, segmentLabel: selectedSegments[columnKey].label, rowData: props.row, originalData: props.original,
                })}
                item={item}
                itemTitle={itemToNavigationLabel}
                itemIcon={itemIcon}
                iconClassName={this.isCategoryView ? this.classes.backgroundSizeToAuto : null}
                isEnableNavigationMenu={isEnableNavigationMenu}
                shouldOpenNavigationMenuPopup={this.segmentToOpenWhatsNextPopup === itemToNavigationLabel}
              />
            );
          },
          footer: 'Total',
          minWidth: 200,
        };
      }

      if (columnKey === segmentsKeys.secondSegment) {
        return {
          ...newColumn,
          fixed: 'left',
          headerKey: selectedSegments[columnKey].label,
          inlineFiltersParams: {
            columnKey,
            selectedSegments,
            sortByColumn: this.state.sortByColumn,
            filtersByColumn: this.state.filtersByColumn,
            setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
            updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
            columnFiltersOptions,
          },
          header: selectedSegments[columnKey].label,
          cell: (item) => {
            if (item == null && !isLoaded) {
              return (cellSkeleton);
            }
            const segmentTypeLabel = selectedSegments[columnKey].label;

            let itemLabel = getItemLabelWithNoValue({
              value: item, segment: selectedSegments[columnKey].value, segmentLabel: selectedSegments[columnKey].label,
            });
            if (item === 'No Value') {
              itemLabel = `No ${segmentTypeLabel}`;
            }

            if (isChannelColumn) {
              return (
                this.getChannelColumn({ channel: item, isCategoryViewByColumn: this.state.selectedSegments.secondSegment?.value === 'category' })
              );
            }
            return (
              <>
                {isContentChannelColumn && (
                  <ChannelIcon
                    channelIcon={getChannelIcon(item)}
                    channel={item}
                  />
                )}
                <EllipsisTooltip withPortal text={itemLabel} />
              </>
            );
          },
          footer: '',
        };
      }
    }

    if (columnKey.toLowerCase().includes('conversionrate')) {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const trendData = this.props.trendData?.[metricRelevantFor]?.segments;
      const trendDataTotals = this.props.trendData?.[metricRelevantFor]?.totals;
      const metricAverage = dataTotals[columnKey];
      const showShareOfTotal = !metricWithoutShareOfTotal.includes('ConversionRate');

      return {
        ...newColumn,
        headerKey: columnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen}>
            {columnTitle}
          </HeaderExpendColumn>
        ),
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original[camelCase(`growthPerMetric ${metricRelevantFor}`)],
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData,
              columnKey,
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });

            return (
              <ExpendCell
                itemValue={item || 0}
                itemValueFormatter={conversionRateFormatter}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                showShareOfTotal={showShareOfTotal}
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                isLoaded={isLoaded || item}
              />
            );
          }

          return (item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {conversionRateFormatter(item)}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => conversionRateFormatter(value)}
                showShareOfTotal={showShareOfTotal}
              />
            );
          }
          return (metricAverage == null && !isLoaded) ? (cellSkeleton) : conversionRateFormatter(metricAverage);
        },
        priority: 4,
      };
    }

    if (columnKey.includes('Velocity')) {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const trendData = this.props.trendData?.[metricRelevantFor]?.segments;
      const trendDataTotals = this.props.trendData?.[metricRelevantFor]?.totals;
      const metricAverage = dataTotals[columnKey];
      const showShareOfTotal = !metricWithoutShareOfTotal.includes('Velocity');

      return {
        ...newColumn,
        headerKey: columnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen}>
            {columnTitle}
          </HeaderExpendColumn>
        ),
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original[camelCase(`growthPerMetric ${metricRelevantFor}`)],
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData,
              columnKey,
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });

            return (
              <ExpendCell
                itemValue={item}
                itemValueFormatter={(value) => `${value} ${getDaysPluralName(value)}`}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                isLowerBetter
                showShareOfTotal={showShareOfTotal}
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                isLoaded={isLoaded || item}
              />
            );
          }

          return (item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {item != null ? `${item.toLocaleString()} ${getDaysPluralName(item)}` : '-'}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => (`${formatNumberWithDecimalPoint(value)} ${getDaysPluralName(value)}`)}
                showShareOfTotal={showShareOfTotal}
              />
            );
          }
          return (metricAverage == null && !isLoaded) ? (cellSkeleton) : `${formatNumberWithDecimalPoint(metricAverage)} ${getDaysPluralName(metricAverage)}`;
        },
        priority: 3,
      };
    }

    if (columnKey === 'averageSalesCycle') {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const trendData = this.props.trendData?.[metricRelevantFor]?.segments;
      const trendDataTotals = this.props.trendData?.[metricRelevantFor]?.totals;
      const metricAverage = dataTotals[columnKey];
      const showShareOfTotal = !metricWithoutShareOfTotal.includes('averageSalesCycle');

      return {
        ...newColumn,
        headerKey: columnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen}>
            {columnTitle}
          </HeaderExpendColumn>
        ),
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original[camelCase(`growthPerMetric ${metricRelevantFor}`)],
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData,
              columnKey,
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });

            return (
              <ExpendCell
                itemValue={item}
                itemValueFormatter={(value) => `${value} ${getDaysPluralName(value)}`}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                showShareOfTotal={showShareOfTotal}
                isLowerBetter
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                isLoaded={isLoaded || item}
              />
            );
          }
          return (item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {item != null ? `${item} ${getDaysPluralName(item)}` : '-'}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => (`${value} ${getDaysPluralName(value)}`)}
                showShareOfTotal={showShareOfTotal}
              />
            );
          }
          return (metricAverage == null && !isLoaded) ? (cellSkeleton) : `${metricAverage} ${getDaysPluralName(metricAverage)}`;
        },
      };
    }

    if (columnKey === 'ROI') {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const totalRevenue = getFooterTotalsForKey({
        columnKey: 'revenue', indicator: metricRelevantFor || selectedStageKey, data: tableData, prevData: prevTableData, hideNoValuesRows,
      });
      const totalCost = getFooterTotalsForKey({
        columnKey: 'cost', indicator: metricRelevantFor || selectedStageKey, data: tableData, prevData: prevTableData, hideNoValuesRows,
      });
      return {
        ...newColumn,
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original[camelCase(`growthPerMetric ${metricRelevantFor}`)],
          });
          return ((item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {numberWithZeroDefault(roiFormatter)(item)}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          ));
        },
        footerFormatter: roiFormatter,
        footer: ((totalRevenue.total == null || totalCost.total == null) && !isLoaded) ? (cellSkeleton) : [totalRevenue.total / totalCost.total, totalRevenue.prevTotal / totalCost.prevTotal],
      };
    }

    if (columnKey === 'pipelineROI') {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const totalPipeline = getFooterTotalsForKey({
        columnKey: 'pipeline', indicator: metricRelevantFor || selectedStageKey, data: tableData, prevData: prevTableData, hideNoValuesRows,
      });
      const totalCost = getFooterTotalsForKey({
        columnKey: 'cost', indicator: metricRelevantFor || selectedStageKey, data: tableData, prevData: prevTableData, hideNoValuesRows,
      });

      return {
        ...newColumn,
        headerKey: columnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
        },
        header: columnTitle,
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original[camelCase(`growthPerMetric ${metricRelevantFor}`)],
          });

          return ((item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {numberWithZeroDefault(roiFormatter)(item)}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          ));
        },
        footerFormatter: roiFormatter,
        footer: ((totalPipeline.total == null || totalCost.total == null) && !isLoaded) ? (cellSkeleton) : [totalPipeline.total / totalCost.total, totalPipeline.prevTotal / totalCost.prevTotal],
      };
    }

    if (columnKey === 'ARPA') {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const trendData = this.props.trendData?.[metricRelevantFor]?.segments;
      const trendDataTotals = this.props.trendData?.[metricRelevantFor]?.totals;
      const metricAverage = dataTotals[columnKey];
      const showShareOfTotal = !metricWithoutShareOfTotal.includes('ARPA');

      return {
        ...newColumn,
        headerKey: columnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen}>
            {columnTitle}
          </HeaderExpendColumn>
        ),
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original[camelCase(`growthPerMetric ${metricRelevantFor}`)],
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData,
              columnKey,
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });

            return (
              <ExpendCell
                itemValue={item}
                itemValueFormatter={(value) => numberWithZeroDefault(averageFormatter)(value)}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                showShareOfTotal={showShareOfTotal}
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                isLoaded={isLoaded || item}
              />
            );
          }

          return (item == null && !isLoaded) ? (
            cellSkeleton
          ) : (
            <>
              <span>
                {numberWithZeroDefault(averageFormatter)(item)}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => numberWithZeroDefault(averageFormatter)(value)}
                showShareOfTotal={showShareOfTotal}
              />
            );
          }
          return (metricAverage == null && !isLoaded) ? (cellSkeleton) : numberWithZeroDefault(averageFormatter)(metricAverage);
        },
      };
    }

    if (columnKey.startsWith('costPer')) {
      let currentFunnelStage = columnKey.replace('costPer', '');
      currentFunnelStage = currentFunnelStage.charAt(0).toLowerCase() + currentFunnelStage.slice(1);
      const funnelsStagesName = getColumnMetricNickName({ metric: currentFunnelStage, isSingular: true });
      const totalCost = getFooterTotalsForKey({
        columnKey: 'cost', indicator: currentFunnelStage, data: tableData, prevData: prevTableData, hideNoValuesRows,
      });

      const efficiencyColumnTitle = `Cost per ${funnelsStagesName}` || columnKey;
      return {
        ...newColumn,
        headerKey: efficiencyColumnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
        },
        header: efficiencyColumnTitle,
        accessor: (item) => {
          const attributedValue = this.isCrossFunnelView ? item[columnKey] : item[camelCase(`attributed ${currentFunnelStage}`)];
          const sum = item.cost || 0;
          if (sum !== 0 && attributedValue !== 0) {
            const costPerValue = sum / attributedValue;
            return costPerValue;
          }
          return null;
        },
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: 'efficiency',
            growthPerMetric: props.original[camelCase(`growthPerMetric ${currentFunnelStage}`)],
          });
          if ((item == null) && !isLoaded) {
            return (
              cellSkeleton
            );
          }

          return (
            <>
              <span>
                {this.isCrossFunnelView ? averageFormatter(item) : this.getEfficiencyColumn(item, funnelsStagesName)}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (stagesAmount[currentFunnelStage] == null && !isLoaded) {
            return (cellSkeleton);
          }
          const currentEfficiency = stagesAmount[currentFunnelStage] > 0 ? (totalCost.total / stagesAmount[currentFunnelStage]) : 0;
          const prevEfficiency = stagesAmountPrev[currentFunnelStage] && (totalCost.prevTotal / stagesAmountPrev[currentFunnelStage]);
          return (
            <div className={this.classes.flexRow}>
              {this.getEfficiencyColumn(currentEfficiency, funnelsStagesName)}
              <TrendMark number={currentEfficiency} prevNumber={prevEfficiency} isLowerBetter />
            </div>
          );
        },
        footerFormatter: formatBudget,
      };
    }

    if (columnKey.includes('attributed')) {
      let currentFunnelStage = columnKey.replace('attributed', '');
      currentFunnelStage = currentFunnelStage.charAt(0).toLowerCase() + currentFunnelStage.slice(1);
      const [metricTotal] = Object.values(getFooterTotalsForKey({
        columnKey, indicator: currentFunnelStage, data: tableData, prevData: prevTableData, hideNoValuesRows,
      }));
      const metricAverage = calculateAverage(tableData[currentFunnelStage], 'attributed');
      const trendDataTotals = this.props.trendData?.[currentFunnelStage]?.totals;
      let currentTrendData = this.props.trendData?.[currentFunnelStage]?.segments;

      if (this.isCrossFunnelView) {
        currentTrendData = this.props.trendData?.[currentFunnelStage]?.segments;
      }
      const updatedColumnTitle = columnTitle;

      return {
        ...newColumn,
        headerKey: updatedColumnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen}>
            {updatedColumnTitle}
          </HeaderExpendColumn>
        ),
        priority: 2,
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: 'attributed',
            growthPerMetric: props.original[camelCase(`growthPerMetric ${currentFunnelStage}`)],
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData: currentTrendData,
              columnKey: 'attributed',
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });

            return (
              <ExpendCell
                itemValue={item || 0}
                shareOfTotal={metricTotal}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                withTrend={currentFunnelStage !== webVisits && currentFunnelStage !== pageViews}
                isLoaded={isLoaded || item}
              />
            );
          }
          return (item == null && !isLoaded) ? (
            cellSkeleton
          ) : (
            <>
              <span>
                {item?.toLocaleString() || 0}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                total={metricTotal}
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => formatNumberWithDecimalPoint(value, 1)}
              />
            );
          }
          return (metricTotal == null && !isLoaded) ? (cellSkeleton) : formatNumberWithDecimalPoint(metricTotal, 1);
        },
      };
    }

    if (columnKey.includes('touched') && columnKey !== 'touchedRevenue' && columnKey !== 'touchedPipeline') {
      let currentFunnelStage = columnKey.replace('touched', '');
      const trendData = this.props.trendData?.[currentFunnelStage]?.segments;
      const trendDataTotals = this.props.trendData?.[currentFunnelStage]?.totals;
      currentFunnelStage = currentFunnelStage.charAt(0).toLowerCase() + currentFunnelStage.slice(1);
      const [metricTotal] = Object.values(getFooterTotalsForKey({
        columnKey, indicator: currentFunnelStage, data: tableData, prevData: prevTableData, hideNoValuesRows,
      }));
      const metricAverage = calculateAverage(tableData[currentFunnelStage], 'touched');

      const touchedColumnTitle = columnKey === 'touched' ? `${columnTitle} ${getColumnMetricNickName({ metric: currentFunnelStage })}` : columnTitle;
      return {
        ...newColumn,
        headerKey: touchedColumnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen}>
            {touchedColumnTitle}
          </HeaderExpendColumn>
        ),
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: 'touched',
            growthPerMetric: props.original[camelCase(`growthPerMetric ${currentFunnelStage}`)],
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData,
              columnKey,
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });
            return (
              <ExpendCell
                itemValue={item || 0}
                shareOfTotal={metricTotal}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                isLoaded={isLoaded || item}
              />
            );
          }

          return (item == null && !isLoaded) ? (
            cellSkeleton
          ) : (
            <>
              <span>
                {item?.toLocaleString() || 0}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                total={metricTotal}
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => formatNumberWithDecimalPoint(value, 1)}
                showTotal={false}
              />
            );
          }
          return '-';
        },
        priority: 1,
      };
    }

    if (['revenue', 'pipeline', 'touchedRevenue', 'touchedPipeline', 'cost', 'MRR', 'ARR'].includes(columnKey)) {
      const metricRelevantFor = this.props.getMetricRelevantFor({ metric: columnKey });
      const [metricTotal] = Object.values(getFooterTotalsForKey({
        columnKey, indicator: metricRelevantFor || selectedStageKey, data: tableData, prevData: prevTableData, hideNoValuesRows,
      }));
      const metricAverage = calculateAverage(tableData[selectedStageKey], columnKey);
      const showShareOfTotal = !metricWithoutShareOfTotal.includes(columnKey);

      const trendData = this.props.trendData?.[metricRelevantFor || selectedStageKey]?.segments;
      const trendDataTotals = this.props.trendData?.[metricRelevantFor || selectedStageKey]?.totals;

      return {
        ...newColumn,
        headerKey: columnTitle,
        inlineFiltersParams: {
          isNumeric: true,
          columnKey,
          isExpendOpen,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters }) => this.updateFiltersByColumn({ filters }),
          updateExpandColumnsKeys: this.props.isCalculateAdvancedMetrics ? this.updateExpandColumnsKeys : null,
        },
        header: (
          <HeaderExpendColumn isExpendOpen={isExpendOpen} withTrend={!columnsWithoutTrend.includes(columnKey)}>
            {columnTitle}
          </HeaderExpendColumn>
        ),
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original.growthPerMetric,
          });

          if (isExpendOpen) {
            const trendValues = getTrendValuesForCell({
              trendData,
              columnKey,
              firstSegment: props.row.firstSegment,
              secondSegment: props.row.secondSegment,
            });
            return (
              <ExpendCell
                itemValue={item || 0}
                itemValueFormatter={formatBudget}
                shareOfTotal={metricTotal}
                average={metricAverage}
                trendValues={trendValues}
                isLoadedTrendData={isLoadedTrendData}
                withTrend={!columnsWithoutTrend.includes(columnKey)}
                showShareOfTotal={showShareOfTotal}
                growthValue={growthValue}
                isGrowthDesired={isGrowthDesired}
                isLoaded={isLoaded || item}
              />
            );
          }

          return (item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {formatBudget(item) || 0}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          );
        },
        footer: () => {
          if (isExpendOpen) {
            return (
              <FooterExpendCell
                total={metricTotal}
                average={metricAverage}
                trendAverage={trendDataTotals?.[columnKey]}
                formatter={(value) => formatBudget(value)}
                showShareOfTotal={showShareOfTotal}
              />
            );
          }
          return (metricTotal == null && !isLoaded) ? (cellSkeleton) : formatBudget(metricTotal);
        },
      };
    }

    if (isCostColumns) {
      return {
        ...newColumn,
        cell: (item, props) => {
          const growthValue = getGrowthValue({
            metricName: columnKey,
            growthPerMetric: props.original.growthPerMetric,
          });

          return ((item == null && !isLoaded) ? (cellSkeleton) : (
            <>
              <span>
                {formatBudget(item || 0)}
              </span>
              {isCompareToPreviousEnabled ? (
                growthValue === null && !isLoaded ? (
                  <Loading3Dots />
                ) : (
                  <ComparisonValue
                    value={growthValue}
                    isGrowthDesired={isGrowthDesired}
                  />
                )
              ) : null}
            </>
          ));
        },
        footerFormatter: formatBudget,
      };
    }

    return newColumn;
  }

  updateStageKey = (key, callback) => {
    this.setState(() => {
      let id = camelCase(`attributed ${key}`);

      if (key === crossFunnel) {
        id = 'attributedFunnel1';
      }
      const newSortByColumn = { id, desc: true };

      return {
        selectedStageKey: key,
        sortByColumn: newSortByColumn,
      };
    }, callback);

    if (this.props.updateDefaultStageKey) {
      this.props.updateDefaultStageKey({ stageKey: key });
    }
  };

  async setSelectedSegments({ segments }) {
    const segmentsForRequest = parseSegmentsForRequest(segments);
    await this.props.updateSegmentsAnalysisParam(segmentsForRequest, null, [widgetsConfig.segmentsAnalysis.type]);
    this.setState({ selectedSegments: segments });
  }

  exitFocusMode() {
    const rawFilters = getFiltersWithoutFocusMode({ filters: this.props.filters, focusKey: this.state.focusMode });
    this.props.innerPageNavigation({
      filters: rawFilters,
      isNewFiltersUIOnly: true,
    });
    this.setState({ focusMode: null });
  }

  get isCrossFunnelView() {
    return this.state.selectedStageKey === crossFunnel;
  }

  get isEfficiency() {
    return this.state.crossFunnelToggle === CROSS_FUNNEL_TOGGLE.EFFICIENCY;
  }

  get isShowCampaignsChannelsColumn() {
    const isCampaignFirstSegment = this.state.selectedSegments.firstSegment.value === 'campaign';
    const isChannelSecondSegment = this.state.selectedSegments.secondSegment.value === 'channel';
    return isCampaignFirstSegment && !isChannelSecondSegment;
  }

  setToggle = (newVal, callback) => this.setState({ crossFunnelToggle: newVal }, callback);

  getDrillDownResultKeyName({ entityId = this.state.drillDownPopupEntityId }) {
    const { inspectPopupData, selectedStageKey } = this.state;
    const firstSegmentType = inspectPopupData.firstSegmentType;
    const secondSegmentType = inspectPopupData.secondSegmentType;
    const firstSegmentSelectedValue = inspectPopupData.firstSegmentValue;
    const secondSegmentSelectedValue = inspectPopupData.secondSegmentValue;

    return `creditBySession-${entityId}-${firstSegmentType}-${secondSegmentType}-${firstSegmentSelectedValue}-${secondSegmentSelectedValue}-${selectedStageKey}`;
  }

  getDrillDownData({ entityId, title }) {
    const { inspectPopupData } = this.state;
    const { timeframe: timeFrame, attributionModel, filters } = this.props;
    this.setState({ drillDownPopupEntityId: entityId, isShowInspectPopup: false, drillDownPopupTitle: title });
    const firstSegment = inspectPopupData.firstSegmentType;
    const secondSegment = inspectPopupData.secondSegmentType;

    const resultKeyName = this.getDrillDownResultKeyName({ entityId });
    getWidgetsData({
      widgets: [widgetTypes.creditBySessions],
      configPerWidget: {
        [widgetTypes.creditBySessions]: [{
          entityId,
          firstSegment,
          secondSegment,
          selectedFunnel: this.state.selectedStageKey,
          timeFrame,
          attributionModel,
          filters,
        }],
      },
      resultKeyName,
    });
  }

  updateFiltersByColumn({ filters: filtersByColumn }) {
    this.setState((prevState) => {
      const updateDataTable = getUpdateFilteredTableData({
        data: prevState.originalTableData,
        segment: prevState.firstSegment?.value,
        searchValue: prevState.searchTableValues?.value || '',
        searchColumn: prevState.searchTableValues?.searchColumn,
        filtersByColumn,
        stageAmountKeys: this.stageAmountKeys,
        isOtherCampaignsHidden: this.props.isOtherCampaignsHidden,
      });
      if (this.props.updateWidgetInlineFiltersByColumn) {
        this.props.updateWidgetInlineFiltersByColumn({ filtersBy: filtersByColumn });
      }
      return { tableData: updateDataTable, filtersByColumn };
    });
  }

  onSearchData({
    searchColumn, segment, value = '', data,
  }) {
    this.setState((prevState) => {
      const updateDataTable = getUpdateFilteredTableData({
        data: data || prevState.originalTableData,
        segment,
        searchValue: value,
        searchColumn,
        filtersByColumn: prevState.filtersByColumn,
        stageAmountKeys: this.stageAmountKeys,
        isOtherCampaignsHidden: this.props.isOtherCampaignsHidden,
      });
      return { searchTableValues: { searchColumn, value }, tableData: updateDataTable };
    });
  }

  onSortByColumn({ sortByColumn }) {
    if (this.props.updateWidgetInlineFiltersByColumn) {
      this.props.updateWidgetInlineFiltersByColumn({ sortBy: sortByColumn });
    }
    this.setState({ sortByColumn });
  }

  get stageAmountKeys() {
    return getDefaultStageSelectorData({ firstSegment: this.state.selectedSegments.firstSegment?.value }).map((stageSelector) => stageSelector.key);
  }

  getTableDataOfAllFunnels({ fromOriginal }) {
    if (fromOriginal) {
      return this.props.mergedFunnelsTableData;
    }
    return mergeAllFunnelsTableData({ tableDataByFunnel: this.state.tableData, selectedStageKey: this.state.selectedStageKey });
  }

  render() {
    const {
      selectedStageKey,
      selectedSegments,
      crossFunnelToggle,
      drillDownPopupEntityId,
      isShowInspectPopup,
      inspectPopupData,
      drillDownPopupTitle,
      tableData,
    } = this.state;
    const {
      prevData,
      segmentsOptionsFields,
      setColumnsFooter,
      formatStageNumber,
      creditBySessions,
      getUserDynamicMetricsPerFunnel,
      userFunnels,
      isAttribution,
      filters,
      isLoaded,
    } = this.props;
    const {
      growthPerStage,
    } = tableData;

    const isHavingUIFilter = filters.some((filter) => filter.isUiOnly);
    const isSearching = this.state.searchTableValues?.value?.length > 0;
    const hasInlineFilters = this.state.filtersByColumn && Object.keys(this.state.filtersByColumn).length > 0;
    const isUIFilterOrSearch = isHavingUIFilter || isSearching || hasInlineFilters || this.props.isOtherCampaignsHidden;

    const stagesAmount = isUIFilterOrSearch ? {} : this.props.stagesAmount;
    const stagesAmountPrev = getStagesAmountsForSegments({ data: prevData });

    let updatedTableData = this.getTableDataOfAllFunnels({ fromOriginal: !(isSearching || hasInlineFilters) }) || [];

    if (this.props.hideNoValuesRows) {
      updatedTableData = tableDataWithoutNoValue({ tableData: updatedTableData });
    }

    if (this.props.isOtherCampaignsHidden) {
      updatedTableData = tableDataWithoutOtherCampaigns({ tableData: updatedTableData });
    }

    const userDynamicMetricsPerFunnel = getUserDynamicMetricsPerFunnel();
    const allColumnOptions = getAllColumnOptionsAndGroupedOptions({ isAttribution, selectedSegments }).allColumnOptions.map((column) => column.value);
    const pipelineFunnel = getPipelineFunnel();
    const revenueFunnel = getRevenueFunnel();
    allColumnOptions.push('touchedPipeline', 'touchedRevenue');

    const allDataKeys = getTableColumnKeys({
      customColumnsKeys: allColumnOptions,
      selectedStageKey,
      firstSegmentValue: selectedSegments.firstSegment?.value,
      secondSegmentValue: selectedSegments.secondSegment?.value,
      userDynamicMetricsPerFunnel,
      pipelineFunnel,
      revenueFunnel,
      userFunnels,
      isEfficiency: this.isEfficiency,
      isCrossFunnelView: this.isCrossFunnelView,
    });

    if (isUIFilterOrSearch && isLoaded) {
      stagesAmount[selectedStageKey] = sumBy(tableData[selectedStageKey], (dataRow) => dataRow.attributed);
      for (const stageKey of this.stageAmountKeys) {
        if (stageKey === selectedStageKey) {
          continue;
        }
        stagesAmount[stageKey] = sumBy(tableData[stageKey], (dataRow) => dataRow.attributed);
      }
    }

    const stagesData = Object.keys(stagesAmount).map((stage) => {
      let stageGrowth = growthPerStage?.[stage];
      if (stageGrowth === null) {
        stageGrowth = 'Infinity';
      }

      return {
        key: stage,
        name: getColumnMetricNickName({ metric: stage }),
        number: formatStageNumber(stagesAmount, stage),
        prevNumber: formatStageNumber(stagesAmountPrev, stage),
        stageGrowth: stageGrowth ?? null,
      };
    });

    const tableColumnsByStage = allDataKeys.map((key) => this.setColumnByKey({
      columnKey: key, stagesAmount, stagesAmountPrev,
    }));

    let tableColumns = setColumnsFooter(updatedTableData, tableColumnsByStage);
    if (!selectedSegments.secondSegment?.value) {
      tableColumns = tableColumns.filter((item) => item.id !== segmentsKeys.secondSegment);
    }

    const AttributionTable = this.props.attributionTable;
    const drillDownData = creditBySessions?.[this.getDrillDownResultKeyName({})]?.data ?? {};
    const isDrillDownDataLoaded = creditBySessions?.[this.getDrillDownResultKeyName({})]?.isLoaded;

    if (this.isShowCampaignsChannelsColumn) {
      const columnKey = 'channels';
      const columnFiltersOptions = getChannelsColumnOptionsByData({ tableData: this.getTableDataOfAllFunnels({ fromOriginal: true }), columnKey });
      const channelsColumn = {
        id: columnKey,
        header: 'Channels',
        accessor: columnKey,
        inlineFiltersParams: {
          columnKey,
          selectedSegments,
          sortByColumn: this.state.sortByColumn,
          filtersByColumn: this.state.filtersByColumn,
          setSortByColumn: (sortByColumn) => this.onSortByColumn({ sortByColumn }),
          updateFiltersByColumn: ({ filters: channelFilters }) => this.updateFiltersByColumn({ filters: channelFilters }),
          columnFiltersOptions,
          isShowSortBy: false,
        },
        cell: (channels, props) => (channels == null && !isLoaded ? this.getCellSkeleton() : (
          <div className={this.classes.cellWithIcon}>
            {channels ? (
              <ChannelList
                channels={channels}
                width={props.width - 40}
              />
            ) : null}
          </div>
        )),
        footer: '',
        priority: 0,
      };
      tableColumns.push(channelsColumn);
    }
    tableColumns = orderBy(tableColumns, ['priority', 'id'], ['asc']);
    const allTableColumnsIds = tableColumns.map((c) => c.id);

    if (updatedTableData.length === 0 && !isLoaded) {
      updatedTableData = Array(skeletonTableRows).fill({
        firstSegment: null, secondSegment: null, attributed: null, skeletonRow: true,
      });
    }

    return (
      <>
        <AttributionTable
          {...this.props}
          selectedStageKey={selectedStageKey}
          updateStageKey={this.updateStageKey}
          allColumns={allTableColumnsIds}
          stagesData={stagesData}
          tableColumns={tableColumns}
          tableData={updatedTableData}
          segmentsFields={segmentsOptionsFields}
          selectedSegments={selectedSegments}
          setSelectedSegments={(segments) => this.setSelectedSegments({ segments })}
          TableProps={{
            showPagination: true,
            infiniteScroll: true,
            infiniteScrollSelector: 'div > div.rt-table > div.rt-tbody',
          }}
          disableUpdateSegmentsAnalysisParam={!this.props.updateSegmentsAnalysisParam}
          widgetHeaderConfigType={widgetTypes.segmentsAnalysis}
          setToggle={this.setToggle}
          toggleValue={crossFunnelToggle}
          isCrossFunnelView={this.isCrossFunnelView}
          defaultMinWidth={180}
          onSearchData={(searchColumn, value) => this.onSearchData({
            searchColumn, segment: this.state.selectedSegments.firstSegment.value, value,
          })}
          searchBar={{
            placeholder: 'Search...',
            searchColumn: this.isContentSegmentsFirstURLSecondType ? 'title' : segmentsKeys.firstSegment,
          }}
          isUIFilterOrSearch={isUIFilterOrSearch}
          sortByColumn={this.state.sortByColumn}
          filtersByColumn={this.state.filtersByColumn}
        />
        {isShowInspectPopup && (
          <Inspect
            inspectMetricData={inspectPopupData}
            setIsShowInspectPopup={(isShowPopup) => this.setState({ isShowInspectPopup: isShowPopup })}
            kpiFocus={selectedStageKey}
            timeFrame={this.props.timeframe}
            filters={this.props.filters}
            attributionModel={this.props.attributionModel}
            setDrillDownPopupEntityId={(entityId, title) => this.getDrillDownData({ entityId, title })}
          />
        )}
        {drillDownPopupEntityId && (
          <DrillDownPopup
            onClose={() => this.setState({ drillDownPopupEntityId: null })}
            entityId={drillDownPopupEntityId}
            drillDownData={drillDownData}
            isLoaded={isDrillDownDataLoaded}
            onGoBack={() => this.setState({ isShowInspectPopup: true, drillDownPopupEntityId: null })}
            title={drillDownPopupTitle}
            segments={{
              firstSegmentValue: inspectPopupData.firstSegmentValue,
              firstSegment: inspectPopupData.firstSegmentType,
              secondSegmentValue: inspectPopupData.secondSegmentValue,
              secondSegment: inspectPopupData.secondSegmentType,
            }}
          />
        )}
      </>
    );
  }
};

export default enhance(AttributionSegmentsTableParseData);
