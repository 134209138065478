import React, { useState } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import serverCommunication from 'data/serverCommunication';
import Tooltip from 'components/controls/Tooltip';
import Textfield from 'components/controls/Textfield';
import Dropdown from 'components/controls/Dropdown';
import SegmentBucket from 'components/pages/settings/customSegments/SegmentBucket';
import { ruleOperators } from 'components/pages/settings/customSegments/enums';
import Spinner from 'components/pages/journeys/Spinner';
import SaveButton from 'components/pages/profile/SaveButton';
import servicesStore from 'stores/servicesStore';

import { getMappingOptions } from 'components/utils/logic/utils';
import { getOverlappingGroupIndex } from 'components/pages/settings/customSegments/logic/SegmentBucket';
import { segmentsGroupByCommonSegments } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { checkIfAllPropertiesAreFilled, getAllFilledGroupsRules } from 'components/pages/settings/customSegments/logic/customSegmentsProperties';

import { Events } from 'trackers/analytics/enums';

import style from 'styles/settings/custom-segments/custom-segments.css';
import channelsTabStyle from 'styles/settings/channels/channels-tab.css';

const styles = style.locals || {};
const channelsTabStyles = channelsTabStyle.locals || {};

function CustomSegmentsProperties({
  UID,
  customSegmentsData,
  selectedSegmentAndGroupIds,
  customFieldsIdToLabelMap,
  setCustomSegmentsData,
  setSelectedSegmentAndGroupIds,
  updateUserAccountByKey,
}) {
  useStyles([style, channelsTabStyle]);

  const [isLoading, setIsLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState({ success: false, fail: false });
  const [errors, setErrors] = useState(null);

  if (!selectedSegmentAndGroupIds.segmentId) {
    return (
      <div className={classnames(channelsTabStyles.inner, styles.noSegmentSelected)}>
        Select a segment
      </div>
    );
  }

  function checkOverlappingRules({ groups }) {
    const allFilledGroupsRules = getAllFilledGroupsRules({ groups });
    const overlappingResult = getOverlappingGroupIndex({ groups: allFilledGroupsRules });
    setErrors(overlappingResult);
  }

  function onChangeBucketConfig({
    newConfig, groupIndex,
  }) {
    const updatedCustomSegmentsData = { ...customSegmentsData };
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].groups[groupIndex] = newConfig;
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].isChanged = true;
    setCustomSegmentsData(updatedCustomSegmentsData);

    checkOverlappingRules({ groups: updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].groups });
  }

  function onDeactivateBucket({ groupId }) {
    const updatedCustomSegmentsData = { ...customSegmentsData };
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].groups = updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].groups.filter((group) => group.id !== groupId);
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].isChanged = true;
    setCustomSegmentsData(updatedCustomSegmentsData);

    checkOverlappingRules({ groups: updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].groups });
  }

  function onChangeSegmentConfig({ type, value }) {
    const updatedCustomSegmentsData = { ...customSegmentsData };
    const previousValue = updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId][type];
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId][type] = value;
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].isChanged = true;
    const fieldChangedIsCrmField = type === 'crmFieldId';
    const valueHasChanged = previousValue !== value;
    const shouldResetValueOnCrmFields = fieldChangedIsCrmField && valueHasChanged;
    if (shouldResetValueOnCrmFields) {
      resetAllRulesWithEqualsOperatorInTheSegmentGroup();
    }
    setCustomSegmentsData(updatedCustomSegmentsData);
  }

  function addNewBucket() {
    const updatedCustomSegmentsData = { ...customSegmentsData };
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].groups.push({
      id: uuidv4(),
      name: '',
      rules: [
        [
          {
            operation: '',
            value: '',
            id: uuidv4(),
          },
        ],
      ],
    });
    setCustomSegmentsData(updatedCustomSegmentsData);
    trackBucketCreated();
  }

  async function onSaveSegment() {
    setIsLoading(true);
    const updatedCustomSegmentsData = { ...customSegmentsData };
    updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId].isChanged = false;
    setCustomSegmentsData(updatedCustomSegmentsData);

    const customSegmentForRequest = cloneDeep(customSegmentsData[selectedSegmentAndGroupIds.segmentId]);
    delete customSegmentForRequest.id;
    delete customSegmentForRequest.isNewSegment;
    delete customSegmentForRequest.isChanged;

    await updateCustomSegmentData({
      id: selectedSegmentAndGroupIds.segmentId,
      customSegment: customSegmentForRequest,
    });
    setIsLoading(false);

    trackSegmentSaved();
    customSegmentsData[selectedSegmentAndGroupIds.segmentId].isNewSegment = false;
  }

  async function updateCustomSegmentData({ id, customSegment }) {
    try {
      const response = await serverCommunication.serverRequest('PUT', 'customSegments', JSON.stringify({ id, customSegment }));
      const responseData = await response.json();
      setSaveStatus({ success: true, fail: false });
      updateUserAccountByKey({ key: 'customFieldsIdToLabelMap', value: responseData.value?.customFieldsIdToLabelMap });
      updateUserAccountByKey({ key: 'customSegmentsMapping', value: responseData.value?.customSegmentsMapping });
    } catch (error) {
      servicesStore.logger.error('failed to update customSegment', {
        error,
        UID,
      });
      setSaveStatus({ success: false, fail: true });
    }
  }

  async function onDeleteSegment() {
    setIsLoading(true);
    if (!customSegmentsData[selectedSegmentAndGroupIds.segmentId].isNewSegment) {
      await deleteCustomSegmentData({ id: selectedSegmentAndGroupIds.segmentId });
    }
    setSelectedSegmentAndGroupIds({ segmentId: null, groupId: null });
    const updatedCustomSegmentsData = { ...customSegmentsData };
    delete updatedCustomSegmentsData[selectedSegmentAndGroupIds.segmentId];
    setCustomSegmentsData(updatedCustomSegmentsData);
    setIsLoading(false);
  }

  async function deleteCustomSegmentData({ id }) {
    try {
      const response = await serverCommunication.serverRequest('DELETE', 'customSegments', JSON.stringify({ id }));
      const responseData = await response.json();
      updateUserAccountByKey({ key: 'customFieldsIdToLabelMap', value: responseData.value?.customFieldsIdToLabelMap });
      updateUserAccountByKey({ key: 'customSegmentsMapping', value: responseData.value?.customSegmentsMapping });
    } catch (error) {
      servicesStore.logger.error('failed to update customSegment', {
        error,
        UID,
      });
    }
  }

  function trackBucketCreated() {
    const segmentId = selectedSegmentAndGroupIds.segmentId;

    servicesStore.eventTracker.track({
      eventName: Events.customSegmentNewBucketCreated,
      properties: {
        segmentId,
        isNewSegment: !!customSegmentsData[segmentId].isNewSegment,
      },
    });
  }

  function trackSegmentSaved() {
    const segmentId = selectedSegmentAndGroupIds.segmentId;
    const customFieldIdRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    const fieldType = customFieldIdRegex.test(segmentId) ? 'customField' : segmentId;

    servicesStore.eventTracker.track({
      eventName: Events.customSegmentSaved,
      properties: {
        segmentId,
        fieldType,
        isNewSegment: !!customSegmentsData[segmentId].isNewSegment,
        bucketsCount: customSegmentsData[segmentId].groups.length,
      },
    });
  }

  function resetAllRulesWithEqualsOperatorInTheSegmentGroup() {
    const selectedSegmentsProperties = customSegmentsData[selectedSegmentAndGroupIds.segmentId];
    const segmentGroups = selectedSegmentsProperties.groups;

    for (const [index, group] of Object.entries(segmentGroups)) {
      for (const ruleSet of group.rules) {
        for (const rule of ruleSet) {
          if (rule.operation === ruleOperators.equals) {
            rule.value = '';
            onChangeBucketConfig({ newConfig: group, groupIndex: index });
          }
        }
      }
    }
  }

  const selectedSegmentsProperties = customSegmentsData[selectedSegmentAndGroupIds.segmentId];
  const segmentGroups = selectedSegmentsProperties.groups;
  const allFieldsAreFilled = checkIfAllPropertiesAreFilled({ segmentsProperties: selectedSegmentsProperties });

  const mappingOptions = getMappingOptions(customFieldsIdToLabelMap).filter((option) => !customSegmentsData[option.value]);
  const mappingOptionsWithCommon = segmentsGroupByCommonSegments({ options: mappingOptions });

  const shouldDisableSaveButton = isLoading || !allFieldsAreFilled || !selectedSegmentsProperties.isChanged || !!errors;

  return (
    <div className={classnames(channelsTabStyles.inner, styles.customSegmentsPropertiesWrapper)}>
      <div className={channelsTabStyles.channel}>
        <Tooltip id="CustomSegmentsPropertiesEdit" tip="Rename">
          <Textfield
            className={channelsTabStyles.channelTitle}
            placeHolder="Enter a name"
            value={selectedSegmentsProperties.name}
            onChange={(e) => onChangeSegmentConfig({ value: e.target.value, type: 'name' })}
          />
        </Tooltip>
      </div>

      <div className={channelsTabStyles.whiteBox}>
        <div className={channelsTabStyles.flexRow}>
          <div className={classnames(channelsTabStyles.fieldText, channelsTabStyles.fieldHalf)}>
            Original field
          </div>
          <Dropdown
            options={mappingOptionsWithCommon}
            className={channelsTabStyles.fieldHalf}
            selectedKey={selectedSegmentsProperties.crmFieldId}
            onChange={(e) => {
              onChangeSegmentConfig({ value: e.value, type: 'crmFieldId' });
            }}
            isSearchable
          />
        </div>
      </div>
      <div className={channelsTabStyles.titleText}>
        <div className={classnames(channelsTabStyles.flexRow, styles.rowFullWidth)}>
          Segment Buckets
          <Button
            type="secondaryBlue"
            onClick={() => addNewBucket()}
          >
            + Add segment bucket
          </Button>
        </div>
      </div>
      {segmentGroups?.map((group, groupIndex) => (
        <SegmentBucket
          key={`group-${group.id}`}
          originalFieldSelectedKey={selectedSegmentsProperties.crmFieldId}
          segmentBucketData={group}
          onChangeBucketConfig={(data) => onChangeBucketConfig({ ...data, groupIndex, groupId: group.id })}
          isActive={selectedSegmentAndGroupIds.groupId === group.id}
          errors={errors}
          onDeactivateBucket={() => onDeactivateBucket({ groupId: group.id })}
          isShowDeleteButton={segmentGroups.length > 1}
          customSegmentsData={customSegmentsData}
        />
      ))}

      <div className={channelsTabStyles.applyChanges}>
        <Button
          type="secondaryRed"
          disabled={isLoading}
          onClick={() => onDeleteSegment()}
        >
          Delete
        </Button>
        {isLoading ? (
          <Spinner />
        ) : null}
        <SaveButton
          onClick={() => onSaveSegment()}
          success={saveStatus.success}
          fail={saveStatus.fail}
          disabled={shouldDisableSaveButton}
          style={{ width: 'auto' }}
          labelClass={styles.saveButtonLabel}
        />
      </div>
    </div>
  );
}

export default inject(
  ({
    userStore: {
      updateUserAccountByKey,
      userAccount: {
        customFieldsIdToLabelMap,
      } = {},
      userMonthPlan: {
        UID,
      },
    } = {},
  }) => ({
    customFieldsIdToLabelMap,
    updateUserAccountByKey,
    UID,
  }),
  observer
)(CustomSegmentsProperties);
