import React, { useState } from 'react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';
import SuggestionPlaceholderDropdown from 'components/pages/questions/SuggestionPlaceholderDropdown';
import CustomCheckbox from 'components/controls/CustomCheckbox';

import { questionTextType, suggestionsPlaceholderTextTypes } from 'components/pages/questions/enums';
import { splitSuggestionTextToObjects, updateTextAccordingPlaceHolderType } from 'components/pages/questions/logic/suggestionItem';

import style from 'styles/questions/suggestions.css';
import multiCheckStyle from 'styles/controls/multi-check-select.css';

const styles = style.locals || {};
const multiCheckStyles = multiCheckStyle.locals || {};

export default function SuggestionItem({
  data, onSelectSuggestion, selectedSuggestions, isShowCheckboxes, onUpdatePlaceHolderValue,
}) {
  useStyles([style, multiCheckStyle]);

  const [placeHoldersValues, setPlaceHoldersValues] = useState(data.defaultPlaceHoldersValues);
  const { placeHoldersTypes, questionText: itemText } = data;

  function updatePlaceHolderValue({ placeHolderKey, placeHolderValue }) {
    const newPlaceHoldersValues = { ...placeHoldersValues };
    newPlaceHoldersValues[placeHolderKey] = placeHolderValue;
    setPlaceHoldersValues(newPlaceHoldersValues);

    if (onUpdatePlaceHolderValue) {
      onUpdatePlaceHolderValue({ placeHolders: newPlaceHoldersValues, questionData: data });
    }
  }

  const questionTextArray = splitSuggestionTextToObjects({ text: itemText, placeHoldersTypes, placeHoldersValues });
  const isSelected = selectedSuggestions?.some((selectedSuggestion) => selectedSuggestion.questionId === data.questionId);

  return (
    <div className={styles.suggestionItemWrapper}>
      {isShowCheckboxes ? (
        <CustomCheckbox
          key={data.questionId}
          checked={isSelected}
          onChange={() => onSelectSuggestion({ questionId: data.questionId, placeHoldersValues })}
          className={multiCheckStyles.checkboxContainer}
          checkboxClassName={classNames(multiCheckStyles.checkbox, styles.checkbox)}
          checkMarkClassName={multiCheckStyles.checkboxMark}
          childrenClassName={multiCheckStyles.checkboxLabel}
        />
      ) : null}
      <div
        className={styles.suggestionItem}
        data-testid="suggestion-item"
      >
        {questionTextArray.map((item, index) => {
          const shouldUpdateTextAccordingMetricType = index === 0 && item.type === questionTextType && questionTextArray[index + 1];
          if (shouldUpdateTextAccordingMetricType) {
            item.text = updateTextAccordingPlaceHolderType({ text: item.text, placeHolderType: questionTextArray[index + 1].type, placeHolderValue: questionTextArray[index + 1].text });
          }
          const questionTextKey = `${item.text}-${index}-${item.type}`;

          const isPlaceHolderTextItem = suggestionsPlaceholderTextTypes.includes(item.type);

          if (item.type === questionTextType || isPlaceHolderTextItem) {
            return (
              <div
                key={questionTextKey}
                className={styles.text}
                onClick={() => onSelectSuggestion({ questionId: data.questionId, placeHoldersValues })}
              >
                {item.text}
              </div>
            );
          }

          return (
            <SuggestionPlaceholderDropdown
              key={questionTextKey}
              text={item.text}
              type={item.type}
              onChange={({ placeHolderValue }) => updatePlaceHolderValue({ placeHolderKey: item.placeholderName, placeHolderValue })}
            />
          );
        })}
      </div>
    </div>
  );
}
