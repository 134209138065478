import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import useStyles from 'hooks/useStyles';

import CustomCheckbox from 'components/controls/CustomCheckbox';

import tablePopupStyles from 'styles/plan/table-popup.css';

const styles = tablePopupStyles.locals || {};

export default function CheckListWithDragAndDrop({
  listOptions, selectedListOptions, onChangeCheckbox, handleDragDrop, hideOptionsFromColumns,
}) {
  useStyles([tablePopupStyles]);

  return (
    <DragDropContext onDragEnd={handleDragDrop}>
      <Droppable droppableId="list-container">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {listOptions.map(({ id, label, isDisabled }, index) => {
              const isHideOption = hideOptionsFromColumns.includes(id);
              const customCheckboxKey = `${id}-${index}`;
              if (isHideOption) {
                return null;
              }
              return (
                <Draggable key={id} draggableId={id} index={index}>
                  {(itemProvided) => (
                    <div
                      className={styles.checkListItem}
                      ref={itemProvided.innerRef}
                      {...itemProvided.draggableProps}
                      {...itemProvided.dragHandleProps}
                    >
                      <div className={styles.dragAndDropIcon} />
                      <CustomCheckbox
                        key={customCheckboxKey}
                        checked={selectedListOptions.includes(id)}
                        onChange={() => onChangeCheckbox(id)}
                        className={styles.checkboxContainer}
                        checkboxClassName={styles.checkbox}
                        checkMarkClassName={styles.checkboxMark}
                        childrenClassName={styles.checkboxLabel}
                        disabled={isDisabled}
                      >
                        {label}
                      </CustomCheckbox>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
