import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep, isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import CheckListWithPresets from 'components/controls/CheckListWithPresets';
import timeFrameModule from 'modules/timeframe';

import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { getTableColumnKeys } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import {
  getAllColumnOptionsAndGroupedOptions, getColumnMetricNickName, defaultFirstAndSecondColumns, addDisabledBySegmentsToOptions,
} from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { getRevenueFunnel, getPipelineFunnel, FUNNEL1 } from 'components/utils/indicators';
import { handleReorderAndUpdatedColumnsOptions } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTablePopup';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { segmentsGroupByCommonSegments } from 'components/pages/analyze/SegmentsTab/logic/segments';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function SegmentAnalysisProperties({
  selectedWidgetProperties,
  onSelectedWidgetProperties,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  widgetProps,
  getUserDynamicMetricsPerFunnel,
  userFunnels,
  getWidgetRequestId,
  segmentsAnalysis,
  fiscalYearFirstMonth,
}) {
  useStyles([style]);

  useEffect(() => {
    const defaultProperties = {
      segmentsAnalysisParams: {
        firstSegment: null,
        secondSegment: null,
      },
    };
    onSelectedWidgetProperties({ values: defaultProperties });
  }, []);

  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const clonedWidgetConfig = cloneDeep(widgetProps.widgetConfig);
  const timeFrameParams = timeFrameModule.getTimeframeParams({ ...widgetProps.widgetHeaderConfig.timeFrame, fiscalYearFirstMonth });
  const { startDate, endDate } = timeFrameParams;
  clonedWidgetConfig.timeFrame.startDate = new Date(getTSForTimezone(startDate));
  clonedWidgetConfig.timeFrame.endDate = new Date(getTSForTimezone(endDate));
  const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: clonedWidgetConfig });

  useEffect(() => {
    const isAttribution = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution;
    if (!isNil(isAttribution)) {
      const allColumnsValues = getColumnsValues({ selectedSegments: selectedWidgetProperties.segmentsAnalysisParams, isAttribution });

      const disabledColumns = getAllColumnOptionsAndGroupedOptions({
        isAttribution,
        selectedSegments: selectedWidgetProperties.segmentsAnalysisParams,
      }).allColumnOptions.filter((column) => column.isDisabled).map((column) => column.value);

      const updatedColumnsOptions = allColumnsValues.map((col) => ({
        value: col, label: getColumnMetricNickName({ metric: col }), isDisabled: disabledColumns.includes(col),
      }));

      if (selectedColumns.length === 0) {
        onSelectedWidgetProperties({ values: { inactiveColumns: { [FUNNEL1]: allColumnsValues } } });
      }

      setColumnsOptions(updatedColumnsOptions);
    }
  }, [segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution]);

  function getColumnsValues({ selectedSegments, isAttribution }) {
    let allColumnsValues = [];
    if (selectedSegments?.firstSegment) {
      const userDynamicMetricsPerFunnel = getUserDynamicMetricsPerFunnel();
      const allColumnOptions = getAllColumnOptionsAndGroupedOptions({
        isAttribution,
        selectedSegments: {
          firstSegment: { value: selectedSegments?.firstSegment },
          secondSegment: { value: selectedSegments?.secondSegment },
        },
      }).allColumnOptions.map((column) => column.value);
      allColumnOptions.push('touchedPipeline', 'touchedRevenue');

      allColumnsValues = getTableColumnKeys({
        customColumnsKeys: allColumnOptions,
        firstSegmentValue: selectedSegments?.firstSegment,
        secondSegmentValue: selectedSegments?.secondSegment,
        userDynamicMetricsPerFunnel,
        pipelineFunnel: getPipelineFunnel(),
        revenueFunnel: getRevenueFunnel(),
        userFunnels,
      });
      allColumnsValues = allColumnsValues.filter((column) => !defaultFirstAndSecondColumns.includes(column));
    }
    return allColumnsValues;
  }

  function onChangeSegment({ segmentKey, segmentValue }) {
    const segmentsAnalysisParams = { ...selectedWidgetProperties.segmentsAnalysisParams, [segmentKey]: segmentValue };
    const updatedValues = { segmentsAnalysisParams };

    if (widgetProps.isCalculateAdvancedMetrics) {
      const trendBySegmentsParams = { ...selectedWidgetProperties.trendBySegmentsParams, [segmentKey]: segmentValue };
      updatedValues.trendBySegmentsParams = trendBySegmentsParams;
    }

    if (selectedColumns.length === 0) {
      const allColumnsValues = getColumnsValues({ selectedSegments: segmentsAnalysisParams, isAttribution: false });
      updatedValues.inactiveColumns = { [FUNNEL1]: allColumnsValues };
    }

    onSelectedWidgetProperties({ values: updatedValues });
  }

  function getSegmentsOptions() {
    const optionsWithDisabled = addDisabledBySegmentsToOptions({
      options: getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist }),
      selectedSegments: {
        firstSegment: selectedWidgetProperties.segmentsAnalysisParams?.firstSegment,
        secondSegment: selectedWidgetProperties.segmentsAnalysisParams?.secondSegment,
      },
    });

    return segmentsGroupByCommonSegments({ options: optionsWithDisabled });
  }

  function onSelectedColumns({ options }) {
    const inactiveColumns = columnsOptions.map((column) => column.value).filter((item) => !options.includes(item));
    const reorderedColumns = new Set(options);
    columnsOptions.forEach((column) => reorderedColumns.add(column.value));

    setSelectedColumns(options);
    onSelectedWidgetProperties({
      values: {
        inactiveColumns: { [FUNNEL1]: inactiveColumns },
        reorderColumns: { [FUNNEL1]: Array.from(reorderedColumns) },
      },
      isRequestServerData: false,
    });
  }

  function onReorderColumns({ droppedItem }) {
    if (!droppedItem.destination) {
      return;
    }
    const updatedColumnsOptions = handleReorderAndUpdatedColumnsOptions(columnsOptions, droppedItem);
    setColumnsOptions(updatedColumnsOptions);
  }

  const segmentsOptions = getSegmentsOptions();

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>Breakdown by</div>
        <Dropdown
          onChange={(value) => onChangeSegment({ segmentKey: 'firstSegment', segmentValue: value?.value })}
          options={segmentsOptions}
          selectedKey={selectedWidgetProperties.segmentsAnalysisParams?.firstSegment}
          isSearchable
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Secondary Segment (Optional)</div>
        <Dropdown
          onChange={(value) => onChangeSegment({ segmentKey: 'secondSegment', segmentValue: value?.value })}
          options={segmentsOptions}
          selectedKey={selectedWidgetProperties.segmentsAnalysisParams?.secondSegment}
          disabled={!selectedWidgetProperties.segmentsAnalysisParams?.firstSegment}
          isSearchable
          isClearable
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Select columns</div>
        <CheckListWithPresets
          isCanReorder
          options={columnsOptions}
          selectedOptions={selectedColumns}
          onDragEnd={(droppedItem) => onReorderColumns({ droppedItem })}
          isDisabled={!selectedWidgetProperties.segmentsAnalysisParams?.firstSegment || columnsOptions.length === 0}
          setSelectedOptions={(options) => onSelectedColumns({ options })}
          isShowError={selectedWidgetProperties.segmentsAnalysisParams?.firstSegment && selectedColumns?.length === 0 && columnsOptions.length > 0}
          checkListToggleLabel="Select columns"
          checkListTitle="Manage columns and metrics"
          classNamePopupToggle={styles.selectColumnsButton}
          popupOptionsClassName={styles.popupOptionsWrapper}
          dropdownContainerClassName={styles.selectColumnsButtonContainer}
        />
      </div>
    </>
  );
}

export default inject(({
  widgetsAnalysisStore: {
    getWidgetRequestId,
    dataPerWidget: {
      [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
    },
  },
  userStore: {
    userAccount: {
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
    } = {},
    getUserDynamicMetricsPerFunnel,
    userFunnels,
    userMonthPlan: {
      fiscalYearFirstMonth,
    },
  } = {},
}) => ({
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  getUserDynamicMetricsPerFunnel,
  userFunnels,
  getWidgetRequestId,
  segmentsAnalysis,
  fiscalYearFirstMonth,
}),
observer)(SegmentAnalysisProperties);
