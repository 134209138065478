export const getFilteredOptionsForRequest = (journeyFilterOptions) => {
  const updatedFilterOptions = [];
  for (const categoryOptions of journeyFilterOptions) {
    const values = [];
    for (const value of categoryOptions.values) {
      if (value.isSelected) {
        values.push(value.value);
      }
    }
    if (values.length) {
      updatedFilterOptions.push({ ...categoryOptions, values });
    }
  }
  return updatedFilterOptions;
};
