import React from 'react';

import useStyles from 'hooks/useStyles';

import CustomCheckbox from 'components/controls/CustomCheckbox';

import checkboxStyles from 'styles/controls/multi-check-select.css';
import attributionStyles from 'styles/analyze/analyze-attribution-table.css';

const checkboxStyle = checkboxStyles.locals || {};
const attributionStyle = attributionStyles.locals || {};

export default function CheckboxWithFrame({
  checked, onChange, text,
}) {
  useStyles([checkboxStyles, attributionStyles]);

  return (
    <div className={attributionStyle.hideNoValueWrapper}>
      <CustomCheckbox
        checked={checked}
        onChange={onChange}
        className={checkboxStyle.checkboxContainer}
        checkboxClassName={checkboxStyle.checkbox}
        checkMarkClassName={checkboxStyle.checkboxMark}
        childrenClassName={attributionStyle.checkboxLabel}
      >
        {text}
      </CustomCheckbox>
    </div>
  );
}
