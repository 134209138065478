import React, { useMemo } from 'react';
import classnames from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import Dropdown from 'components/controls/Dropdown';
import EditableLabel from 'components/controls/EditableLabel';
import Textfield from 'components/controls/Textfield';
import Skeleton from 'components/common/Skeleton';
import FeatureFlags from 'components/common/FeatureFlags';
import InfoMarker from 'components/pages/InfoMarker';
import Tooltip from 'components/controls/Tooltip';

import { budgetChangeOperatorsOptions, unclickableItemsKeys } from 'components/widgets/whatIf/enums';
import { skeletonSmallTextSizes } from 'components/common/enums';
import {
  formatIndicatorDisplay, getIndicatorDisplaySign, getRevenueFunnel, getPipelineFunnel,
} from 'components/utils/indicators';

import style from 'styles/analyze/scenario-box.css';

const styles = style.locals || {};

function ScenarioRules({
  rules,
  setRules,
  isBaseline,
  ruleBaseData = {},
  isLoadedBaseData,
  scenarioType,
  paidChannelsOptions,
  setPaidChannelsOptions,
  flags,
  kpiFocus,
}) {
  useStyles([style]);

  function addDisabledToSelectedChannel({ prevChannel, currentChannel }) {
    const updatedPaidChannelsOptions = [...paidChannelsOptions];
    if (prevChannel) {
      const prevChannelIndex = updatedPaidChannelsOptions.findIndex((channel) => channel.value === prevChannel);
      updatedPaidChannelsOptions[prevChannelIndex].disabled = false;
    }
    const newChannelIndex = updatedPaidChannelsOptions.findIndex((channel) => channel.value === currentChannel);
    updatedPaidChannelsOptions[newChannelIndex].disabled = true;
    setPaidChannelsOptions(updatedPaidChannelsOptions);
  }

  function onChangeSelectedChannel({ channel }) {
    setRules({ ...rules, channel });
    addDisabledToSelectedChannel({ prevChannel: rules?.channel, currentChannel: channel });
  }

  function onchangeRuleValue({ value }) {
    const updatedRules = { ...rules };
    if (!rules.budgetChangeOperator) {
      updatedRules.budgetChangeOperator = 'increase';
    }
    setRules({ ...updatedRules, budgetChange: value });
  }

  const isChannelScenario = scenarioType === 'channel';
  const shouldShowScenarioRules = (rules.channel && isChannelScenario) || !isChannelScenario;

  const tooltipTextByKpiFocus = useMemo(() => {
    let currentStage = kpiFocus;
    if (kpiFocus === 'pipeline') {
      currentStage = getPipelineFunnel();
    }
    if (kpiFocus === 'revenue') {
      currentStage = getRevenueFunnel();
    }
    return `${userStore.getMetricNickname({ metric: 'funnel2' })} ➝ ${userStore.getMetricNickname({ metric: currentStage })}`;
  }, [kpiFocus]);

  return (
    <div>
      {isChannelScenario && !isBaseline ? (
        <div className={styles.row}>
          <div className={styles.rowName}>
            Channel
          </div>
          <div className={styles.rowAction}>
            <Dropdown
              controlWidth={200}
              options={paidChannelsOptions}
              selectedKey={rules.channel}
              onChange={(e) => onChangeSelectedChannel({ channel: e.value })}
              isSearchable
            />
          </div>
        </div>
      ) : null}

      {shouldShowScenarioRules ? (
        <>
          {!isBaseline ? (
            <div className={styles.row}>
              <div className={styles.rowName}>
                Budget Change
                <InfoMarker tooltipText="InfiniGrow will apply the change to every month within the selected timeframe." />
              </div>
              <div className={styles.rowAction}>
                <Dropdown
                  controlWidth={120}
                  options={budgetChangeOperatorsOptions}
                  selectedKey={rules.budgetChangeOperator || 'increase'}
                  onChange={(event) => setRules({ ...rules, budgetChangeOperator: event.value })}
                  isSearchable
                />
                <Textfield
                  inputSign="%"
                  value={rules.budgetChange}
                  onChange={(event) => onchangeRuleValue({ value: event.target.value })}
                  style={{ width: '72px', marginLeft: '8px' }}
                  dataTestId="budget-change-input"
                />
              </div>
            </div>
          ) : null}

          <FeatureFlags flag={!flags.whatIfDemoOnly}>
            <div className={styles.row}>
              <div className={styles.rowName}>
                Avg. Monthly Spend
                <Tooltip
                  id="Avg-Monthly-Spend"
                  tip="InfiniGrow uses your average monthly spend to estimate your budget, since you did not input it under the Plan section. For more accurate forecasts, enter your budget in the Plan section."
                >
                  <div className={styles.warningIcon} />
                </Tooltip>
              </div>
              <div className={styles.rowAction}>
                {isLoadedBaseData ? (
                  <>
                    {getIndicatorDisplaySign('revenue')}
                    {ruleBaseData.avgMonthlySpend}
                  </>
                ) : (
                  <Skeleton {...skeletonSmallTextSizes} />
                )}
              </div>
            </div>
          </FeatureFlags>

          <div className={classnames(styles.row, styles.rowWithHover)}>
            <div className={styles.rowName}>
              Conv. Rate (%)
              <InfoMarker tooltipText={`${tooltipTextByKpiFocus} CR`} />
            </div>
            <div className={styles.rowAction}>
              {isLoadedBaseData ? (
                <EditableLabel
                  value={(rules.conversionRate === undefined ? ruleBaseData.conversionRate : rules.conversionRate) || 0}
                  onChange={(event) => setRules({ ...rules, conversionRate: event.target.value })}
                  rightLabel="%"
                  dataTestId="conversion-rate-input"
                  disableClicks={flags.whatIfUnclickableItems?.[unclickableItemsKeys.baselineConversionRate]}
                />
              ) : (
                <Skeleton {...skeletonSmallTextSizes} />
              )}
            </div>
          </div>
          <div className={classnames(styles.row, styles.rowWithHover)}>
            <div className={styles.rowName}>
              Velocity (days)
              <InfoMarker tooltipText={`${tooltipTextByKpiFocus} Velocity`} />
            </div>
            <div className={styles.rowAction}>
              {isLoadedBaseData ? (
                <EditableLabel
                  value={(rules.velocity === undefined ? ruleBaseData.velocity : rules.velocity) || 0}
                  onChange={(event) => setRules({ ...rules, velocity: event.target.value })}
                  rightLabel="Days"
                  gap="4px"
                  dataTestId="velocity-input"
                />
              ) : (
                <Skeleton {...skeletonSmallTextSizes} />
              )}
            </div>
          </div>
          {(kpiFocus === getRevenueFunnel() || kpiFocus === 'revenue') ? (
            <div className={classnames(styles.row, styles.rowWithHover)}>
              <div className={styles.rowName}>
                {`Avg. Deal Size (${getIndicatorDisplaySign('revenue')})`}
              </div>
              <div className={styles.rowAction}>
                {isLoadedBaseData ? (
                  <EditableLabel
                    value={(rules.avgDealSize === undefined ? ruleBaseData.avgDealSize : rules.avgDealSize) || 0}
                    onChange={(event) => setRules({ ...rules, avgDealSize: event.target.value })}
                    formatValue={(value) => formatIndicatorDisplay('revenue', value, true)}
                    dataTestId="avg-deal-size-input"
                  />
                ) : (
                  <Skeleton {...skeletonSmallTextSizes} />
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default withLDConsumer()(ScenarioRules);
